import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { vMaska } from "maska"
import { useLocalizationStore } from '@/store/localization_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatInput',
  props: {
    modelValue: { type: String, required: true },
    labelKey: { type: String, required: true },
    mask: { type: String, required: false },
    type: { type: String, required: false }
  },
  emits: ["update:model-value", "focus", "blur"],
  setup(__props: any, { expose: __expose }) {

const props = __props;



const label = ref(props.labelKey);

const localizationStore = useLocalizationStore();

__expose({
  setFocus(v:boolean){
    if(v) {
      myInput.value?.focus();
    } else {
      myInput.value?.blur();
    }
  }
})

onMounted(async () => {
  label.value = await localizationStore.getKey(props.labelKey);
});

const myInput = ref<InstanceType<typeof HTMLInputElement>>();


const __returned__ = { props, label, localizationStore, myInput, get vMaska() { return vMaska }, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
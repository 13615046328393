<template >
  <div v-show="templateVisible" ref="flipboardContainerElement" class="flipboard-container ui-shadows">
    <div class="flipboard-tools">
      <button class="flipboard-tool ui-shadows" :class="penToolActive ? 'active' : 'innactive'" @click="tooglePen">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <g>
              <g>
                <path fill="#000000"
                  d="M206.6,10.5c-0.6,0.3-2.1,1.6-3.2,2.6l-2.1,2l7.1,7.2c4.4,4.4,6.9,7.3,6.6,7.6c-0.3,0.3-4,3.2-8.1,6.5C189.6,50.3,173.6,65,166.2,74c-4.4,5.3-8.5,11.6-8.5,13.1c0,0.4,2.5,3.3,5.5,6.3l5.5,5.5l3.4-1.8c7.2-3.7,19.3-14.9,33.1-30.6c6.3-7.1,18.4-21.9,20.6-25c0.8-1.1,1.1-0.8,8,6l7.2,7.2l2-2.2c2.6-2.9,3.5-5.6,3-9.5c-1.3-11-13.2-25.2-25.9-31.1C215.7,10,208.8,9.3,206.6,10.5z" />
                <path fill="#000000"
                  d="M183.7,25.2c-18.9,11.4-41.5,27.5-58.2,41.5c-10.7,9-12.6,10.8-12.6,12.3c0,1.8,63.4,65.1,64.7,64.6c0.5-0.2,1-0.3,1.1-0.3c0.3,0,12.3-14.1,17-19.9c15.5-19.3,41-57.6,41-61.4c0-1.7-7.7-9.4-9.4-9.4c-0.9,0-2.9,2.1-7.6,8c-11.3,14.1-19.9,23.9-29.7,33.7c-10.4,10.4-17.4,16-23.7,19.2l-4.1,2.1l-10.6-10.6l-10.6-10.6l2.2-4.4c6.6-12.7,24.9-31.2,53-53.5c7.4-5.8,7.6-6,7.6-7.6c0-1.5-7.7-9.2-9.2-9.2C193.7,19.4,188.9,22,183.7,25.2z" />
                <path fill="#000000"
                  d="M98.4,91.5c-15.9,16.5-30.6,35.4-41.8,53.9c-3.6,5.9-9.8,17.5-9.8,18.3c0,0.9,45,45.6,45.9,45.6c1.1,0,15.5-8,23.2-12.8c11.4-7.2,25.8-18,36.8-27.6c8.8-7.7,17.8-16.8,17.8-18c0-1.6-63.7-65.3-65.2-65.3C104.6,85.5,101.9,87.8,98.4,91.5z" />
                <path fill="#000000"
                  d="M36.1,181.8c-6.3,3.7-11.7,6.9-11.9,7.2c-0.3,0.3-1.3,4.6-2.3,9.6c-3.5,17.1-7.4,29.8-10.5,34.2c-1.4,2-1.6,2.6-1.2,3.8c0.4,1.1,1,1.5,2.2,1.6c1.5,0.1,2.9-1.1,13.7-11.9l12-12v-3.4c0-3,0.2-3.5,1.6-4.8c2-1.7,8.6-3.7,11.4-3.5c2.1,0.2,2.2,0.2,2.3,2.6c0.3,3.8-1.7,9.7-3.9,11.5c-1.6,1.3-2.1,1.5-4.5,1.1l-2.8-0.3l-12.2,12.2c-9.6,9.6-12.3,12.5-12.3,13.6c0,3,2.5,3.6,5.6,1.4c4.1-3.1,16.3-6.8,33.3-10.3c5.8-1.2,10.5-2.2,10.6-2.4c1.4-1.6,13.9-24.1,13.9-25.1c0.1-2-30.1-32-32-31.9C48.2,175.1,42.5,178.1,36.1,181.8z" />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button class="flipboard-tool ui-shadows" :class="eraseToolActive ? 'active' : 'innactive'" @click="toogleErase">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <g>
              <g>
                <path fill="#000000"
                  d="M138.4,7c-0.6,0.2-1.5,0.9-2.1,1.6c-0.9,1-26.1,40.1-84.1,130.4c-4.1,6.4-5.2,8.5-5.2,9.9c0,2.2,1.3,3.9,4.3,5.8c1.3,0.8,2.3,1.6,2.3,1.8c0,0.2-4.7,7.7-10.5,16.7c-9.3,14.4-10.5,16.6-10.5,18.3c0,1.5,0.5,2.3,1.5,3.4c2.3,2.2,83.5,54.1,85.1,54.5c3.1,0.6,4-0.4,14.9-17.3c5.7-8.8,10.5-16.3,10.6-16.5c0.2-0.3,1.7,0.4,3.3,1.3c3.3,2,6,2.4,7.7,0.9c0.9-0.7,46-70.5,84.3-130.3c6.2-9.7,6.7-10.9,5.3-13.6c-0.5-1-15.5-10.9-50.7-33.5c-27.5-17.7-50.7-32.6-51.6-33C141.3,6.4,140,6.3,138.4,7z M185.9,47.5c23.4,15.1,43.8,28.2,45.1,29.1l2.4,1.6l-34.5,53.7c-19,29.5-34.9,54.2-35.3,54.8c-0.8,1.1-1.3,0.8-46.3-28.2c-25-16.1-45.5-29.5-45.5-29.7c0-0.3,45.3-71,66.4-103.5c2.4-3.8,4-5.7,4.4-5.5C142.8,19.9,162.4,32.4,185.9,47.5z M111,164.5c24.9,15.6,45.4,28.6,45.6,28.8c0.2,0.2-0.9,2.8-2.5,5.7l-2.9,5.4l-1.8-1.1c-3-1.9-89.4-56.7-89.5-56.8c-0.2-0.2,5.6-10.4,5.9-10.4C65.8,136.1,86.2,148.9,111,164.5z M100.1,186c24.8,15.9,35.9,23.3,35.7,23.8c-2.6,4.4-17.2,26.6-17.4,26.6c-0.5,0-71.3-45.4-72.2-46.3c-0.6-0.5,0.6-2.5,6.3-11.4c3.8-6,7.7-12.1,8.7-13.5c1.1-1.6,2-2.6,2.4-2.5C63.8,162.7,80.3,173.3,100.1,186z" />
                <path fill="#000000"
                  d="M145.5,36.7c-0.6,0.1-1.7,0.8-2.3,1.6c-2.2,2.3-54.1,83.6-54.4,85.1c-0.7,3.3-0.5,3.4,33.6,25.3c17.8,11.4,32.8,21,33.4,21.3c1.6,0.8,3.2,0.7,4.9-0.4c1.5-0.9,54.8-83.4,55.4-85.5c0.4-1.4,0-3.1-0.9-4.3c-1-1.3-65.8-42.7-67.3-43C147.1,36.6,146,36.6,145.5,36.7z" />
                <path fill="#000000"
                  d="M18.2,215.6c-5.5,0.3-5.8,0.3-7,1.7c-2.3,2.7-1.3,6.8,2.1,8.3c2.5,1,33.9,1,36.4,0c5.4-2.2,4-9.9-1.8-10C33.7,215.4,23,215.4,18.2,215.6z" />
                <path fill="#000000"
                  d="M142.1,227.7c-1.4,1.3-1.7,1.8-1.7,3.8c0,2,0.3,2.6,1.7,3.8l1.6,1.5h28.7c24.5,0,28.8-0.1,30.1-0.8c1.9-1,2.5-2.2,2.5-4.6c0-2.4-0.7-3.6-2.5-4.6c-1.3-0.7-5.6-0.8-30.1-0.8h-28.7L142.1,227.7z" />
                <path fill="#000000"
                  d="M23.8,237.6c-2,0.9-3.3,3.6-2.9,5.8c0.2,0.8,0.9,2,1.6,2.7l1.3,1.3h29.3h29.3l1.6-1.4c2.5-2.2,2.1-6.3-0.7-8.2C81.7,236.8,26.3,236.5,23.8,237.6z" />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <!--button class="flipboard-tool ui-shadows" :class="bucketToolActive ? 'active' : 'innactive'" @click="toogleBucket">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
<g><g><g><path fill="#000000" d="M110.3,18.9c-4.5,1.5-7.5,4-19.1,16.6L78.4,49.3H65.5c-9.5,0.1-14.2,0.5-18.3,1.9c-14.6,5.1-18,18-8.2,31.5l3.5,4.9l-14.4,15.6c-8,8.6-15.3,17-16.3,18.8c-5.5,9.7,1.2,28.8,17.6,50.9c8.6,11.5,34.8,37.7,46.5,46.3c12,8.9,26.6,16.3,35.1,17.8c12.6,2.3,10.2,4,65.3-47.3c26.9-25.1,49.8-47,50.8-48.6c4.9-7.4,0.4-23.8-11.2-41.4C186.7,55,130.6,12.1,110.3,18.9z M126.9,38.1c20.9,9.9,50.4,36,69.3,61.5l2.9,3.8l-10.2-2.4c-5.7-1.4-14.4-2.9-19.3-3.2c-5-0.3-10-0.7-11.3-1.1c-2.7-0.5-20.4-20.2-29-32.3c-6.2-8.7-12.9-21.1-14.2-26.5c-0.6-2.6-0.4-2.9,2.3-2.9C119,35,123.3,36.4,126.9,38.1z M114.3,71.8c14.2,20.7,41.6,47.9,62.8,62.4c2.7,1.9,8.8,5.4,13.3,7.9l8.3,4.4l-24.9,23.1c-59.8,55.9-56,52.5-59.7,51.7c-19.1-4-57-36.3-76.7-65.6c-6.9-10.1-11.7-20.4-11.7-24.5c0-1.8,26.1-30.9,28.3-31.7c0.6-0.2,4,1.8,7.6,4.4c7.8,5.8,21.4,14,29.7,18.1c5.6,2.7,6,3.2,7.2,8.2c2.4,10.7,9.9,16.6,21,16.6c20.7,0,28.3-28,10.3-38.5c-6.2-3.6-15.2-3.7-21.4-0.1l-4.4,2.5l-11.3-6.3c-13.1-7.3-27-16.6-26.6-17.8c0.2-0.4,8.1-9.2,17.6-19.4l17.3-18.7l4.3,8.3C107.9,61.4,111.9,68.1,114.3,71.8z M58.4,70.6c-4.9,4.9-5.1,5-6.5,3c-4.1-5.5-2.2-7.7,6.8-7.8l4.8-0.1L58.4,70.6z M122.8,122.9c0.7,0.7,1.3,2.2,1.3,3.5c0,1.3-0.5,2.7-1.3,3.5s-2.2,1.3-3.5,1.3s-2.7-0.5-3.5-1.3s-1.3-2.2-1.3-3.5c0-1.3,0.5-2.7,1.3-3.5s2.2-1.3,3.5-1.3S122,122.1,122.8,122.9z"/><path fill="#000000" d="M210.8,176.2c-16.9,18.1-22.5,32.6-17,44.7c9.5,21.2,37.4,22.5,49.3,2.3c2.3-4.1,2.9-6.2,2.9-12.4c0-6.3-0.5-8.3-3.6-13.9c-4-7.4-15.9-22.4-19.4-24.3C218.5,170.2,215.8,171,210.8,176.2z M225.1,199.9c6,9,6.4,13.1,2,17.7c-3.8,3.9-8.2,4.9-13,2.6c-3.5-1.7-6.7-6.1-6.7-9.4c0-3.5,9.4-18.5,11.6-18.5C219.6,192.4,222.3,195.8,225.1,199.9z"/></g></g></g>
</svg>
      </button -->
      <button class="flipboard-tool ui-shadows" :class="rectangleToolActive ? 'active' : 'innactive'" @click="toogleRectangle">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <path fill="#000000"
              d="M239.4,64.1h-30.6V33.5c0-3.6-3-6.6-6.7-6.6c-3.6,0-6.6,3-6.6,6.6v30.6h-30.6c-3.6,0-6.7,3-6.7,6.6 s3,6.6,6.7,6.6h30.6V108c0,3.6,3,6.6,6.6,6.6c3.6,0,6.7-3,6.7-6.6V77.4h30.6c3.6,0,6.6-3,6.6-6.6C246,67,243,64.1,239.4,64.1z  M16.3,89.5c3.4,0,6-2.7,6-6h0.2v-6.6h11.1c3.3,0,6-2.7,6-6s-2.8-6-6-6l-17.4-0.1c-3.4,0-6,2.7-6,6v13.1 C10.2,86.7,12.9,89.5,16.3,89.5z M57.6,76.7h30.2c3.4,0,6-2.7,6-6s-2.7-6-6-6H57.6c-3.4,0-6,2.8-6,6C51.6,74,54.4,76.7,57.6,76.7z  M111.8,76.7H142c3.4,0,6-2.7,6-6s-2.7-6-6-6h-30.2c-3.4,0-6,2.8-6,6C106,74,108.4,76.7,111.8,76.7z M208.3,142.9c0-3.4-2.7-6-6-6 c-3.4,0-6,2.7-6,6v30.2c0,3.4,2.7,6,6,6c3.4,0,6-2.7,6-6V142.9z M202.2,191.1c-3.4,0-6,2.8-6,6v19.6H172v0.4c-3.4,0-6,2.7-6,6 c0,3.4,2.7,6,6,6h30.2c3.4,0,6-2.7,6-6v-25.8C208.3,193.7,205.5,191.1,202.2,191.1z M149,216.7l-126.7-0.2V100.9h-0.2 c0-3.4-2.7-6-6-6s-6,2.7-6,6v121.9c0,3.4,2.7,6,6,6h132.6c3.4,0,6-2.8,6-6C154.7,219.3,151.9,216.7,149,216.7z" />
          </g>
        </svg>
      </button>
      <button class="flipboard-tool ui-shadows" :class="circleToolActive ? 'active' : 'innactive'" @click="toogleCircle">
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 256 256">
          <g>
            <path
              d="M33.5,88.3c3-6.9,6.8-13.6,11.6-20.2,1.1-1.5,2.4-3.1,3.8-4.8-4.6-2.7-9.3-5.3-14-7.9-3.8,4.9-7.3,10.1-10.3,15.5-1.4,2.5-2.8,5.2-4,8,4.3,3,8.6,6.3,13,9.4Z" />
            <path
              d="M70,43.8c3.1-2,6.4-4,9.9-5.8-3-4.3-5.7-8.9-8.2-13.6-11.1,6.1-21.3,14-30.1,23.3,4.8,2.7,9.6,5.3,14.2,8.2,4.9-4.8,10.1-9.4,14.2-12.1Z" />
            <path
              d="M32.7,165.8c-2.9-7.2-4.9-14.8-5.9-22.7-5.3.6-10.5,1.2-15.8,1.6,1.4,10.2,4.5,20.6,8.7,30.5,4.3-3.2,8.7-6.2,13-9.4Z" />
            <path
              d="M51.4,195.6c-2.4-2.7-4.6-5.3-6.2-7.5-3.2-4.3-5.9-8.7-8.3-13.2-4.3,3.1-8.6,6.1-12.8,9.3,2.2,4,4.5,8,7.1,11.6,2.5,3.6,6.3,8,10.5,12.4,3-4.4,6.3-8.6,9.7-12.6Z" />
            <path
              d="M67.2,229.4c2.8-4.5,5.3-9.2,7.7-13.9-1.5-.9-3.1-1.9-4.6-2.9-3.5-2.3-7.9-5.9-12-9.9-3.4,4-6.7,8.2-9.6,12.6,4.7,4.4,9.4,8.3,13.2,10.8,1.7,1.2,3.5,2.2,5.2,3.3Z" />
            <path
              d="M234.4,77c-15.9-32.7-45.7-56.4-81.1-64.3-6-1.3-22.9-3.2-26.6-2.9-1,0-4.8.3-8.7.6-13,1.1-25.6,4.4-37.4,9.6,2.5,4.7,5.3,9.3,8.4,13.7,7.2-3,14.4-5.2,20.9-6.3,3.2-.5,6.3-1,6.9-1.2,3.1-.8,26.4.4,31.4,1.5,20.2,4.5,37.6,13.9,51.8,28.1,19.6,19.6,30,44.6,30,72.3s-10.5,52.9-30,72.3c-18.5,18.5-41.7,28.7-68.1,29.7-17,.7-33.4-2.6-48.4-9.9-2.5,4.7-4.9,9.4-7.7,13.9,13.6,6.7,27.8,10.6,43.4,11.8,57.4,4.3,110-33.9,123.5-90,6.4-26.3,3.4-54.6-8.4-78.9Z" />
            <path
              d="M25.9,133.2c0-1.6-.1-3.3-.1-5,0-10.9,1.4-20.8,4.3-30.3-4.5-3.1-8.8-6.4-13.3-9.5-2.8,7.9-4.9,16.1-6,23.9-.7,4.9-.9,14.5-.6,22.4,5.3-.4,10.5-1,15.7-1.6Z" />
          </g>
          <path class="st0" d="M127,83c.3,31.7.7,63.3,1,95" />
          <path class="st0" d="M85.6,128h85" />
        </svg>
      </button>


      <button class="flipboard-tool ui-shadows" @click="handleCleanBoard">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <g>
              <path fill="#000000"
                d="M231.1,60.3c-0.7-11.6-10.2-20.8-22-20.8H187v-7.4c0-12.2-9.9-22.1-22.1-22.1H91.1C78.9,10,69,19.9,69,32.1v7.4H46.9c-11.8,0-21.3,9.2-22,20.8h-0.1v16.1c0,8.1,6.6,14.8,14.8,14.8l0,0v125.4c0,16.3,13.2,29.5,29.5,29.5h118c16.3,0,29.5-13.2,29.5-29.5V91.1c8.1,0,14.8-6.6,14.8-14.8V60.3H231.1L231.1,60.3z M83.8,32.1c0-4.1,3.3-7.4,7.4-7.4h73.7c4.1,0,7.4,3.3,7.4,7.4v7.4H83.8V32.1L83.8,32.1z M201.8,216.5c0,8.1-6.6,14.7-14.8,14.7H69c-8.1,0-14.7-6.6-14.7-14.7V91.1h147.5V216.5z M216.5,69v7.4h-177V61.6c0-4.1,3.3-7.4,7.4-7.4h162.2c4.1,0,7.4,3.3,7.4,7.4V69L216.5,69z M76.4,216.5h14.8c4.1,0,7.4-3.3,7.4-7.4v-95.9c0-4.1-3.3-7.4-7.4-7.4H76.4c-4.1,0-7.4,3.3-7.4,7.4v95.9C69,213.2,72.3,216.5,76.4,216.5z M76.4,113.3h14.8v95.9H76.4V113.3z M120.6,216.5h14.8c4.1,0,7.4-3.3,7.4-7.4v-95.9c0-4.1-3.3-7.4-7.4-7.4h-14.8c-4.1,0-7.4,3.3-7.4,7.4v95.9C113.3,213.2,116.6,216.5,120.6,216.5L120.6,216.5z M120.6,113.3h14.8v95.9h-14.8V113.3L120.6,113.3z M164.9,216.5h14.8c4.1,0,7.4-3.3,7.4-7.4v-95.9c0-4.1-3.3-7.4-7.4-7.4h-14.8c-4.1,0-7.4,3.3-7.4,7.4v95.9C157.5,213.2,160.8,216.5,164.9,216.5z M164.9,113.3h14.8v95.9h-14.8V113.3z" />
            </g>
          </g>
        </svg>
      </button>
      <div class="color-picker ui-shadows">
        <input type="color"  v-model="selectedColor" />
      </div>
    </div>
    <div class="flipboard-draw-area">
      <div ref="flipboardPaper" class="flipboard-paper ui-shadows">
        <canvas v-show="rectangleToolActive || circleToolActive"
          ref="flipboardCanvasPreviewElement" class="flipboard-canvas-preview-element"
          >
        </canvas>
        <canvas  ref="flipboardCanvasElement" class="flipboard-canvas-element"
          :class="{ 'pen-active': penToolActive, 'erase-active': eraseToolActive }">
        </canvas>
      </div>
    </div>
    <button class="close-button ui-shadows" ref="closeElement" @click="handleCloseScreen">
      <FontAwesomeIcon :icon="Icons.imported.faXmark"></FontAwesomeIcon>
    </button>
  </div>
</template>


<script setup lang="ts">
import { onMounted, ref, nextTick } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import {FlipBoardEventBus, FlipBoardDataKind, ToggleFlipBoardState} from '@/infra/event_bus/FlipBoardEventBus';

let ctx:CanvasRenderingContext2D;
let ctxPreview:CanvasRenderingContext2D;
let isDrawing:boolean = false;
let isErasing:boolean = false;
let isRecting:boolean = false;
let isCircling:boolean = false;
let isPainting:boolean = false;

let selectedColor:string = '#000000';//cor inicial
let lastX:number = 0;
let lastY:number = 0;
let startPreviewX:number = 0;
let startPreviewY:number = 0;

const templateVisible = ref(false);

const closeElement = ref<HTMLButtonElement>();
const flipboardContainerElement = ref<HTMLDivElement>();

const penToolActive = ref(false);
const eraseToolActive = ref(false);
const bucketToolActive = ref(false);
const rectangleToolActive = ref(false);
const circleToolActive = ref(false);

const flipboardCanvasElement = ref<HTMLCanvasElement>();
const flipboardCanvasPreviewElement = ref<HTMLCanvasElement>();
const flipboardPaper = ref<HTMLDivElement>();

const clearAllToogle = () => {
  penToolActive.value = false;
  eraseToolActive.value = false;
  bucketToolActive.value = false;
  rectangleToolActive.value = false;
  circleToolActive.value = false;
}

const tooglePen = () => {
  if (penToolActive.value == false) {
    clearAllToogle();
    penToolActive.value = true;
  } else {
    penToolActive.value = false;
  }
}

const toogleErase = () => {
  if (eraseToolActive.value == false) {
    clearAllToogle();
    eraseToolActive.value = true;
  } else {
    eraseToolActive.value = false;
  }
}

const toogleBucket = () => {
  if (bucketToolActive.value == false) {
    clearAllToogle();
    bucketToolActive.value = true;
  } else {
    bucketToolActive.value = false;
  }
}

const toogleRectangle = () => {
  if (rectangleToolActive.value == false) {
    clearAllToogle();
    rectangleToolActive.value = true;
  } else {
    rectangleToolActive.value = false;
  }
}

const toogleCircle = () => {
  if (circleToolActive.value == false) {
    clearAllToogle();
    circleToolActive.value = true;
  } else {
    circleToolActive.value = false;
  }
}

const handleCloseScreen = () => {
  closeScreen();
  FlipBoardEventBus.toggleFlipBoard(ToggleFlipBoardState.OFF);
}

const closeScreen = () => {
  templateVisible.value = false;
}

const openScreen = () => {
  templateVisible.value = true;
  nextTick(() => {
    init();
  })
}


const controlScreen = (state:ToggleFlipBoardState) => {
  if(state === ToggleFlipBoardState.ON) {
    openScreen();
  } else {
    closeScreen();
  }
}

const handleRemoteData = (strData:string, kind:FlipBoardDataKind) => {

  const data = strData.split(",");

  switch (kind) {
    case FlipBoardDataKind.DRAW:
      remoteDrawLine(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.ERASE:
      remoteErase(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.RECT:
      remoteRect(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.CIRCLE:
      remoteCircle(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.FILL:
      remoteFill(
        Number(data[0]), 
        Number(data[1]), 
        hexToRgb(data[2]), 
        Number(data[3]), 
        Number(data[4]));
    break;

    case FlipBoardDataKind.CLEAR:
      remoteClear();
    break;
  
    default:
      break;
  }

}


/**
 *  DRAW FUNCTIONS
 */


const drawLine = (x:number, y:number) => {
  ctx.beginPath();
  ctx.moveTo(lastX, lastY);
  ctx.lineTo(x, y);
  ctx.lineWidth = 2;
  ctx.strokeStyle = selectedColor;
  ctx.stroke();
  ctx.closePath();
  [lastX, lastY] = [x, y];
}

const remoteDrawLine = (x:number, y:number, 
    lx:number, ly:number, color:string, 
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {
    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height;

    if(destWidth === 0 || destHeight === 0) {
      //if the component was never oppened before
      // and is receiving data, the canvas has no size
      destWidth = sourceWidth;
      destHeight = sourceHeight;
      _updateCanvasSize(destWidth, destHeight);
    }

    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;
    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    const dlx = lx * scaleX;
    const dly = ly * scaleY;
    // Draw the line on the destination canvas with the scaled coordinates
    ctx.beginPath();
    ctx.moveTo(dlx, dly);
    ctx.lineTo(dx, dy);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  }
  
}

function erase(x:number, y:number) {
  ctx.beginPath();
  ctx.fillStyle = "#ffffff";
  ctx.arc(x, y, 13, 0, 2 * Math.PI);
  ctx.fill();
  ctx.closePath();
}

const remoteErase = (
    x:number, y:number, 
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    ctx.beginPath();
    ctx.fillStyle = "#ffffff";
    ctx.arc(dx, dy, 13, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }
}

function previewRect(x:number, y:number, canvas:HTMLCanvasElement) {
  // Limpar o canvas
  ctxPreview.clearRect(0, 0, canvas.width, canvas.height);
  // Desenhar o retângulo de prévia
  ctxPreview.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Azul transparente
  ctxPreview.strokeRect(startPreviewX, startPreviewY, x - startPreviewX, y - startPreviewY);
}

function previewCircle(x:number, y:number, canvas:HTMLCanvasElement) {
  // Limpar o canvas
  ctxPreview.clearRect(0, 0, canvas.width, canvas.height);
  // Calcular os raios da elipse com base no movimento do mouse
  const radiusX = Math.abs(x - startPreviewX);
  const radiusY = Math.abs(y - startPreviewY);

  ctxPreview.beginPath();
  ctxPreview.ellipse(startPreviewX, startPreviewY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctxPreview.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Azul transparente
  ctxPreview.stroke();
}

function applyRect(x:number, y:number) {
  ctxPreview.clearRect(0,0,ctx.canvas.width, ctx.canvas.height);
  ctx.strokeStyle = selectedColor;
  ctx.strokeRect(startPreviewX, startPreviewY, x - startPreviewX, y - startPreviewY);
  isRecting = false;
}

const remoteRect = (
    x:number, y:number, lx:number, ly:number, color:string,
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    ctx.strokeStyle = color;
    ctx.strokeRect(lx, ly, dx - lx, dy - ly);
  }
}

function applyCircle(x:number, y:number) {
  ctxPreview.clearRect(0,0,ctx.canvas.width, ctx.canvas.height);
  const radiusX = Math.abs(x - startPreviewX);
  const radiusY = Math.abs(y - startPreviewY);
  ctx.beginPath();
  ctx.ellipse(startPreviewX, startPreviewY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.strokeStyle = selectedColor;
  ctx.stroke();
  isCircling = false;
}

const remoteCircle = (
    x:number, y:number, lx:number, ly:number, color:string,
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    const radiusX = Math.abs(dx - lx);
    const radiusY = Math.abs(dy - ly);
    ctx.beginPath();
    ctx.ellipse(lx, ly, radiusX, radiusY, 0, 0, 2 * Math.PI);
    ctx.strokeStyle = color;
    ctx.stroke();
  }
}


const handleCleanBoard = () => {
  clearBoard();
  FlipBoardEventBus.sendData(`0,0`, FlipBoardDataKind.CLEAR);
};

const clearBoard = () => {
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0,0, ctx.canvas.width, ctx.canvas.height);
}

const remoteClear = () => {
  clearBoard();
}


interface Pixel {
  x: number;
  y: number;
}

function floodFill(px:number, py:number, newColor:number[]) {
  let canvas:HTMLCanvasElement = flipboardCanvasElement.value!;
  if (canvas != undefined) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const originalColor = getColorAt(px, py, data);

    if (originalColor === newColor) return;

    function getColorAt(cx: number, cy: number, datai: Uint8ClampedArray): [number, number, number, number] {
      const index = (cy * canvas.width + cx) * 4;
      return [datai[index], datai[index + 1], datai[index + 2], 255];
    }
 
    const visited: Set<string> = new Set();
    const stack: Pixel[] = [{ x:px, y:py }];

    while (stack.length > 0) {
      const { x, y } = stack.pop()!;
      const index = (y * canvas.width + x) * 4;

      if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
        const color = getColorAt(x, y, data);
        if (color[0] === originalColor[0] && color[1] === originalColor[1] && color[2] === originalColor[2] && !visited.has(`${x},${y}`)) {
          data[index] = newColor[0];
          data[index + 1] = newColor[1];
          data[index + 2] = newColor[2];
          visited.add(`${x},${y}`);
          stack.push({ x: x + 1, y });
          stack.push({ x: x - 1, y });
          stack.push({ x: x, y: y + 1 });
          stack.push({ x: x, y: y - 1 });
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }
}


function remoteFill(px:number, py:number, newColor:number[],sourceWidth:number, sourceHeight:number) {
  let canvas:HTMLCanvasElement = flipboardCanvasElement.value!;
  if (canvas != undefined) {

    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = px * scaleX;
    const dy = py * scaleY;


    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const originalColor = getColorAt(dx, dy, data);

    if (originalColor === newColor) return;

    function getColorAt(cx: number, cy: number, datai: Uint8ClampedArray): [number, number, number, number] {
      const index = (cy * canvas.width + cx) * 4;
      return [datai[index], datai[index + 1], datai[index + 2], 255];
    }
 
    const visited: Set<string> = new Set();
    const stack: Pixel[] = [{ x:dx, y:dy }];

    while (stack.length > 0) {
      const { x, y } = stack.pop()!;
      const index = (y * canvas.width + x) * 4;

      if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
        const color = getColorAt(x, y, data);
        if (color[0] === originalColor[0] && color[1] === originalColor[1] && color[2] === originalColor[2] && !visited.has(`${x},${y}`)) {
          data[index] = newColor[0];
          data[index + 1] = newColor[1];
          data[index + 2] = newColor[2];
          visited.add(`${x},${y}`);
          stack.push({ x: x + 1, y });
          stack.push({ x: x - 1, y });
          stack.push({ x: x, y: y + 1 });
          stack.push({ x: x, y: y - 1 });
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }
}


const _updateCanvasSize = (w:number, h:number) => {
  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let preview = flipboardCanvasPreviewElement.value;
    
    let img = new Image();
    let dataURL = canvas.toDataURL();
    img.src = dataURL;
    
    canvas.setAttribute(
         "style", `width:${(w).toFixed(0)}px;height:${(h).toFixed(0)}px;`);
    canvas.setAttribute("width",`${(w).toFixed(0)}`);
    canvas.setAttribute("height",`${(h).toFixed(0)}`);

    
    preview!.setAttribute("width",`${(w).toFixed(0)}`);
    preview!.setAttribute("height",`${(h).toFixed(0)}`);
    // v-show perde ao reescrever o style
    let previewDisplay = '';
    if(rectangleToolActive.value === false && circleToolActive.value === false) {
      previewDisplay = 'display:none'
    }
    preview!.setAttribute(
         "style", `width:${(w).toFixed(0)}px;height:${(h).toFixed(0)}px;${previewDisplay}`);
    
    ctx = flipboardCanvasElement.value?.getContext('2d')!;
    ctxPreview = flipboardCanvasPreviewElement.value?.getContext('2d')!;
    img.onload = function() {
      ctx.drawImage(img,0,0, w, h);
    };
  }
}

const fixCanvasSize = () => {
  _updateCanvasSize(flipboardPaper.value?.clientWidth!, flipboardPaper.value?.clientHeight!);
}

const hexToRgb = (hex:string) => {
  // Remove o símbolo # se presente
  hex = hex.replace(/^#/, '');
  // Verifica se o valor hexadecimal tem 3 ou 6 dígitos
  if (hex.length === 3) {
    hex = hex.split('').map(hex => hex + hex).join('');
  }
  // Converte cada par de caracteres hexadecimais para um número decimal
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b, 255 ];
}

window.addEventListener("resize", fixCanvasSize, false);

const initUIState = () => {
  console.log("init UI flipboard");
  init();
  initEvents();
}

const init = () => {
  fixCanvasSize();

  ctx = flipboardCanvasElement.value?.getContext('2d')!;
  ctxPreview = flipboardCanvasPreviewElement.value?.getContext('2d')!;

  let canvas = flipboardCanvasElement.value;
  if (canvas) {
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0,0, canvas!.width, canvas!.height );
  }
}

const initEvents = () => {
  FlipBoardEventBus.onToogleFlipBoardEvt.subscribe(controlScreen.name, controlScreen);
  FlipBoardEventBus.onDataReceivedEvt.subscribe(handleRemoteData.name, handleRemoteData);
  FlipBoardEventBus.onClearBoard.subscribe(remoteClear.name, remoteClear);

  flipboardCanvasElement.value?.addEventListener('click', (e) => {
    if (bucketToolActive.value === true && !isPainting) {
      isPainting = true;
      floodFill(e.offsetX, e.offsetY, hexToRgb(selectedColor));
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.FILL);
      isPainting = false;
    }
  });

  flipboardCanvasElement.value?.addEventListener('mouseleave', (e) => {
    isDrawing = false;
    isErasing = false;
    isRecting = false;
    isCircling = false;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mouseleave', (e) => {
    isDrawing = false;
    isErasing = false;
    isRecting = false;
    isCircling = false;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });

  flipboardCanvasElement.value?.addEventListener('mousedown', (e) => {
    isDrawing = penToolActive.value;
    isErasing = eraseToolActive.value;
    isRecting = rectangleToolActive.value;
    isCircling = circleToolActive.value;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mousedown', (e) => {
    isRecting = rectangleToolActive.value;
    isCircling = circleToolActive.value;
    if (isRecting || isCircling) {
      [startPreviewX, startPreviewY] = [e.offsetX, e.offsetY];
    }
  });
  
  flipboardCanvasElement.value?.addEventListener('mousemove', (e) => {
    if (isDrawing){
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.DRAW);
      drawLine(e.offsetX, e.offsetY);
    } else if (isErasing) {
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.ERASE);
      erase(e.offsetX, e.offsetY);
    }
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mousemove', (e) => {
    if (isRecting) {
      let canvasPreview = flipboardCanvasPreviewElement.value;
      previewRect(e.offsetX, e.offsetY, canvasPreview!);
    } else if (isCircling) {
      let canvasPreview = flipboardCanvasPreviewElement.value;
      previewCircle(e.offsetX, e.offsetY, canvasPreview!);
    }
  });

  flipboardCanvasElement.value?.addEventListener('mouseup', (e) => {
    isDrawing = false;
    isErasing = false;
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mouseup', (e) => {
    if (isRecting === true) {
      applyRect(e.offsetX, e.offsetY);
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${startPreviewX},${startPreviewY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.RECT);
    } else if (isCircling) {
      applyCircle(e.offsetX, e.offsetY);
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${startPreviewX},${startPreviewY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.CIRCLE);
    }
  });
}

onMounted(initUIState);

</script>

<style scoped>
.flipboard-container {
  width: calc(100% - 308px);
  max-width: calc(100% - 308px);
  height: calc(100% - 130px);
  max-height: calc(100% - 130px);
  top: 8px;
  left: calc(50% + 300px);
  transform: translate(calc(-50% - 158px), 0);
  position: fixed;
  backdrop-filter: blur(32px);
  display: flex;
  justify-content: center;
  padding: 20px;
  z-index: 200;

  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px);
}

.flipboard-tools {
  width: 54px;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 9px 0 0 9px;
  border: 1px solid #a9a6a6;
  align-items: start;
  display: table-column;
  justify-content: center;
  padding-left: 7px;
  overflow: hidden;
}

.flipboard-tool {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
}

.innactive:hover {
  background-color: #ffffff;
}

.active {
  box-shadow: rgb(0, 0, 0) 2px 1px 0px 0px inset;
  background-color: #58af6e;

}

.active svg path {
  fill: #ffffff;
}

.flipboard-draw-area {
  width: calc(100% - 54px);
  padding: 16px;
  background-color: #c0c0c0;
  position: relative;
  height: auto;
  border-radius: 0 9px 9px 0;
  border: 1px solid #a9a6a6;
}

.flipboard-paper {
  max-height: calc(100% - 8px);
  aspect-ratio: 16 / 9;
  background-color: #ffffff;
  margin: 0 auto;
}

.flipboard-canvas-element {
  width: 100%;
  height: 100%;
  z-index: 4000;
}

.flipboard-canvas-preview-element {
  width: 100%;
  height: 100%;
  z-index: 4001;
  position: absolute;
  cursor:crosshair;
}

#decorator {
  top: 0px;
  position: fixed;
  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px);
  z-index: 3999;
}

.close-button {
  position: fixed;
  top: -8px;
  right: -8px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 26px;
  height: 26px;
  z-index: 4001;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.9)
}

.pen-active {
  cursor: url("@/assets/flipboard/pen.png") 0 38, auto;
}

.erase-active {
  cursor: url("@/assets/flipboard/eraser.png") 14 14, auto;
}

.color-picker {
  position: absolute;
  bottom: 28px;
  width: 38px;
  height: 38px;
  border-radius: 8px;
}

.color-picker input {
  width: 35px;
  border: 0px;
  height: 35px;
  border-radius: 12px;
  margin: 2px;
}

.st0 {
  fill: #231f20;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}</style>
export class LocalizationStorage {

  public static readonly key = 'localization';

  public static saveLanguage(language: string): void {
    localStorage.setItem(LocalizationStorage.key, language);
  }

  public static getLanguage(): string | null {
    return localStorage.getItem(LocalizationStorage.key);
  }
}
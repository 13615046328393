import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class EmojiModel {

  @JsonProperty()
  emoji?: string;

  @JsonProperty()
  sentTime: number;

  @JsonProperty()
  temporary: boolean;

  @JsonProperty()
  active: boolean;

  constructor(params: {
    emoji?: string;
    sentTime: number;
    temporary: boolean;
    active: boolean;
  }) {
    if (params) {
      this.emoji = params.emoji;
      this.sentTime = params.sentTime;
      this.temporary = params.temporary;
      this.active = params.active;
    } else {
      this.emoji = undefined;
      this.sentTime = 0;
      this.temporary = false;
      this.active = false;
    }

  }
}
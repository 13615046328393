<template>
  <div v-show="cameraEnabledState" class="participant-container-video-enabled  ui-shadows">
    <video v-show="cameraEnabledState" :id="`video-${participant!.identity}`" ref="videoElement" class="video-element"></video>

    <div ref="participantName" class="participant-name-container-video-enabled">
      <div class="participant-name">{{ participant!.name }}</div>
      <div ref="participantSignalElement" class="participant-signal fa-solid fa-signal"></div>
    </div>

    <div ref="participantMicContainer2" class="participant-mic-container div-off">
      <div ref="participantMicElement2" class="participant-mic fas fa-microphone-slash"></div>
    </div>
  </div>
  <div v-show="!cameraEnabledState" :id="`participant-${participant!.identity}`" class="participant-container  ui-shadows" ref="participantContainerElement">
    <div class="profile-container">
      <div class="profile-pic not-speaking" ref="participantProfilePic">
        {{ participant!.name?.substring(0,1) }}
        <div ref="participantMicContainer" class="participant-mic-container div-off">
          <div ref="participantMicElement" class="participant-mic fas fa-microphone-slash"></div>
        </div>
      </div>
    </div>
    <audio :id="`audio-${participant!.identity}`" ref="audioElement"></audio>
    <div ref="participantName" class="participant-name-container">
      <div class="participant-name">{{ participant!.name }}</div>
      <div ref="participantSignalElement" class="participant-signal fa-solid fa-signal"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Participant, RemoteTrack, RemoteTrackPublication, ParticipantEvent, Track, TrackPublication, LocalParticipant, RemoteParticipant } from 'livekit-client';
import { forEachChild } from 'typescript';
import {onMounted, onUnmounted, ref} from 'vue';

const props = defineProps({
  participant: {
    type: Participant,
    required: true,
  }
});

enum ConnectionQuality {
  Excellent = 'excellent',
  Good = 'good',
  Poor = 'poor',
  Lost = 'lost',
  Unknown = 'unknown',
}

let startTime = Date.now();
let cameraPub:TrackPublication | undefined = undefined;
let micPub:TrackPublication | undefined = undefined;

const videoElement = ref<HTMLVideoElement>();
const audioElement = ref<HTMLAudioElement>();
const participantSignalElement = ref<HTMLDivElement>();
const participantContainerElement = ref<HTMLDivElement>();
const participantProfilePic = ref<HTMLDivElement>();
const participantMicElement = ref<HTMLDivElement>();
const participantMicContainer= ref<HTMLDivElement>()
const participantMicElement2 = ref<HTMLDivElement>();
const participantMicContainer2= ref<HTMLDivElement>()

let cameraEnabledState = ref(false);
let audioEnabledState:boolean = false;
let micActiveState:boolean = false;
let isSpeakingState:boolean = false;
let signalQualityState:string = ConnectionQuality.Unknown;

defineExpose({
  updateUI() {
    _updateUI();
  }
})

const _updateUI = () => {

  console.log("update trigger", props.participant.name, props.participant.sid);
  console.log(props.participant["eventName"]);
  setVideo();
  setAudio();
  setMic();
  setSignalQuality();
  setSpeaking(undefined);
}

const initUIState = () => {
  console.log("init UI");
  //console.log(props.participant);
  props.participant
    .on(ParticipantEvent.TrackSubscribed, trackUpdateSubscription)
    .on(ParticipantEvent.TrackUnsubscribed, trackUpdateSubscription)
    .on(ParticipantEvent.TrackMuted, trackMuted)
    .on(ParticipantEvent.TrackUnmuted, trackUnMuted)
    .on(ParticipantEvent.IsSpeakingChanged, isSpeakingChanged)
    .on(ParticipantEvent.ConnectionQualityChanged, connectionQualityChanged);
  _updateUI();
}

const trackUpdateSubscription = (remoteTrack:RemoteTrack, remoteTrackPublication:RemoteTrackPublication) => {
  console.log("trackupdate", props.participant.name)
  setVideo();
  setAudio();
  setMic();
}

const trackMuted = (pub: TrackPublication) => {
  console.log("on_track_muted", props.participant.name);
  setVideo();
  setAudio();
  setMic();
}

const trackUnMuted = (pub: TrackPublication) => {
  console.log("on_track_unmuted", props.participant.name);
  setVideo();
  setAudio();
  setMic();
}

const isSpeakingChanged = (speaking: boolean) => {
  console.log("is speak changed", props.participant.name);
  setSpeaking(speaking);
}

const connectionQualityChanged = (connectionQuality: ConnectionQuality) => {
  console.log("quality changed", props.participant.name);
  setSignalQuality();
}

const destroy = () => {
  console.log("destroy");
  props.participant.off(ParticipantEvent.TrackSubscribed, trackUpdateSubscription);
  props.participant.off(ParticipantEvent.TrackUnsubscribed, trackUpdateSubscription);
  props.participant.off(ParticipantEvent.TrackMuted, trackMuted);
  props.participant.off(ParticipantEvent.TrackUnmuted, trackUnMuted);
  props.participant.off(ParticipantEvent.IsSpeakingChanged, isSpeakingChanged);
  props.participant.off(ParticipantEvent.ConnectionQualityChanged, connectionQualityChanged);
}

const setVideo = () => {
  if (videoElement.value) {
    let element:HTMLVideoElement = videoElement.value;
  
    cameraPub = props.participant.getTrackPublication(Track.Source.Camera);
    const cameraEnabled = cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
    
    //check if the state changes before update all elements
    if (cameraEnabled != cameraEnabledState.value) {
      //update camera state
      cameraEnabledState.value = cameraEnabled ? cameraEnabled : false;
      if (cameraEnabled) {
        if (props.participant instanceof LocalParticipant) {
          // flip
          element.style.transform = 'scale(-1, 1)';
        } else if (!cameraPub?.videoTrack?.attachedElements.includes(element)) {
          const renderStartTime = Date.now();
          // measure time to render
          element.onloadeddata = () => {
            const elapsed = Date.now() - renderStartTime;
            let fromJoin = 0;
            if (props.participant.joinedAt && props.participant.joinedAt.getTime() < startTime) {
              fromJoin = Date.now() - startTime;
            }
            console.log(
              `RemoteVideoTrack ${cameraPub?.trackSid} (${element.videoWidth}x${element.videoHeight}) rendered in ${elapsed}ms`,
              fromJoin > 0 ? `, ${fromJoin}ms from start` : '',
            );
          };
        }
        cameraPub?.videoTrack?.attach(element);
      } else {
        // clear information display
        if (cameraPub?.videoTrack) {
          // detach manually whenever possible
          cameraPub.videoTrack?.detach(element);
        } else {
          element.src = '';
          element.srcObject = null;
        }
      }
    }
  }
}

const setAudio = () => {
  if (audioElement.value) {
    let element:HTMLAudioElement = audioElement.value;

    micPub = props.participant.getTrackPublication(Track.Source.Microphone);
    const micEnabled = (micPub && micPub.isSubscribed && (micPub.isMuted === false))?true:false;
    //console.log("mic enabled", micEnabled)
    //console.log(micPub, micPub?.isSubscribed, micPub?.isMuted);
    if (micEnabled != audioEnabledState) {
      audioEnabledState = micEnabled ? micEnabled : false;
      if (micEnabled) {
        if (!(props.participant instanceof LocalParticipant)) {
          // don't attach local audio
          element.onloadeddata = () => {
            if (props.participant.joinedAt && props.participant.joinedAt.getTime() < startTime) {
              const fromJoin = Date.now() - startTime;
              console.log(`RemoteAudioTrack ${micPub?.trackSid} played ${fromJoin}ms from start`);
            }
          };
          micPub?.audioTrack?.attach(element);
        }
      } else {
        if (!(props.participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.detach(element);
        }
      }
    }
  }
}

const setMic = () => {
  if (participantMicElement.value &&
     participantMicContainer.value &&
     participantMicContainer2.value && 
     participantMicElement2.value) {

    let icon: HTMLDivElement = participantMicElement.value;
    let container: HTMLDivElement = participantMicContainer.value;
    let container2: HTMLDivElement = participantMicContainer2.value;
    let icon2: HTMLDivElement = participantMicElement2.value;
    
    micPub = props.participant.getTrackPublication(Track.Source.Microphone);
    const micEnabled = (micPub && (micPub.isMuted === false))?true:false;
    
    //update only if state changes
    if (micEnabled != micActiveState) {
      micActiveState = micEnabled ? micEnabled : false;

      //update mic state on css
      if (micActiveState) {
        icon.classList.remove("fa-microphone-slash");
        icon.classList.add("fa-microphone");
        container.classList.add("div-on");
        container.classList.remove("div-off");

        icon2.classList.remove("fa-microphone-slash");
        icon2.classList.add("fa-microphone");
        container2.classList.add("div-on");
        container2.classList.remove("div-off");
      } else {
        icon.classList.add("fa-microphone-slash");
        icon.classList.remove("fa-microphone");
        container.classList.remove("div-on");
        container.classList.add("div-off");

        icon2.classList.add("fa-microphone-slash");
        icon2.classList.remove("fa-microphone");
        container2.classList.remove("div-on");
        container2.classList.add("div-off");
      }
    }
  }
}


const setSignalQuality = () => {
  if (participantSignalElement.value) {

    let element:HTMLDivElement = participantSignalElement.value;
    const quality = props.participant.connectionQuality.valueOf();

    if(quality != signalQualityState) {
      signalQualityState = quality;
      switch (signalQualityState) {
        case ConnectionQuality.Poor:
          element.classList.remove("color-off");
          element.classList.add("color-poor");
          element.classList.remove("color-good");
          element.classList.remove("color-excellent");
          break;
        case ConnectionQuality.Good:
          element.classList.remove("color-off");
          element.classList.remove("color-poor");
          element.classList.add("color-good");
          element.classList.remove("color-excellent");
          break;
        case ConnectionQuality.Excellent:
          element.classList.remove("color-off");
          element.classList.remove("color-poor");
          element.classList.remove("color-good");
          element.classList.add("color-excellent");
          break;
        default:
          element.classList.add("color-off");
          element.classList.remove("color-poor");
          element.classList.remove("color-good");
          element.classList.remove("color-excellent");
          break;
      }
    }
  }
}

const setSpeaking = (isSpeaking:boolean | undefined) => {
  if (participantProfilePic.value) {
    let element:HTMLDivElement = participantProfilePic.value;
    const speaking = isSpeaking == undefined ? props.participant.isSpeaking: isSpeaking;
    if (speaking != isSpeakingState) {
      isSpeakingState = speaking;
      if(isSpeakingState) {
        element.classList.add("speaking");
        element.classList.remove("not-speaking");
      } else {
        element.classList.remove("speaking");
        element.classList.add("not-speaking");
      }
    }
  }
}

onMounted(initUIState);
onUnmounted(destroy);
</script>

<style scoped>
.participant-container {
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 24px;
  padding: 16px 32px 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  backdrop-filter: blur(32px);
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;
}

.participant-container-video-enabled {
  position: relative;
  display: flex;
  width: 100%;
  padding: 8px;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  backdrop-filter: blur(32px);
  border-radius: 24px;
}

.participant-name-container-video-enabled {
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  align-items: center;

  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  backdrop-filter: blur(32px);
  padding: 6px 12px;
  border-radius: 8px;
}

.participant-container-video-enabled .participant-name {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.participant-container-video-enabled .participant-mic-container {
  bottom: 16px;
  right: 16px;
}

.video-element{
  width: 100%;
  border-radius: 17px;
}
.participant-name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.participant-name{
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.participant-signal {
  margin-left:5px;
  margin-right:5px;
  height:15px;
  font-size: 12px;
}

.participant-mic-container {
  position: absolute;
  right: -4px;
  bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  width: 20px;
  height: 20px;
}

.participant-mic {
  font-size: 10px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-pic {
  position: relative;
  width:50px;
  height:50px;
  border-radius: 10000px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
  background-color: #c1bcfd;
}
.profile-container {
  display:flex;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}
.div-off {
  background-color: #b95356;
}
.div-on {
  background-color: #58af6e;
}
.color-off {
  color: #353535;
}
.color-on {
  color: #58af6e;
}
.color-poor{
  color:darkred;
}
.color-good{
  color:darkorange;
}
.color-excellent{
  color:green;
}
.speaking {
  border: 2px solid #58af6e;
  box-shadow: 0px 0px 20px 1.21px rgba(13, 149, 51, 1.00);
}
.not-speaking {
  border: 2px solid transparent;
}
</style>
<template>
  <div class="screen-container" @click="pinScreen">
    <video ref="screenVideoElement" class="video-element"></video>
    <div class="name-element">{{screen.screenName}}</div>
    <button class="pin-button" ref="screenPinElement">
      <FontAwesomeIcon :icon="Icons.imported.faMapPin"></FontAwesomeIcon>
    </button>
  </div>
</template>


<script setup lang="ts">
import { TrackPublication, RemoteTrackPublication } from 'livekit-client';
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import {useMediaStore} from "../../store/media_store";
import {onMounted, ref} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';

const mediaStore = useMediaStore();

const props = defineProps({
  screen: {
    type: ScreenShareDTO,
    required: true,
  }
});

let screenPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;

const screenVideoElement = ref<HTMLVideoElement>();
const screenPinElement = ref<HTMLButtonElement>();

defineExpose({
  updateUI() {
    _updateUI();
  }
})

const pinScreen = () => {
  mediaStore.updateBigScreenShare(props.screen);
}

const _updateUI = () => {
  if(screenVideoElement.value) {
    screenPub = props.screen.screen;
    if (screenPub) {
      screenPub.videoTrack?.attach(screenVideoElement.value);
    }
  }
}

const initUIState = () => {
  console.log("init UI screen");
  _updateUI();
}

onMounted(initUIState);

</script>

<style scoped>
.screen-container{
  position: relative;
  overflow: hidden;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 24px;
  flex: 1;
  max-height: 130px;
  min-height: 50px;
  cursor: pointer;
  margin: 8px;
}

.screen-container:hover {
  background: #000;
  opacity: 0.95;
}

.video-element{
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.name-element {
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: #353535;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  backdrop-filter: blur(32px);
  font-size: 12px;
  padding: 6px 12px;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-weight: 800;
  border-radius: 8px;
  line-height: normal;
}

.pin-button {
  position: absolute;
  top: 8px;
  right: 8px;

  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  backdrop-filter: blur(32px);
  border-radius: 12px;
  width: 32px;
  height: 32px;
  color: #353535;
}

.pin-button:hover {
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pen-canvas-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", {
      width: "0",
      height: "0",
      class: _normalizeClass([{ 'pen-active': $setup.penActive, 'erase-active': $setup.eraseActive }, "pen-canvas"]),
      ref: "penCanvas"
    }, null, 2 /* CLASS */),
    _createVNode($setup["PenToolsComponent"], {
      "onUpdate:penActive": $setup.handlePenActive,
      "onUpdate:eraseActive": $setup.handleEraseActive,
      "onUpdate:selectedColor": $setup.handleColorChange,
      onCleanBoard: $setup.handleCleanBoard
    })
  ]))
}
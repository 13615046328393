import { defineComponent as _defineComponent } from 'vue'
import { inject, onMounted, onUnmounted, Ref, ref } from 'vue';
//import { useRouter } from 'vue-router'; 
import MediaServerClient from '../infra/opagameserver/MediaServerClient';
import PhaserGame from '../game/PhaserGame.vue';
import MediaParticipantsComponent from '../components/media/MediaParticipantsComponent.vue';
import LocalParticipantComponent from '../components/media/LocalParticipantComponent.vue';
import MediaControllersComponent from '../components/media/MediaControllersComponent.vue';
import GameServerClient from '@/infra/opagameserver/gameserver/GameServerClient';
import { InteractionFailed } from '@/domain/gameserver/interaction-failed';
import CommonToast from '@/components/CommonToast.vue';
import { CommonToastInjection, InteractionIframeInjection } from '@/core/constants/injections';
import ChatUI from '@/components/chat/ChatUI.vue';
import { ChangeMediaRoomRes } from '@/domain/gameserver/change-media-room-res.model';
import MediaScreenSharesComponent from '@/components/media/MediaScreenSharesComponent.vue';
import MediaScreenComponent from '@/components/media/MediaScreenComponent.vue';
import { useMediaStore } from "../store/media_store";
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import { IframeEvt } from '@/domain/gameserver/iframe-evt.model';
import InteractionIframe from '@/components/modals/InteractionIframe.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import { routes } from '@/router';
import { useUserStore } from '@/store/login_store';
import EmojiContainerComponent from '@/components/emoji/EmojiContainerComponent.vue';
import { Emoji, EMOJI_TIMER } from '@/core/constants/emoji_constants';
import UIShadowButton from '@/components/UIShadowButton.vue';
import { Icons } from '@/font-awesome';
import GuestModalComponent from '@/components/guest/GuestModalComponent.vue';
import UniverseStorage from '@/infra/local_storage/universe.storage';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';
import { RoomScene } from '@/game/scenes/RoomScene';
import { usePlayersStore } from '@/store/players_store';
import { Character } from '@/game/game_objects/Character';
import FlipBoardComponent from '@/components/media/FlipBoardComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GameScene',
  setup(__props, { expose: __expose }) {
  __expose();

const phaserRef = ref<InstanceType<typeof PhaserGame>>();
const mediaServer: MediaServerClient = new MediaServerClient();
const screenRef = ref<InstanceType<typeof MediaScreenComponent>>();
const guestModal = ref<InstanceType<typeof GuestModalComponent>>();


const mediaStore = useMediaStore();
const userStore = useUserStore();
const playersStore = usePlayersStore();
const commonToast = inject<Ref<InstanceType<typeof CommonToast>>>(CommonToastInjection);
const interactionIframe = inject<Ref<InstanceType<typeof InteractionIframe>>>(InteractionIframeInjection);

const showEmojiContainer = ref(false);

async function connectToRoom(changeMediaRoomRes: ChangeMediaRoomRes) {
  await mediaServer.connectToRoom(changeMediaRoomRes.roomName, changeMediaRoomRes.token);
  mediaServer.penDataCallback = handlePenDataReceived;
}

function cleanMediaRoom(id: string) {
  console.log("id", id);
  mediaStore.cleanRemote(id);
}

onMounted(() => {
  GameServerClient.onPlayerLeaveEvt.subscribe("CleanMediaRoom", cleanMediaRoom.bind(this));
  GameServerClient.onChangeMediaRoomEvt.subscribe("ChangeMediaRoom", connectToRoom.bind(this));
  GameServerClient.onInteractionFailedEvt.subscribe(showInteractionFailed.name, showInteractionFailed.bind(this));
  GameServerClient.onIframeEvt.subscribe(showInteractionIframe.name, showInteractionIframe.bind(this));
  RoomScene.onPlayerListChanged.subscribe(onPlayerListChanged.name, onPlayerListChanged.bind(this));
});

onUnmounted(() => {
  GameServerClient.onPlayerLeaveEvt.unsubscribe("CleanMediaRoom");
  GameServerClient.onChangeMediaRoomEvt.unsubscribe("ChangeMediaRoom");
  GameServerClient.onInteractionFailedEvt.unsubscribe(showInteractionFailed.name);
  GameServerClient.onIframeEvt.unsubscribe(showInteractionIframe.name);
  RoomScene.onPlayerListChanged.unsubscribe(onPlayerListChanged.name);
});

async function showInteractionIframe(interactionIframeEvt: IframeEvt) {
  if (interactionIframeEvt.open) {
    await interactionIframe?.value.open({
      iframeUrl: interactionIframeEvt.url,
    });
  } else {
    interactionIframe?.value.close(undefined);
  }
}

function showInteractionFailed(interactionFailed: InteractionFailed) {
  commonToast?.value?.open({
    textKey: `error.interactionFailed.${interactionFailed.reason}`,
    state: 'error',
  });
}

function onPlayerListChanged(players: Character[]) {
  console.log("[LOG] Character list updated:", players);
  playersStore.players = [...players];
}

mediaStore.$subscribe((mutation, state) => {
  //update screen UI
  screenRef.value?.updateUI();
})

function handlePenDataReceived(data: string, topic: string) {
  screenRef.value?.onRemotePenDataReceived(data, topic);
}


const __returned__ = { phaserRef, mediaServer, screenRef, guestModal, mediaStore, userStore, playersStore, commonToast, interactionIframe, showEmojiContainer, connectToRoom, cleanMediaRoom, showInteractionIframe, showInteractionFailed, onPlayerListChanged, handlePenDataReceived, PhaserGame, MediaParticipantsComponent, LocalParticipantComponent, MediaControllersComponent, get GameServerClient() { return GameServerClient }, ChatUI, MediaScreenSharesComponent, MediaScreenComponent, ContextMenu, get routes() { return routes }, EmojiContainerComponent, get EMOJI_TIMER() { return EMOJI_TIMER }, UIShadowButton, get Icons() { return Icons }, GuestModalComponent, get UniverseStorage() { return UniverseStorage }, get LoggedUserStorage() { return LoggedUserStorage }, FlipBoardComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { ImutableModel } from "./imutable.model";

@JsonObject()
export class Universe extends ImutableModel {
  @JsonProperty()
  public name: string;
  @JsonProperty()
  public domain: string;
  @JsonProperty()
  public logoUrl: string;
  @JsonProperty()
  public allowGuests: boolean;

  constructor(params?: {
    id: string;
    name: string;
    domain: string;
    logoUrl: string;
    allowGuests: boolean;
  }) {
    super(params);
    if (!params) return;
    this.id = params.id;
    this.name = params.name;
    this.domain = params.domain;
    this.logoUrl = params.logoUrl;
    this.allowGuests = params.allowGuests;
  }
}
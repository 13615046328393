import { defineComponent as _defineComponent } from 'vue'
import {useMediaStore} from "../../store/media_store";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import { ref, onMounted } from "vue";
import {FlipBoardEventBus, ToggleFlipBoardState} from '@/infra/event_bus/FlipBoardEventBus';



export default /*@__PURE__*/_defineComponent({
  __name: 'MediaControllersComponent',
  emits: ["on-off-local-video", "on-off-local-audio", "on-off-local-screen"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const mediaStore = useMediaStore();

const flipboardOn = ref(false);

const emit = __emit

const onOffLocalVideo = async () => {
  emit("on-off-local-video");
}

const onOffLocalAudio = async () => {
  emit("on-off-local-audio");
}

const onOffLocalScreen = async () => {
  emit("on-off-local-screen");
}

const onOffFlipboard = async (onOff?:ToggleFlipBoardState) => {
  if (onOff !== undefined) {
    flipboardOn.value = onOff === ToggleFlipBoardState.ON ? true : false;
  } else {
    if(flipboardOn.value === true) {
      flipboardOn.value = false;
      FlipBoardEventBus.toggleFlipBoard(ToggleFlipBoardState.OFF);
    } else {
      flipboardOn.value = true;
      FlipBoardEventBus.toggleFlipBoard(ToggleFlipBoardState.ON);
    }
  }
}


const initComponent = () => {
  FlipBoardEventBus.onToogleFlipBoardEvt.subscribe(onOffFlipboard.name, (t:ToggleFlipBoardState) => onOffFlipboard(t));
}

onMounted(initComponent);

const __returned__ = { mediaStore, flipboardOn, emit, onOffLocalVideo, onOffLocalAudio, onOffLocalScreen, onOffFlipboard, initComponent, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
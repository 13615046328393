import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { CachePlayer } from "./cache-player.model";

@JsonObject()
export class CachePlayersChangeRes {

  @JsonProperty({ type: CachePlayer })
  cachePlayers: CachePlayer[];

  constructor(params: {
    cachePlayers: CachePlayer[];
  }) {
    if (!params) return;
    this.cachePlayers = params.cachePlayers;
  }

}
import { PaginationDTO } from "@/dto/pagination.dto";
import UniverseStorage from "@/infra/local_storage/universe.storage";
import { UniversesRestDao } from "@/infra/opagameserver/rest/universes-rest.dao";
import { Universe } from "@/models/universe.model";
import { ValueResult } from "@/models/value-result.model";
import { defineStore } from "pinia";

interface IState {
  universe: Universe | null;
}

export const useUniverseStore = defineStore("universe_store", {
  state: (): IState => ({
    universe: null
  }),
  actions: {
    async getUniverse(params: {
      filters:{
        domains: string[]
      },
      pagination?: PaginationDTO
    }): Promise<ValueResult<Universe | null>> {
      const response = await UniversesRestDao.getUniverse({
        ...params
      });

      const [responseValue, responseError] = [response?.getValue()! ,response.getError()!];
      if (responseError) return ValueResult.fromError(responseError);
      this.universe = responseValue.items[0] ?? null;
      if (!responseValue.items[0]) return new ValueResult();

      UniverseStorage.setData(responseValue.items[0]);

      return new ValueResult({ value: responseValue.items[0] });
    },
  }
});
import { defineComponent as _defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, computed } from 'vue';
import CommonModal from './CommonModal.vue';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import CommonButton from '../CommonButton.vue';
import { useLocalizationStore } from '@/store/localization_store';


export default /*@__PURE__*/_defineComponent({
  __name: 'AlertModal',
  setup(__props, { expose: __expose }) {

const localizationStore = useLocalizationStore();
const titleAlign = ref<'start' | 'center'>('center');
const titleElem = ref<HTMLHeadElement>();
const contentElem = ref<HTMLParagraphElement>();
const modalType = ref<'default' | 'success' | 'error'>('default');
const buttons = ref<{
  text: string;
  action: () => void;
  cta?: 'cta' | 'secondary' | 'error';
  type?: 'filled' | 'outlined';
  size?: 'small' | 'medium' | 'large';
  ilustration?: string;
}[]>([]);
const titleColor = ref<string>();
const icon = ref<IconDefinition | null>(null);
const alertModalV2 = ref<InstanceType<typeof CommonModal>>();
const isFullScreen = ref<boolean>(false);
const canClose = ref<boolean>(true);
const size = ref<'small' | 'medium'>('small');
const ilustration = ref<string>('');

__expose({
  open: async (params: {
    modalType?: 'default' | 'success' | 'error', 
    title: string,
    customContentStyle?: {
      color?: string,
      background?: string,
      fontSize?: string, 
    }
    customContent?: string;
    content: string,
    buttons?: {
      text: string;
      action: () => void;
      cta?: 'cta' | 'secondary' | 'error';
      type?: 'filled' | 'outlined';
      size?: 'small' | 'medium' | 'large';
    }[],
    titleColor?: "cta" | "success" | "error",
    zIndex?: number,
    isFullScreen?: boolean,
    canClose?: boolean,
    size?: 'small' | 'medium'
    icon?: IconDefinition,
    ilustration?: string;
  }) => {
    // Configuração padrão dos parâmetros
    titleElem.value!.innerText = await localizationStore.getKey(params.title) ?? '';
    contentElem.value!.innerHTML = await localizationStore.getKey(params.content) ?? '';
    isFullScreen.value = params.isFullScreen ?? false;
    canClose.value = params.canClose ?? true;
    size.value = params.size ?? 'small';
    alertModalV2.value?.open({ zIndex: params.zIndex ?? 1000 });
    icon.value = params.icon ?? null;

    // Configuração dinâmica com base no modalType
    modalType.value = params.modalType ?? 'default';
    ilustration.value = params.ilustration ?? '';

    switch (modalType.value) {
      case 'success':
        titleColor.value = 'success';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
            cta: 'cta',
            type: 'filled',
          },
        ];
        break;

      case 'error':
        titleColor.value = 'error';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
            cta: 'secondary',
            type: 'outlined',
          },
        ];
        break;

      default:
        titleColor.value = params.titleColor ?? 'cta';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
          },
        ];
        break;
    }
  },
  close: () => {
    alertModalV2.value?.close();
  },
});

const titleColorClass = computed(() => {
  switch (titleColor.value) {
    case 'success':
      return 'var(--components-modal-color-success-title-color, #31812A)';
    case 'error':
      return 'var(--components-modal-color-error-title-color, #C63D3D)';
    default:
      return 'var(--components-modal-color-default-title-color, #fff)';
  }
});

const __returned__ = { localizationStore, titleAlign, titleElem, contentElem, modalType, buttons, titleColor, icon, alertModalV2, isFullScreen, canClose, size, ilustration, titleColorClass, get FontAwesomeIcon() { return FontAwesomeIcon }, CommonModal, CommonButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
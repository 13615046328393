// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Estilos para os tabs e o conteúdo */
.tabs[data-v-7f2f4dce] {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0px;
  width: 100%;
}
.tabs li[data-v-7f2f4dce] {
  cursor: pointer;
  height: 40px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #c0c0c0;
  flex-grow: 1;
  flex-basis: 0;
  align-content: center;
  text-align: center;
}
.tabs li.innactive[data-v-7f2f4dce]:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}
.tabs li.active[data-v-7f2f4dce] {
  background-color: #58af6e;
  color: #ffffff;
}
.tabs li.active svg[data-v-7f2f4dce] {
  fill: #ffffff;
}
.emoji-component-modal[data-v-7f2f4dce] {
  position: fixed;
  bottom: 100px;
  left: calc(50% - 140px);
  z-index: 110;
  height: 300px;
  width: 387px;
  /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%); */
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 8px;
  border: 1px solid #ffffff;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  padding: 8px;
}
.emoji-container[data-v-7f2f4dce] {

  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 200px;
  width: 100%;
  border: 1px solid #c0c0c0;
}
.emoji-row[data-v-7f2f4dce] {
  display: flex;
}
.emoji-col[data-v-7f2f4dce] {
  width: 12.5%;
  /* Cada coluna ocupará 12.5% da largura total */
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid rgb(255, 255, 255);
  border-left: 1px solid rgb(255, 255, 255);
  padding: 10px;
  font-size: 20px;
  width: 44px;
  height: 44px;
  font-family: "NotoColorEmoji", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}
.emoji-col[data-v-7f2f4dce]:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title[data-v-26322191] {
  font-weight: 800;
  font-size: 32px;
  color: #fafafa;
  text-align: center;
}
.guest-item p[data-v-26322191] {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
}
.guest-item[data-v-26322191] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
}
.guest-buttons[data-v-26322191] {
  display: flex;
  gap: 2px;
}
.guest-button[data-v-26322191] {
  padding: 8px;
  background-color: #aaa;
  border-radius: 8px;
  width: 30px;
}
.guest-button[data-v-26322191]:not(.selected) {
  cursor: pointer;
}
.guest-button[data-v-26322191]:not(.selected):hover {
  background-color: #ccc;
}
.guest-button.selected[data-v-26322191] {
  background-color: #58af6e;
  color: #fff;
}
.guest-list[data-v-26322191] {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 350px;
  overflow-y: auto;
  padding: 16px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { defineComponent as _defineComponent } from 'vue'
import LocalizedText from './LocalizedText.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonButton',
  props: {
    labelKey: { type: String, required: true }
  },
  emits: ["click"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;




const __returned__ = { props, LocalizedText }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center flex-column" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  ref: "contentElem",
  class: "content-paragraph"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "alertModalV2",
    "can-close": $setup.canClose,
    "is-full-screen": $setup.isFullScreen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass($setup.size === 'small' ? 'alert-modal-small' : 'alert-modal-medium')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            ($setup.icon && !$setup.ilustration)
              ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                  key: 0,
                  icon: $setup.icon,
                  class: "alert-icon",
                  style: _normalizeStyle({ color: $setup.titleColorClass })
                }, null, 8 /* PROPS */, ["icon", "style"]))
              : _createCommentVNode("v-if", true),
            ($setup.ilustration)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: $setup.ilustration,
                  class: "ilustration"
                }, null, 8 /* PROPS */, _hoisted_3))
              : _createCommentVNode("v-if", true)
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"height":"var(--semantic-size-components-modal-spacing-gap-icon-title, 32px)"} }, null, -1 /* HOISTED */)),
          _createElementVNode("div", null, [
            _createElementVNode("h3", {
              ref: "titleElem",
              class: "title",
              style: _normalizeStyle({ color: $setup.titleColorClass, textAlign: $setup.titleAlign })
            }, null, 4 /* STYLE */)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"height":"var(--semantic-size-components-modal-spacing-gap-title-slot, 16px)"} }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"height":"var(--semantic-size-components-modal-spacing-gap-slot-button, 48px)"} }, null, -1 /* HOISTED */)),
        _createElementVNode("div", {
          class: _normalizeClass('buttons'),
          style: _normalizeStyle({ justifyContent: $setup.buttons.length > 1 ? 'space-between' : 'center'})
        }, [
          ($setup.buttons[0] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 0,
                onClick: $setup.buttons[0]?.action,
                "label-key": $setup.buttons[0]?.text,
                class: _normalizeClass($setup.size === 'medium' ? 'w-100' : '')
              }, null, 8 /* PROPS */, ["onClick", "label-key", "class"]))
            : _createCommentVNode("v-if", true),
          ($setup.buttons[1] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 1,
                onClick: $setup.buttons[1]?.action,
                "label-key": $setup.buttons[1]?.text
              }, null, 8 /* PROPS */, ["onClick", "label-key"]))
            : _createCommentVNode("v-if", true)
        ], 4 /* STYLE */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["can-close", "is-full-screen"]))
}
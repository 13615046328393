import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "screenContainerElement",
  class: "screen-container"
}
const _hoisted_2 = {
  ref: "screenVideoElement",
  class: "video-element",
  autoplay: ""
}
const _hoisted_3 = { ref: "screenAudioElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", _hoisted_2, null, 512 /* NEED_PATCH */),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      id: "decorator",
      class: "ui-shadows"
    }, null, -1 /* HOISTED */)),
    _createElementVNode("button", {
      class: "close-button ui-shadows",
      ref: "closeElement",
      onClick: $setup.closeScreen
    }, [
      _createVNode($setup["FontAwesomeIcon"], {
        icon: $setup.Icons.imported.faXmark
      }, null, 8 /* PROPS */, ["icon"])
    ], 512 /* NEED_PATCH */),
    _createElementVNode("audio", _hoisted_3, null, 512 /* NEED_PATCH */),
    _createVNode($setup["PenCanvasComponent"], {
      ref: "penRef",
      mediaServer: ($setup.props.mediaServer as MediaServerClient)
    }, null, 8 /* PROPS */, ["mediaServer"])
  ], 512 /* NEED_PATCH */))
}
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class Vector2 {
  @JsonProperty()
  x: number;
  @JsonProperty()
  y: number;

  constructor(params: {
    x: number;
    y: number;
  }) {
    if (!params) return;
    this.x = params.x;
    this.y = params.y;
  }

  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  add(vector: Vector2): Vector2 {
    this.x += vector.x;
    this.y += vector.y;
    return this;
  }

  multiplyByVector(vector: Vector2): Vector2 {
    this.x *= vector.x;
    this.y *= vector.y;
    return this;
  }
  
  multiplyByScalar(scalar: number): Vector2 {
    this.x *= scalar;
    this.y *= scalar;
    return this;
  }

  normalize(): Vector2 {
    const length = this.length();
    this.x /= length;
    this.y /= length;
    return this;
  }

  distance(vector: Vector2): number {
    return Math.sqrt((this.x - vector.x) * (this.x - vector.x) + (this.y - vector.y) * (this.y - vector.y));
  }

  clone() {
    return new Vector2({ x: this.x, y: this.y });
  }

  toPixel(params: {
    tileWidth: number;
    tileHeight: number;
  }): Vector2 {
    return new Vector2({ x: this.x * params.tileWidth + params.tileWidth / 2, y: this.y * params.tileHeight });
  }

  toTiledVector2(params: {
    tileWidth: number;
    tileHeight: number;
  }): Vector2 {
    return new Vector2({ x: Math.floor(this.x / params.tileWidth), y: Math.floor(this.y / params.tileHeight) });
  }

  static one(): Vector2 {
    return new Vector2({ x: 1, y: 1 });
  }

  static zero(): Vector2 {
    return new Vector2({ x: 0, y: 0 });
  }
  
}
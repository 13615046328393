import { defineComponent as _defineComponent } from 'vue'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';



export default /*@__PURE__*/_defineComponent({
  __name: 'UIShadowButton',
  props: {
    icon: { type: Object, required: false }
  },
  emits: ["click"],
  setup(__props: any, { expose: __expose }) {
  __expose();






const __returned__ = { get FontAwesomeIcon() { return FontAwesomeIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
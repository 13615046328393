<script setup lang="ts">
import { inject, onMounted, onUnmounted, Ref, ref } from 'vue';
//import { useRouter } from 'vue-router'; 
import MediaServerClient from '../infra/opagameserver/MediaServerClient';
import PhaserGame from '../game/PhaserGame.vue';
import MediaParticipantsComponent from '../components/media/MediaParticipantsComponent.vue';
import LocalParticipantComponent from '../components/media/LocalParticipantComponent.vue';
import MediaControllersComponent from '../components/media/MediaControllersComponent.vue';
import GameServerClient from '@/infra/opagameserver/gameserver/GameServerClient';
import { InteractionFailed } from '@/domain/gameserver/interaction-failed';
import CommonToast from '@/components/CommonToast.vue';
import { CommonToastInjection, InteractionIframeInjection } from '@/core/constants/injections';
import ChatUI from '@/components/chat/ChatUI.vue';
import { ChangeMediaRoomRes } from '@/domain/gameserver/change-media-room-res.model';
import MediaScreenSharesComponent from '@/components/media/MediaScreenSharesComponent.vue';
import MediaScreenComponent from '@/components/media/MediaScreenComponent.vue';
import { useMediaStore } from "../store/media_store";
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import { IframeEvt } from '@/domain/gameserver/iframe-evt.model';
import InteractionIframe from '@/components/modals/InteractionIframe.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import { routes } from '@/router';
import { useUserStore } from '@/store/login_store';
import EmojiContainerComponent from '@/components/emoji/EmojiContainerComponent.vue';
import { Emoji, EMOJI_TIMER } from '@/core/constants/emoji_constants';
import UIShadowButton from '@/components/UIShadowButton.vue';
import { Icons } from '@/font-awesome';
import GuestModalComponent from '@/components/guest/GuestModalComponent.vue';
import UniverseStorage from '@/infra/local_storage/universe.storage';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';
import { RoomScene } from '@/game/scenes/RoomScene';
import { usePlayersStore } from '@/store/players_store';
import { Character } from '@/game/game_objects/Character';
import FlipBoardComponent from '@/components/media/FlipBoardComponent.vue';

const phaserRef = ref<InstanceType<typeof PhaserGame>>();
const mediaServer: MediaServerClient = new MediaServerClient();
const screenRef = ref<InstanceType<typeof MediaScreenComponent>>();
const guestModal = ref<InstanceType<typeof GuestModalComponent>>();


const mediaStore = useMediaStore();
const userStore = useUserStore();
const playersStore = usePlayersStore();
const commonToast = inject<Ref<InstanceType<typeof CommonToast>>>(CommonToastInjection);
const interactionIframe = inject<Ref<InstanceType<typeof InteractionIframe>>>(InteractionIframeInjection);

const showEmojiContainer = ref(false);

async function connectToRoom(changeMediaRoomRes: ChangeMediaRoomRes) {
  await mediaServer.connectToRoom(changeMediaRoomRes.roomName, changeMediaRoomRes.token);
  mediaServer.penDataCallback = handlePenDataReceived;
}

function cleanMediaRoom(id: string) {
  console.log("id", id);
  mediaStore.cleanRemote(id);
}

onMounted(() => {
  GameServerClient.onPlayerLeaveEvt.subscribe("CleanMediaRoom", cleanMediaRoom.bind(this));
  GameServerClient.onChangeMediaRoomEvt.subscribe("ChangeMediaRoom", connectToRoom.bind(this));
  GameServerClient.onInteractionFailedEvt.subscribe(showInteractionFailed.name, showInteractionFailed.bind(this));
  GameServerClient.onIframeEvt.subscribe(showInteractionIframe.name, showInteractionIframe.bind(this));
  RoomScene.onPlayerListChanged.subscribe(onPlayerListChanged.name, onPlayerListChanged.bind(this));
});

onUnmounted(() => {
  GameServerClient.onPlayerLeaveEvt.unsubscribe("CleanMediaRoom");
  GameServerClient.onChangeMediaRoomEvt.unsubscribe("ChangeMediaRoom");
  GameServerClient.onInteractionFailedEvt.unsubscribe(showInteractionFailed.name);
  GameServerClient.onIframeEvt.unsubscribe(showInteractionIframe.name);
  RoomScene.onPlayerListChanged.unsubscribe(onPlayerListChanged.name);
});

async function showInteractionIframe(interactionIframeEvt: IframeEvt) {
  if (interactionIframeEvt.open) {
    await interactionIframe?.value.open({
      iframeUrl: interactionIframeEvt.url,
    });
  } else {
    interactionIframe?.value.close(undefined);
  }
}

function showInteractionFailed(interactionFailed: InteractionFailed) {
  commonToast?.value?.open({
    textKey: `error.interactionFailed.${interactionFailed.reason}`,
    state: 'error',
  });
}

function onPlayerListChanged(players: Character[]) {
  console.log("[LOG] Character list updated:", players);
  playersStore.players = [...players];
}

mediaStore.$subscribe((mutation, state) => {
  //update screen UI
  screenRef.value?.updateUI();
})

function handlePenDataReceived(data: string, topic: string) {
  screenRef.value?.onRemotePenDataReceived(data, topic);
}

</script>

<template>

  <div class="media-container">
    <div class="media-person-container">
      <LocalParticipantComponent v-show="mediaStore.roomConnected" />
      <MediaParticipantsComponent v-show="mediaStore.roomConnected" />
    </div>
    <MediaScreenSharesComponent v-show="mediaStore.roomConnected" />
  </div>
  <div class="bottom-toolbar">
    <MediaControllersComponent @on-off-local-video="() => { mediaServer.onOffLocalVideo() }"
      @on-off-local-audio="() => { mediaServer.onOffLocalAudio() }"
      @on-off-local-screen="() => { mediaServer.onOffLocalScreen() }"></MediaControllersComponent>
    <div class="divider" v-show="mediaStore.roomConnected"></div>
    <UIShadowButton :icon="Icons.imported.faSmile" @click="() => showEmojiContainer = !showEmojiContainer" />
    <UIShadowButton v-if="UniverseStorage.getUniverse()?.allowGuests && !LoggedUserStorage.getUser()?.getIsGuest()" :icon="Icons.imported.faPersonCircleQuestion" @click="() => guestModal?.open()"></UIShadowButton>
    <ContextMenu :buttons="[
      {
        textKey: 'global.logout',
        action: async () => {
          await userStore.logout();
          await mediaStore.disconnectFromRoom();
          $router.push({ name: routes.login.name })
        }
      }
    ]"></ContextMenu>
  </div>
  <GuestModalComponent ref="guestModal"></GuestModalComponent>
  <EmojiContainerComponent v-show="showEmojiContainer" @close-container="() => { showEmojiContainer = false; }"
    @send-emoji="(emoji: Emoji, time: string) => {
      console.log(emoji, time);
      GameServerClient.sendEmoji({
        emoji: emoji.emoji,
        sentTime: new Date().getTime(),
        temporary: time === EMOJI_TIMER.TEMP,
        active: true
      });
    }" @clear-emoji="() => {
        console.log('clear emoji');
        GameServerClient.clearEmoji();
      }"></EmojiContainerComponent>
    <MediaScreenComponent 
      :screen="(mediaStore.screen as ScreenShareDTO)"
      :mediaServer="(mediaServer as MediaServerClient)"
      ref="screenRef"
      v-if="mediaStore.showScreenFrame"
      />
      <FlipBoardComponent></FlipBoardComponent>
    <ChatUI></ChatUI>
    
    <div class="phaser-container">
      <PhaserGame ref="phaserRef" @current-active-scene="0" />
    </div>
</template>

<style scoped>
.media-container {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 260px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.media-person-container {
  flex-grow: 1;
  overflow: auto;
  padding-right: 4px;
}

.phaser-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.phaser-container canvas {
  margin-top: 0px !important;
  margin-left: 0px !important;
}


.button {
  background-color: #8300C7;
  color: white;
  border-radius: 7px;
  padding: 12px 24px;
  max-width: 170px;
}

.initial-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 15px;
}

.bottom-toolbar {
  position: fixed;
  left: calc(50%);
  transform: translateX(-50%);
  bottom: 16px;
  display: inline-flex;
}

.bottom-toolbar .divider {
  width: 1.5px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 10000px;
  opacity: 0.7;
  margin-right: 8px;
  margin-top: 8px;
}

.bottom-toolbar button,
.bottom-toolbar .context-menu-container {
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px);
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;

  transition: all 100ms ease-out;
}

.bottom-toolbar button.on,
.bottom-toolbar button.screen-sharing.on {
  background: #58af6e;
}

.bottom-toolbar button.on:hover,
.bottom-toolbar button.screen-sharing.on:hover {
  background: #3E9553;
}


.bottom-toolbar button.off {
  background: #b95356;
}

.bottom-toolbar button.off:hover {
  background: #AB4144;
}

.bottom-toolbar button svg {
  color: #FFFFFF;
  width: 20px;
  height: 20px;
}

.bottom-toolbar button.screen-sharing,
.bottom-toolbar .context-menu-container {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
}

.bottom-toolbar button.screen-sharing:hover,
.bottom-toolbar .context-menu-container:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}

.bottom-toolbar button.screen-sharing.on svg {
  fill: #FFF;
}

.bottom-toolbar button.screen-sharing svg {
  width: 24px;
  height: 24px;
  fill: #353535;
}

.bottom-toolbar button svg.fa-video-slash {
  width: 21px;
  height: 21px;
}

.bottom-toolbar button svg.fa-microphone-slash {
  width: 24px;
  height: 24px;
}

.bottom-toolbar .open-context-menu {
  color: #353535;
}

.bottom-toolbar .open-context-menu:hover {
  background: none;
}

.bottom-toolbar .context-menu {
  top: 0px !important;
  bottom: unset !important;
  transform: translateY(-100%) !important;
  transition: all 300ms ease-out;
}
</style>

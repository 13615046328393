import { SerializeUtils } from "@/core/utils/serialize.utils";
import { Universe } from "@/models/universe.model";

class UniverseStorage {
  static setData(universeData) {
    const universeInfo = JSON.stringify(SerializeUtils.serializer.serialize(universeData));
    if (!universeInfo) return;
    window.localStorage.setItem('universeInfo', universeInfo);
  }

  static updateUniverse(universe) {
    const universeInfo = JSON.stringify(universe);
    if (!universeInfo) return;
    window.localStorage.setItem('universeInfo', universeInfo);
  }

  static clearUniverse() {
    window.localStorage.removeItem('universeInfo');
  }

  static getUniverse() {
    const universeStr = JSON.parse(window.localStorage.getItem('universeInfo') ?? 'null');
    const universe = SerializeUtils.serializer.deserializeObject(universeStr, Universe);
    if (!universe) return null;

    return universe;
  }
}

export default UniverseStorage;
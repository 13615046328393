import { JsonObject, JsonProperty } from "typescript-json-serializer";

export enum InteractionFailType {
  TOO_FAR    = "too_far",
}

@JsonObject()
export class InteractionFailed {

  @JsonProperty()
  reason: InteractionFailType;

  @JsonProperty()
  delayToInteractAgainMs: number;

}
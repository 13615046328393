// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gradient[data-v-26084dc2] {
  position: fixed;
  background: linear-gradient(360deg, #7C2DCA 0%, #EF4F83 100%);
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -2;
}
.title-first-part[data-v-26084dc2] {
  color: white;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-break: auto;
  padding: 0px 16px 0px 16px;
}
.title-first-part[data-v-26084dc2] * {
  font-weight: 700;
}
.title-second-part[data-v-26084dc2] {
  color: white;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.input-login[data-v-26084dc2] {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
  gap: 24px;
}
.login-container[data-v-26084dc2] {
  padding-top: 32px;
  gap: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-inside[data-v-26084dc2] {
  background: linear-gradient(360deg, #853EBC 0%, #FF588E 100%);
  border-radius: 48px;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 16px 48px 16px;
  gap: 32px;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.3);
  width: 28%;
  min-width: 400px;
}
.login-main[data-v-26084dc2] {
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
.background-scenario[data-v-26084dc2] {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-left: 5vw;
  padding-right: 5vw;
  z-index: -1;
}
.background-scenario img[data-v-26084dc2] {
  width: 30vw;
  height: auto;
  max-height: 75%;
}
.univ-flex[data-v-26084dc2] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px;
  margin: 8px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 16px;
  background-color: rgba(211, 211, 211, 0.70);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.universe-logo[data-v-26084dc2] {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
}
.universe-logo img[data-v-26084dc2] {
  max-height: 100px;
  max-width: 200px;
  width: auto;
  height: auto;
}
.town-logo[data-v-26084dc2] {
  position: relative;
  padding: 32px;
  max-height: 100px;
}
.town-logo img[data-v-26084dc2] {
  max-height: 100px;
  max-width: 300px;
  width: auto;
  height: auto;
}
.gender-container[data-v-26084dc2] {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-around;
  gap: 8px;
}
.gender-selector[data-v-26084dc2] {
  border: 4px solid lightgrey;
  border-radius: 16px;
  cursor: pointer;
  background-color: #FFFFFF75;
  border-radius: 16px;
  box-sizing: content-box;
}
.gender-selector img[data-v-26084dc2] {
  width: 100px;
  height: 100px;
  padding: 8px;
}
.gender-selector[data-v-26084dc2]:hover {
  border-color: #b1b1b1;
}
.gender-selector[data-v-26084dc2]:active {
  border: 4px solid #EF4F83;
  border-radius: 16px;
}
.selected-border[data-v-26084dc2] {
  border-color: #EF4F83 !important;
  border-width: 4px !important;
}
@media screen and (max-height: 896px) {
.title-first-part[data-v-26084dc2] {
    font-size: 32px;
}
.title-second-part[data-v-26084dc2] {
    font-size: 32px;
}
.login-inside[data-v-26084dc2] {
    gap: 32px;
    min-width: 320px;
}
.input-login[data-v-26084dc2] {
    gap: 16px;
}
}
@media screen and (max-height: 720px) {
.login-container[data-v-26084dc2] {
    gap: 24px;
}
.login-inside[data-v-26084dc2] {
    padding: 32px 16px 32px 16px;
    gap: 16px;
}
.input-login[data-v-26084dc2] {
    gap: 8px;
}
.town-logo img[data-v-26084dc2] {
    max-height: 75px;
    max-width: 150px;
}
.universe-logo img[data-v-26084dc2] {
    max-height: 75px;
    max-width: 100px;
}
}
@media screen and (max-width: 800px) {
.title-first-part[data-v-26084dc2] {
    text-align: center;
}
.gender-selector img[data-v-26084dc2] {
    width: 75px;
    height: 75px;
    padding: 4px;
}
.town-logo img[data-v-26084dc2] {
    max-height: 75px;
    max-width: 150px;
}
.universe-logo img[data-v-26084dc2] {
    max-height: 75px;
    max-width: 100px;
}
}
@media screen and (max-width: 570px) {
.login-inside[data-v-26084dc2] {
    width: 80%;
}
.universe-logo[data-v-26084dc2] {
    padding: 8px;
}
.universe-logo img[data-v-26084dc2] {
    max-height: 50px;
}
.town-logo[data-v-26084dc2] {
    padding: 16px;
}
.town-logo img[data-v-26084dc2] {
    max-height: 50px;
}
.gender-selector img[data-v-26084dc2] {
    width: 50px;
    height: 50px;
    padding: 4px;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { defineComponent as _defineComponent } from 'vue'
import { useLocalizationStore } from '@/store/localization_store';
import { onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocalizedText',
  props: {
    textKey: { type: String, required: true },
    replacer: { type: Function, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const localizationStore = useLocalizationStore();

const text = ref(props.textKey)

onMounted(async () => {
  text.value = await localizationStore.getKey(props.textKey);
  text.value = props.replacer?.(text.value) ?? text.value;
  text.value = text.value.replace(/\*(.*?)\*/g, '<b>$1</b>');
});


const __returned__ = { props, localizationStore, text }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { Vector2 } from "@/domain/gameserver/vector-2.model";

export class EmojiObject extends Phaser.GameObjects.Text{

  private follow: Phaser.GameObjects.Sprite;
  private diffPos: Vector2;
  private background: Phaser.GameObjects.Graphics;

  private emoji?:string;
  private sentTime:number;
  private temporary:boolean;
  private activeEmoji:boolean;
  private TEMP_SHOW_SECONDS:number = 5000; //5 segundos

  private tileSize: Vector2;

  constructor(params: {
    scene: Phaser.Scene;
    x: number;
    y: number;
    emoji?: string;
    sentTime: number;
    temporary:boolean;
    active:boolean;
    follow: Phaser.GameObjects.Sprite;
    tileSize: Vector2;
  }) {
    
    super(params.scene, params.x, params.y, (params.emoji ? params.emoji : ""), {
      align: 'center',
      fontSize: '30px',
      fontFamily: "NotoColorEmoji",
      fixedHeight:38,
    });
    this.tileSize = params.tileSize;
    this.emoji = params.emoji;
    this.sentTime = params.sentTime;
    this.temporary = params.temporary;
    this.activeEmoji = params.active;

    this.setPadding(4,4,4,4);
    this.follow = params.follow;
    this.scene.add.existing(this);
    this.diffPos = new Vector2({
      x: params.x - params.follow?.x,
      y: params.y - params.follow?.y,
    });
    this.setOrigin(0.5);
  }

  private getDepth(x:number, y:number) {
    const DEPTH = 11000;
    const strX = Math.floor(this.x / this.tileSize.x);
    const strY = Math.floor(this.y / this.tileSize.y);
    return DEPTH + Number(`${strX}${strY}`) + 3;
  }

  public update(time: number, delta: number) {
    this.x = this.follow.x + this.diffPos.x;
    this.y = this.follow.y + this.diffPos.y;
    this.setDepth(this.getDepth(this.x, this.y));

    if (this.showEmoji()) {
      if (this.emoji) super.setText(this.emoji);
      this.drawRoundedRect();
    } else {
      super.setText("");
      this.background?.destroy();
    }
  }

  showEmoji ():boolean {
    let show:boolean = false;
    let nowTime= new Date().getTime();
    if (!this.emoji) return false;
    if (this.temporary && 
        this.sentTime > 0 && 
        nowTime - this.sentTime <= this.TEMP_SHOW_SECONDS){
      show = true;
    } else if (!this.temporary && this.activeEmoji) {
      show = true
    } else {
      show = false;
    }
    return show;
  }

  public drawRoundedRect() {

    this.background?.destroy();
    this.background = this.scene.add.graphics();

    // Configuração do balão
    const x = (this.x - this.width / 2)+1;
    const y = (this.y - this.height / 2);
    const width = 43;
    const height = 38;
    const radius = 10;

    // Desenhando o retângulo arredondado
    this.background.fillStyle(0x000000, 0.5); // Cor preta
    this.background.fillRoundedRect(x+1.5, y+1.5, width, height, radius);

    this.background.fillStyle(0xffffff); // Cor branca
    this.background.fillRoundedRect(x, y, width, height, radius);

    // Desenhando a seta
    
    let arrowX = (x + width / 2)+1.5;
    let arrowY = (y + height) +1.5;
    let arrowHeight = 5; // Altura da seta
    let arrowWidth = 15; // Largura da base da seta


    this.background.fillStyle(0x000000, 0.5); 
    this.background.beginPath();
    this.background.moveTo(arrowX - arrowWidth / 2, arrowY);
    this.background.lineTo(arrowX, arrowY + arrowHeight);
    this.background.lineTo(arrowX + arrowWidth / 2, arrowY);
    this.background.closePath();
    this.background.fillPath();

    arrowX = x + width / 2;
    arrowY = y + height;

    this.background.fillStyle(0xffffff); 
    this.background.beginPath();
    this.background.moveTo(arrowX - arrowWidth / 2, arrowY);
    this.background.lineTo(arrowX, arrowY + arrowHeight);
    this.background.lineTo(arrowX + arrowWidth / 2, arrowY);
    this.background.closePath();
    this.background.fillPath();

    
    this.background.setDepth(this.getDepth(this.x, this.y)-1);
  }

  public setEmoji(params:{
    emoji:string,
    sentTime:number,
    temporary:boolean,
    active:boolean
  }) {
    this.emoji = params.emoji;
    this.sentTime = params.sentTime;
    this.temporary = params.temporary;
    this.activeEmoji = params.active;
  }

  public clear() {
    this.emoji = undefined;
    this.sentTime = 0;
    this.temporary = false;
    this.activeEmoji = false;
  }

  destroy(fromScene?: boolean): void {
    this.background?.destroy();
    super.destroy(fromScene);
  }

}
export enum MapLayers {
  // COLLISION
  WALL="wall",
  TABLE="tables",
  FURNITURE="furniture",
  FURNITURE_SUB="furniture_sub",

  // INTERACTION
  MEDIA_COLLIDER="media_collider",
  INTERACTIVE_OBJECTS="interactive_objects",
  TELEPORT_AREA="teleport_area",

  // SPAWN DEPTH
  CHARACTERS="characters",
}
import { defineComponent as _defineComponent } from 'vue'
import { TrackPublication, RemoteTrackPublication } from 'livekit-client';
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import {onMounted, ref} from 'vue';
import {useMediaStore} from "../../store/media_store";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import PenCanvasComponent from '@/components/media/PenCanvasComponent.vue';
import MediaServerClient from '@/infra/opagameserver/MediaServerClient';


export default /*@__PURE__*/_defineComponent({
  __name: 'MediaScreenComponent',
  props: {
  screen: {
    type: ScreenShareDTO,
    required: true,
  },
  mediaServer : {
    type: MediaServerClient,
    required: true
  }
},
  setup(__props, { expose: __expose }) {

const mediaStore = useMediaStore();

const props = __props;

const penRef = ref<InstanceType<typeof PenCanvasComponent>>();

let screenPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;
let audioPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;

const screenVideoElement = ref<HTMLVideoElement>();
const screenAudioElement = ref<HTMLAudioElement>();
const closeElement = ref<HTMLButtonElement>();
const screenContainerElement = ref<HTMLDivElement>();

__expose({
  updateUI() {
    _updateUI();
  },

  onRemotePenDataReceived(data:string, topic:string) {
    penRef.value?.onRemoteReceived(data, topic);
  }
})

const closeScreen = () => {
  //console.log("close screen");
  mediaStore.hideScreenShare();
}

const _updateUI = () => {

  //console.log("update screen ui");
  

  if(screenVideoElement.value) {
    screenPub = props.screen?.screen;
    if (screenPub) {
      //console.log("ui has screen");
      screenPub.videoTrack?.attach(screenVideoElement.value);
    } else {
      //console.log("ui hs not screen");
    }
  }
  if (screenAudioElement.value) {
    audioPub = props.screen?.screenAudio;
    if (audioPub) {
      audioPub.audioTrack?.attach(screenAudioElement.value);
    }
  }

  fixVideoSize();
}

let updatingVideo = false;


const fixVideoSize = () => {
  if (screenVideoElement.value && !updatingVideo) {
    updatingVideo = true;
    const video = screenVideoElement.value;
    // Obtendo as dimensões renderizadas
    const videoRect = video.getBoundingClientRect();
    const larguraVisivel = videoRect.width;
    const alturaVisivel = videoRect.height;

    if(video.videoWidth > 0 && video.videoHeight > 0){

      // Calculando a proporção original
      const proporcaoOriginal = video.videoWidth / video.videoHeight;
      // Determinando qual dimensão está limitando
      const proporcaoVisivel = larguraVisivel / alturaVisivel;
      let larguraFrame, alturaFrame;
      if (proporcaoVisivel > proporcaoOriginal) {
        // A altura está limitando
        alturaFrame = alturaVisivel;
        larguraFrame = alturaFrame * proporcaoOriginal;
      } else {
        // A largura está limitando
        larguraFrame = larguraVisivel;
        alturaFrame = larguraFrame / proporcaoOriginal;
        
      }
        document.getElementById("decorator")?.setAttribute(
          "style", `width:${(larguraFrame+16).toFixed(0)}px;height:${(alturaFrame+16).toFixed(0)}px;`);
        closeElement.value?.setAttribute("style", `right:calc(50% - ${(larguraFrame/2) + 13}px)`);

        penRef.value?.updateCanvas(larguraFrame, alturaFrame);
    }
    updatingVideo = false;
  }
}

window.addEventListener("resize", fixVideoSize, false);

const initUIState = () => {
  screenVideoElement.value?.addEventListener("loadeddata", fixVideoSize, false);
  console.log("init UI screen");
  _updateUI();
}

onMounted(initUIState);


const __returned__ = { mediaStore, props, penRef, get screenPub() { return screenPub }, set screenPub(v) { screenPub = v }, get audioPub() { return audioPub }, set audioPub(v) { audioPub = v }, screenVideoElement, screenAudioElement, closeElement, screenContainerElement, closeScreen, _updateUI, get updatingVideo() { return updatingVideo }, set updatingVideo(v) { updatingVideo = v }, fixVideoSize, initUIState, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons }, PenCanvasComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
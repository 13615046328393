import { defineComponent as _defineComponent } from 'vue'
import { useLocalizationStore } from '@/store/localization_store';
import { onMounted, ref } from 'vue';
import LocalizedText from './LocalizedText.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonDropdown',
  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: String, required: true },
    options: { type: Array, required: true }
  },
  emits: ["change"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;



const localizationStore = useLocalizationStore();
const optionsLocalized = ref(props.options);

onMounted(async () => {
  optionsLocalized.value = await Promise.all(props.options.map(async (option) => ({
    label: await localizationStore.getKey(option.label),
    value: option.value
  })));
});


const __returned__ = { props, localizationStore, optionsLocalized, LocalizedText }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
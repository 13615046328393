import { JsonObject, JsonProperty } from "typescript-json-serializer";

export enum EmojiFailType {
  USER_NOT_FOUND = "userNotFound",
  PLAYER_NOT_FOUND = "playerNotFound",
  PLACE_NOT_FOUND = "placeNotFound",
}

@JsonObject()
export class EmojiFailed {

  @JsonProperty()
  reason: EmojiFailType;
}
import { SerializeUtils } from "@/core/utils/serialize.utils";
import { Town } from "@/models/town.model";

class TownStorage {

  static setData(townData: Town) {
    const townInfo = JSON.stringify(SerializeUtils.serializer.serialize(townData));
    if (!townInfo) return;
    window.localStorage.setItem('townInfo', townInfo);
  }

  static updateTown(town: Town) {
    const townInfo = JSON.stringify(SerializeUtils.serializer.serialize(town));
    if (!townInfo) return;
    window.localStorage.setItem('townInfo', townInfo);
  }

  static clearTown() {
    window.localStorage.removeItem('townInfo');
  }

  static getTown(): Town | null {
    const townStr = JSON.parse(window.localStorage.getItem('townInfo') ?? 'null');
    const town = SerializeUtils.serializer.deserializeObject(townStr,  Town);
    if (!town) return null;

    return town;
  }
}

export default TownStorage;
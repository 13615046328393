import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Vector2 } from "./vector-2.model";
import { MoveDir } from "./move-player.model";
import { EmojiModel } from "./emoji.model";
import { GuestType } from "./guest-type.enum";

@JsonObject()
export class CachePlayer {

  static delayToMoveAgain: number = 150;

  @JsonProperty()
  placeId: string;

  @JsonProperty()
  userId: string;

  @JsonProperty()
  position: Vector2;

  @JsonProperty()
  currentMediaRoomId?: string;

  @JsonProperty()
  userName: string;

  @JsonProperty()
  lastMoveTimestamp: number;

  @JsonProperty()
  lastMoveDir: MoveDir;

  @JsonProperty()
  gender: "male" | "female" = "male";

  @JsonProperty({type: EmojiModel})
  emoji: EmojiModel;

  @JsonProperty()
  isGuest: boolean = false;

  @JsonProperty()
  guestType: GuestType | null;

  constructor(params: {
    userId: string;
    position: Vector2;
    placeId: string;
    userName: string;
    currentMediaRoomId?: string;
    lastMoveTimestamp?: number;
    lastMoveDir?: MoveDir;
    gender?: "male" | "female";
    emoji: EmojiModel;
    isGuest?: boolean;
    guestType?: GuestType;
  }) {
    if (!params) return;
    this.userId = params.userId;
    this.position = params.position;
    this.placeId = params.placeId;
    this.userName = params.userName;
    this.currentMediaRoomId = params.currentMediaRoomId;
    this.lastMoveTimestamp = params.lastMoveTimestamp ?? 0;
    this.lastMoveDir = params.lastMoveDir ?? MoveDir.DOWN;
    this.gender = params.gender ?? "male";
    this.emoji = params.emoji;
    this.isGuest = params.isGuest ?? false;
    this.guestType = params.isGuest ? params.guestType ?? GuestType.UNVALIDATED_GUEST : null;
  }
}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loading-container[data-v-543afa31] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}
.loaded-icon[data-v-543afa31] {
  width: 50px;
  height: 50px;
  color: var(--semantic-color-success-default, #58af6e);
}
.loaded-icon.error[data-v-543afa31] {
  color: var(--semantic-color-error-default, #b95356);
}
.loading-text[data-v-543afa31] {
  color: var(--semantic-color-primary-default, #8300C7);
  text-align: center;

  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.loading-text.loaded[data-v-543afa31] {
  color: var(--semantic-color-success-default, #58af6e);
}
.loading-text.error[data-v-543afa31] {
  color: var(--semantic-color-error-default, #b95356);
}
.loading-subtext[data-v-543afa31] {
  color: var(--semantic-color-fg-muted, #353535);
  text-align: center;

  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.loading-subtext.error[data-v-543afa31] {
  color: var(--semantic-color-error-default, #b95356);
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/genders/female-dark.png'
import _imports_1 from '@/assets/genders/male-dark.png'


const _hoisted_1 = { class: "gradient" }
const _hoisted_2 = { class: "login-inside" }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"column","gap":"8px","width":"100%"} }
const _hoisted_4 = { class: "gender-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["LocalizedText"], {
        style: {"font-size":"32px","font-weight":"bold","color":"#fff","text-transform":"uppercase"},
        "text-key": "guestLogin.title"
      }),
      _createElementVNode("div", _hoisted_3, [
        ($setup.showUniverseInput)
          ? (_openBlock(), _createBlock($setup["CommonInput"], {
              key: 0,
              labelKey: "login.universeDomain",
              modelValue: $setup.universe ?? '',
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => { $setup.universe = v })
            }, null, 8 /* PROPS */, ["modelValue"]))
          : _createCommentVNode("v-if", true),
        ($setup.showTownInput)
          ? (_openBlock(), _createBlock($setup["CommonInput"], {
              key: 1,
              labelKey: "login.townSubdomain",
              modelValue: $setup.town ?? '',
              "onUpdate:modelValue": _cache[1] || (_cache[1] = (v) => { $setup.town = v })
            }, null, 8 /* PROPS */, ["modelValue"]))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["CommonInput"], {
          labelKey: "guestLogin.firstName",
          modelValue: $setup.guestLoginStore.firstName ?? '',
          "onUpdate:modelValue": _cache[2] || (_cache[2] = (v) => $setup.guestLoginStore.firstName = v)
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["CommonInput"], {
          labelKey: "guestLogin.lastName",
          modelValue: $setup.guestLoginStore.lastName ?? '',
          "onUpdate:modelValue": _cache[3] || (_cache[3] = (v) => $setup.guestLoginStore.lastName = v)
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["gender-selector", $setup.guestLoginStore.gender == $setup.UserGender.FEMALE ? 'selected-border' : ''])
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            onClick: _cache[4] || (_cache[4] = () => { $setup.guestLoginStore.gender = $setup.UserGender.FEMALE })
          })
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(["gender-selector", $setup.guestLoginStore.gender == $setup.UserGender.MALE ? 'selected-border' : ''])
        }, [
          _createElementVNode("img", {
            src: _imports_1,
            onClick: _cache[5] || (_cache[5] = () => { $setup.guestLoginStore.gender = $setup.UserGender.MALE })
          })
        ], 2 /* CLASS */)
      ]),
      _createVNode($setup["CommonButton"], {
        "label-key": "guestLogin.enter",
        onClick: $setup.guestLogin
      })
    ])
  ]))
}
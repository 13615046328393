import { createVNode as _createVNode, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-container ui-shadows" }
const _hoisted_2 = ["type", "data-maska", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FontAwesomeIcon"], {
      icon: $setup.Icons.imported.faComment
    }, null, 8 /* PROPS */, ["icon"]),
    _withDirectives(_createElementVNode("input", {
      ref: "myInput",
      type: $props.type ?? 'text',
      "data-maska": $props.mask,
      value: $props.modelValue,
      class: "rounded-input",
      placeholder: $setup.label,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', ($event.target as HTMLInputElement).value))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [
      [$setup["vMaska"]]
    ])
  ]))
}
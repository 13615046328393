import { Vector2 } from "@/domain/gameserver/vector-2.model";
export class DesiredPos {

  private readonly x: number;
  private readonly y: number;
  private readonly delayInMs: number;
  private startMoment: Date | null;
  private initialPos: Vector2 | null = new Vector2({ x: 0, y: 0 });

  constructor(params: {
    x: number,
    y: number,
    delayInMs: number,
  }) {
    this.x = params.x;
    this.y = params.y;
    this.delayInMs = params.delayInMs;
  }

  public getX() {
    return this.x;
  }

  public getY() {
    return this.y;
  }

  public getDelay() {
    return this.delayInMs;
  }

  public setStart(params: {
    startMoment: Date,
    initialPos: Vector2,
  }) {
    this.startMoment = params.startMoment;
    this.initialPos = params.initialPos;
  }

  public hasStart() {
    return Boolean(this.startMoment) && Boolean(this.initialPos);
  }

  public lerp(): {
    t: number,
    pos: Vector2,
  } | null {
    if (!this.hasStart()) return null;
    const currentDate = new Date();
    const elapsedMs = currentDate.getTime() - this.startMoment!.getTime();
    const t = elapsedMs / this.delayInMs;
    return {
      t,
      pos: new Vector2({
        x: Phaser.Math.Linear(this.initialPos!.x, this.x, t),
        y: Phaser.Math.Linear(this.initialPos!.y, this.y, t),
      }),
    };
  }

  public getPosition() {
    return new Vector2({
      x: this.x,
      y: this.y,
    });
  }

}
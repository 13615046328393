<script setup lang="ts">

defineProps<{
  imgUrl?: string,
  userName: string,
  message: string,
  time: Date,
  isItMe: boolean,
}>();

</script>

<template>
  <div class="chat-message ui-shadows">
    <img v-if="imgUrl" :src="imgUrl" />
    <div class="chat-message-text">
      <div class="chat-message-text-name">{{ userName }}</div>
      <div class="chat-message-text-message">{{ message }}</div>
    </div>
    <div class="chat-message-time">{{ time.getHours().toString().padStart(2, '0') }}:{{ time.getMinutes().toString().padStart(2, '0') }}</div>
  </div>
</template>

<style scoped lang="css">
.chat-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  border-radius: 24px;
  /* border: 1px solid rgba(255, 255, 255, 0.70); */
  backdrop-filter: blur(32px);
}

.chat-message-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.chat-message-text-name {
  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: bolder;
}

.chat-message-text-message {
  text-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
  
  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 14px;
  width: 100%;
  min-width: 80px;
  max-width: 220px;
  line-height: normal;
  letter-spacing: 0.5px;
}

.chat-message-time {
  color: #353535;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  align-self: flex-end;
}

</style>
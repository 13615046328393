<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { useLocalizationStore } from '@/store/localization_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref } from 'vue';

const delayToUpdate = 1000 / 60;
type StateData = 'default' | 'error';

const isShowing = ref<boolean>(false);
const text = ref<string | null>(null);
const state = ref<StateData>('default');
const maxTimer = ref<number | null>(null);
const timer = ref<number | null>(null);
const onUndo = ref<(() => void) | null>(null);

const closePromiseResolve = ref<(() => void) | null>(null);

const localizationStore = useLocalizationStore();

defineExpose({
  open: async (params: {
    textKey: string;
    state?: StateData;
    timer?: number;
    onUndo?: () => void;
  }) => {
    text.value = await localizationStore.getKey(params.textKey);
    state.value = params.state ?? 'default';
    timer.value = params.timer ?? 4000;
    maxTimer.value = timer.value;
    onUndo.value = params.onUndo ?? null;
    isShowing.value = true;
    
    setupTimer();

    const closePromise = new Promise<void>((resolve) => {
      closePromiseResolve.value = resolve;
    });
    await closePromise;
  },
  close: closeModal,
});

function setupTimer() {
  const interval = setInterval(() => {
    if ((timer.value ?? 0) <= 0) {
      clearInterval(interval);
      closeModal();
    }
    timer.value = (timer.value ?? 0) - delayToUpdate;
  }, delayToUpdate);
}

function closeModal() {
  isShowing.value = false;
  closePromiseResolve.value?.call(null);
}

function getStyle() {
  if (state.value === 'error') {
    return {
      border: `1px solid var(--semantic-color-error-default, #C63D3D)`,
      background: `var(--semantic-color-error-default, #C63D3D)`,
    };
  }
  return {
    border: `1px solid var(--semantic-color-success-default, #31812A)`,
    background: `var(--semantic-color-success-default, #31812A)`,
  };
}

</script>

<template>
  <div class="common-toast" v-show="isShowing" :key="state" :style="getStyle()">
    <div class="loader" :key="timer ?? 0" :style="{
      width: `calc(${timer && maxTimer ? ((timer / maxTimer) * 100).toFixed(2) : null}% - 4px)`
    }"></div>
    <p class="toast-text">{{ text }}</p>
    <p class="toast-undo" @click="() => {
      onUndo?.call(null);
      closeModal();
    }" v-if="onUndo">Desfazer</p>
    <div class="toast-divider"></div>
    <FontAwesomeIcon class="toast-icon" :icon="Icons.imported.faXmark" @click="closeModal" />
  </div>
</template>

<style scoped>
.common-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  max-width: 400px;
  display: inline-flex;
  padding: var(--semantic-spacing-inset-squishy-medium-vertical, 12px) 12px var(--semantic-spacing-inset-squishy-medium-vertical, 12px) var(--semantic-spacing-inset-squishy-small-horizontal, 16px);
  align-items: center;
  gap: var(--semantic-spacing-inline-400, 32px);
  justify-content: space-between;
  border-radius: var(--semantic-border-radius-default, 7px);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}

.toast-text {
  color: var(--options-color-neutral-white, #FFF);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.toast-undo {
  color: var(--options-color-neutral-white, #FFF);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.toast-icon {
  color: var(--options-color-neutral-white, #FFF);
  cursor: pointer;
}

.toast-divider {
  position: absolute;
  height: calc(100% - 2px);
  width: 2px;
  background: var(--options-color-neutral-white, #FFF);
  opacity: 0.1;
  bottom: 1px;
  right: 36px;
}

.loader {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: var(--semantic-border-radius-default, 7px) var(--semantic-border-radius-default, 7px) 0px 0px;
  height: calc(var(--components-button-spacing-small-gap, 4px) * 1px);
  opacity: 0.5;
  background: var(--options-color-neutral-white, #FFF);
}

</style>

class Constants {

  static readonly stage = window.localStorage.getItem("STAGE") || "local";
  
  static readonly debugMode: boolean = Constants.stage === "local";
  
  static getWsUrl() {
    switch (Constants.stage) {
      case "prod":
        return "wss://ws.opa.town/";
      default:
        return "wss://devws.opa.town/";
        // return "ws://localhost:3001";
    }
  }
  
  static getRestUrl() {
    switch (Constants.stage) {
      case "prod":
        return "https://rest.opa.town";
      default:
        return "https://devrest.opa.town";
        // return "http://localhost:3000";
    }
  }

  static getLiveKitServer() {
    switch (Constants.stage) {
      case "prod":
        return "wss://mediaserver.opa.town/";
      default:
        return "wss://devmediaserver.opa.town/";
    }
  }

  public static readonly universeDomain = "default";
  public static readonly townSubdomain = "default";

}

export default Constants;

import { RouteLocationNormalized } from "vue-router";
import { AuthRequirement, IRoute, routes } from '@/router';
import LoggedUserStorage from "@/infra/local_storage/logged_user.storage";

async function loginGuard(to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<string | null> {
  const toRouteData: IRoute | undefined = Object.values(routes).find(route => route.name === to.name);
  const fromRouteData: IRoute | undefined = Object.values(routes).find(route => route.name === from.name);
  const user = LoggedUserStorage.getUser();

  // LOGIN
  const tokenExpiration = (LoggedUserStorage.getExpiresAt() ?? new Date(0));
  const isLogged = user !== null && tokenExpiration > new Date();
  const noAuthRoutes = Object.values(routes).filter(route => route.authType == AuthRequirement.NOT_LOGGED).map(route => route.name);
  const isGoingToNoAuthRoute = noAuthRoutes.includes(to.name as string);
  if (toRouteData?.authType != AuthRequirement.DONT_CARE) {
    if (!isGoingToNoAuthRoute && !isLogged) return routes.login.path;
    if (isGoingToNoAuthRoute && isLogged) return routes.gameScene.path;
  }

  return null;
}

export default loginGuard;
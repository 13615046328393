import { Action } from "@/domain/Action";

export enum ToggleChatState {
  FOCUSED = 'focused',
  UNFOCUSED = 'unfocused',
}

export class ChatEventBus {
  static onMessageSentEvt: Action<string> = new Action();
  static onFocusChatEvt: Action<ToggleChatState> = new Action();

  static toggleChat(toggleChatState: ToggleChatState) {
    ChatEventBus.onFocusChatEvt.publish(toggleChatState);
  }

  static sendMessage(message: string) {
    ChatEventBus.onMessageSentEvt.publish(message);
  }
}
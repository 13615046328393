<template>
  <div ref="screenContainerElement" class="screen-container" >
    
    <video ref="screenVideoElement" class="video-element" autoplay>
    </video>
    <div id="decorator" class="ui-shadows"></div>
    <button class="close-button ui-shadows" ref="closeElement" @click="closeScreen">
      <FontAwesomeIcon :icon="Icons.imported.faXmark"></FontAwesomeIcon></button>
    <audio ref="screenAudioElement"></audio>
    
  
    <PenCanvasComponent
    ref="penRef"
    :mediaServer="(props.mediaServer as MediaServerClient)"
    ></PenCanvasComponent>
    
  </div>

</template>


<script setup lang="ts">
import { TrackPublication, RemoteTrackPublication } from 'livekit-client';
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import {onMounted, ref} from 'vue';
import {useMediaStore} from "../../store/media_store";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import PenCanvasComponent from '@/components/media/PenCanvasComponent.vue';
import MediaServerClient from '@/infra/opagameserver/MediaServerClient';

const mediaStore = useMediaStore();

const props = defineProps({
  screen: {
    type: ScreenShareDTO,
    required: true,
  },
  mediaServer : {
    type: MediaServerClient,
    required: true
  }
});

const penRef = ref<InstanceType<typeof PenCanvasComponent>>();

let screenPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;
let audioPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;

const screenVideoElement = ref<HTMLVideoElement>();
const screenAudioElement = ref<HTMLAudioElement>();
const closeElement = ref<HTMLButtonElement>();
const screenContainerElement = ref<HTMLDivElement>();

defineExpose({
  updateUI() {
    _updateUI();
  },

  onRemotePenDataReceived(data:string, topic:string) {
    penRef.value?.onRemoteReceived(data, topic);
  }
})

const closeScreen = () => {
  //console.log("close screen");
  mediaStore.hideScreenShare();
}

const _updateUI = () => {

  //console.log("update screen ui");
  

  if(screenVideoElement.value) {
    screenPub = props.screen?.screen;
    if (screenPub) {
      //console.log("ui has screen");
      screenPub.videoTrack?.attach(screenVideoElement.value);
    } else {
      //console.log("ui hs not screen");
    }
  }
  if (screenAudioElement.value) {
    audioPub = props.screen?.screenAudio;
    if (audioPub) {
      audioPub.audioTrack?.attach(screenAudioElement.value);
    }
  }

  fixVideoSize();
}

let updatingVideo = false;


const fixVideoSize = () => {
  if (screenVideoElement.value && !updatingVideo) {
    updatingVideo = true;
    const video = screenVideoElement.value;
    // Obtendo as dimensões renderizadas
    const videoRect = video.getBoundingClientRect();
    const larguraVisivel = videoRect.width;
    const alturaVisivel = videoRect.height;

    if(video.videoWidth > 0 && video.videoHeight > 0){

      // Calculando a proporção original
      const proporcaoOriginal = video.videoWidth / video.videoHeight;
      // Determinando qual dimensão está limitando
      const proporcaoVisivel = larguraVisivel / alturaVisivel;
      let larguraFrame, alturaFrame;
      if (proporcaoVisivel > proporcaoOriginal) {
        // A altura está limitando
        alturaFrame = alturaVisivel;
        larguraFrame = alturaFrame * proporcaoOriginal;
      } else {
        // A largura está limitando
        larguraFrame = larguraVisivel;
        alturaFrame = larguraFrame / proporcaoOriginal;
        
      }
        document.getElementById("decorator")?.setAttribute(
          "style", `width:${(larguraFrame+16).toFixed(0)}px;height:${(alturaFrame+16).toFixed(0)}px;`);
        closeElement.value?.setAttribute("style", `right:calc(50% - ${(larguraFrame/2) + 13}px)`);

        penRef.value?.updateCanvas(larguraFrame, alturaFrame);
    }
    updatingVideo = false;
  }
}

window.addEventListener("resize", fixVideoSize, false);

const initUIState = () => {
  screenVideoElement.value?.addEventListener("loadeddata", fixVideoSize, false);
  console.log("init UI screen");
  _updateUI();
}

onMounted(initUIState);

</script>

<style scoped>

.screen-container{
  width:calc(100% - 308px);
  max-width:calc(100% - 300px);
  max-height: calc(100% - 130px);
  top:8px;
  left: calc(50% + 300px);
  transform:translate(calc(-50% - 158px),0);
  position:fixed;
  /* background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px); */
  display: flex;
  justify-content: center;
  /* border-radius: 24px; */
  padding: 8px;
  z-index:200;
}

#decorator{
  top: 0px;
  position:fixed;
  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px);
  z-index:3999;
}

.video-element{
  width: 100%;
  z-index:4000;
}

.close-button {
  position: fixed;
  top: -8px;
  right: -8px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 26px;
  height: 26px;
  z-index:4001;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.9)
}
</style>
import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '../views/Login.vue';
import GameScene from '../views/GameScene.vue';
import loginGuard from '@/core/nav_guards/login_guard';
import GuestLogin from '@/views/GuestLogin.vue';

export enum AuthRequirement {
  LOGGED = 'logged',
  NOT_LOGGED = 'not-logged',
  DONT_CARE = 'both',
}

export interface IRoute {
  path: string;
  name: string;
  component: any;
  authType?: AuthRequirement;
}

export const routes: { [key: string]: IRoute } = {
  login: {
    path: '/',
    name: 'login',
    component: () => Login,
    authType: AuthRequirement.NOT_LOGGED,
  },
  gameScene: {
    path: '/gameScene',
    name: 'GameScene',
    component: () => GameScene,
    authType: AuthRequirement.LOGGED,
  },
  guestLogin: {
    path: '/guestLogin',
    name: 'guestLogin',
    component: () => GuestLogin,
    authType: AuthRequirement.NOT_LOGGED,
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: Object.values(routes),
});

router.beforeEach(async (to, from, next) => {
  const route = await loginGuard(to, from);
  if (!route) return next();
  return next({ path: route });
});

export default router;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/flipboard/pen.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/flipboard/eraser.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.flipboard-container[data-v-5a211982] {
  width: calc(100% - 308px);
  max-width: calc(100% - 308px);
  height: calc(100% - 130px);
  max-height: calc(100% - 130px);
  top: 8px;
  left: calc(50% + 300px);
  transform: translate(calc(-50% - 158px), 0);
  position: fixed;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  display: flex;
  justify-content: center;
  padding: 20px;
  z-index: 200;

  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px);
}
.flipboard-tools[data-v-5a211982] {
  width: 54px;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 9px 0 0 9px;
  border: 1px solid #a9a6a6;
  align-items: start;
  display: table-column;
  justify-content: center;
  padding-left: 7px;
  overflow: hidden;
}
.flipboard-tool[data-v-5a211982] {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
}
.innactive[data-v-5a211982]:hover {
  background-color: #ffffff;
}
.active[data-v-5a211982] {
  box-shadow: rgb(0, 0, 0) 2px 1px 0px 0px inset;
  background-color: #58af6e;
}
.active svg path[data-v-5a211982] {
  fill: #ffffff;
}
.flipboard-draw-area[data-v-5a211982] {
  width: calc(100% - 54px);
  padding: 16px;
  background-color: #c0c0c0;
  position: relative;
  height: auto;
  border-radius: 0 9px 9px 0;
  border: 1px solid #a9a6a6;
}
.flipboard-paper[data-v-5a211982] {
  max-height: calc(100% - 8px);
  aspect-ratio: 16 / 9;
  background-color: #ffffff;
  margin: 0 auto;
}
.flipboard-canvas-element[data-v-5a211982] {
  width: 100%;
  height: 100%;
  z-index: 4000;
}
.flipboard-canvas-preview-element[data-v-5a211982] {
  width: 100%;
  height: 100%;
  z-index: 4001;
  position: absolute;
  cursor:crosshair;
}
#decorator[data-v-5a211982] {
  top: 0px;
  position: fixed;
  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  z-index: 3999;
}
.close-button[data-v-5a211982] {
  position: fixed;
  top: -8px;
  right: -8px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 26px;
  height: 26px;
  z-index: 4001;
}
.close-button[data-v-5a211982]:hover {
  background-color: rgba(255, 255, 255, 0.9)
}
.pen-active[data-v-5a211982] {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 38, auto;
}
.erase-active[data-v-5a211982] {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) 14 14, auto;
}
.color-picker[data-v-5a211982] {
  position: absolute;
  bottom: 28px;
  width: 38px;
  height: 38px;
  border-radius: 8px;
}
.color-picker input[data-v-5a211982] {
  width: 35px;
  border: 0px;
  height: 35px;
  border-radius: 12px;
  margin: 2px;
}
.st0[data-v-5a211982] {
  fill: #231f20;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

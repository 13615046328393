import { defineComponent as _defineComponent } from 'vue'
import MediaScreenShortComponent from "./MediaScreenShortComponent.vue";
import { ScreenShareDTO } from "@/dto/ScreenShareDTO";
import {useMediaStore} from "../../store/media_store";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MediaScreenSharesComponent',
  setup(__props, { expose: __expose }) {
  __expose();

const mediaStore = useMediaStore();

const screenRef=ref<InstanceType<typeof MediaScreenShortComponent>[]>();

mediaStore.$subscribe((mutation, state) => {
  let target = mutation.events["target"];
  if ( target && target instanceof ScreenShareDTO) {
    screenRef.value?.forEach(s => {
      if (s.screen.screenSID === target.screenSID) {
        //update screen shares UI
        s.updateUI();
      }
    })
  }
})


const __returned__ = { mediaStore, screenRef, MediaScreenShortComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
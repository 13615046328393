<template>
  <div id="media_local_participant_container">
    <ParticipantComponent v-if="mediaStore.localParticipant" 
      :participant="mediaStore.localParticipant"
      ref="participant"
    />
  </div>
</template>

<script lang="ts" setup>
import ParticipantComponent from "./ParticipantComponent.vue";
import {useMediaStore} from "../../store/media_store";
import {LocalParticipant } from 'livekit-client';

import {ref} from "vue";

const mediaStore = useMediaStore();

const participant=ref<InstanceType<typeof ParticipantComponent>>();

mediaStore.$subscribe((mutation, state) => {
  //update participant UI
  let target = mutation.events["target"];
  if ( target && target instanceof LocalParticipant) {
    participant.value?.updateUI();
  }
})


</script>

<style scoped>
#media_local_participant_container {
  margin:8px 0 8px 8px;
}
</style>
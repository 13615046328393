import { defineComponent as _defineComponent } from 'vue'
import { Icons } from '@/font-awesome';
import { ModalEventBus } from '@/infra/event_bus/ModalEventBus';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { onMounted, onUnmounted, ref } from 'vue';

import { v4 as uuidv4, v4 } from 'uuid';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonModal',
  props: {
  canClose: {
    type: Boolean,
    default: true,
  },
  isTop:{
    type: Boolean,
    default: false,
  },
  canMinimize: {
    type: Boolean,
    default: false,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
  teleport: { 
    type: Boolean,
    default: true,
  }
},
  emits: ["on-close-by-button", "on-minimize-by-button"],
  setup(__props, { expose: __expose }) {

const props = __props;



const name = ref<string>();
const blackout = ref<HTMLDivElement>();
const commonModal = ref<HTMLDivElement>();
const zIndexRef = ref<number>(2);

const promise = ref<Promise<any>>();
const resolve = ref<(value: any) => void>();
const subscribed = ref<boolean>(false);

const minimizedId = ref<string | null>(null);

__expose({
  open: open,
  close: closeButton,
  minimize: minimizeButton,
  scrollTo: (params: ScrollToOptions) => {
    commonModal.value?.scrollTo(params);
  },
  getModalElement: () => commonModal.value,
});

async function open<T>(params: {
  name?: string;
  zIndex?: number;
} = {}): Promise<T> {
  if (blackout.value) blackout.value?.classList.add("open");
  ModalEventBus.openModal(name.value!);
  zIndexRef.value = params?.zIndex ?? 2;
  promise.value = new Promise<T>((res) => resolve.value = res);
  subscribed.value = true;
  return await promise.value;
}

function maximize() {
  if (!minimizedId.value) return;
  if (blackout.value) blackout.value?.classList.add("open");
  subscribed.value = true;
  minimizedId.value = null;
}

function minimizeButton() {
  if (!props.canMinimize) return;
  if (blackout.value) blackout.value?.classList.remove("open");
  subscribed.value = false;
}

function closeButton<T>(value?: T) {
  if (blackout.value) blackout.value?.classList.remove("open");
  subscribed.value = false;
  
  resolve.value?.call(null, value);
  ModalEventBus.closeModal(name.value!);
}

onMounted(() => {
  name.value = `MODAL-${v4()}`;
});

onUnmounted(() => {
  closeButton();
});


const __returned__ = { props, name, blackout, commonModal, zIndexRef, promise, resolve, subscribed, minimizedId, open, maximize, minimizeButton, closeButton, get Icons() { return Icons }, get FontAwesomeIcon() { return FontAwesomeIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
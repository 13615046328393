<template>
  <div class="pen-tools-container">
    <div class="pen-tools  ui-shadows">
      <div class="color-picker">
        <input type="color" v-model="selectedColor" @input="updateColor" />
      </div>
      <button @click="updatePen" :class="{ active: penActive }"><FontAwesomeIcon :icon="Icons.imported.faPen"></FontAwesomeIcon></button>
      <button @click="updateErase" :class="{ active: eraseActive }"><FontAwesomeIcon :icon="Icons.imported.faEraser"></FontAwesomeIcon></button>
      <button @click="$emit('cleanBoard')"><FontAwesomeIcon :icon="Icons.imported.faTrash"></FontAwesomeIcon></button>
      </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { defineEmits } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';

const emit = defineEmits([
  'update:penActive',
  'update:eraseActive',
  'update:selectedColor',
  'cleanBoard'
]);

const selectedColor = ref('#000000');
const penActive = ref(false);
const eraseActive = ref(false);

// Emitindo eventos para o componente pai

const updatePen = () => {
  penActive.value = !penActive.value
  emit('update:penActive', penActive.value);
  if (penActive.value) {
    eraseActive.value = false;
    emit('update:eraseActive', eraseActive.value);
  }
};

const updateErase = () => {
  eraseActive.value = !eraseActive.value
  emit('update:eraseActive', eraseActive.value);
  if (eraseActive.value) {
    penActive.value = false;
    emit('update:penActive', penActive.value);
  }
};

const updateColor = () => {
  emit('update:selectedColor', selectedColor.value);
};

</script>

<style scoped>
.pen-tools-container {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.pen-tools {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.color-picker {
  width: 30px;
  height: 30px;
  margin:2px;
}

.color-picker input{
  width: 30px;
  height: 30px;
}

button {
  width:30px;
  height:30px;
  margin:2px;
  border-radius:4px;
}

.active {
  background-color:#58af6e;
}
</style>
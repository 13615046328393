import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Vector2 } from "./vector-2.model";

@JsonObject()
export class MovePlayerRes {

  @JsonProperty()
  placeId: string;

  @JsonProperty()
  userId: string;

  @JsonProperty({ type: Vector2 })
  position: Vector2;

  @JsonProperty()
  angle: number;

  @JsonProperty()
  clientTimestamp: number;

  @JsonProperty()
  serverTimestamp: number;

  @JsonProperty()
  movingDelay: number;

  constructor(params: {
    userId: string;
    position: Vector2;
    angle: number;
    placeId: string;
    clientTimestamp: number;
    serverTimestamp: number;
    movingDelay: number;
  }) {
    if (!params) return;
    this.userId = params.userId;
    this.position = params.position;
    this.angle = params.angle;
    this.placeId = params.placeId;
    this.clientTimestamp = params.clientTimestamp;
    this.serverTimestamp = params.serverTimestamp;
    this.movingDelay = params.movingDelay;
  }
}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-container[data-v-4ccce1c2] {
  width: 100%;
}
.rounded-input[data-v-4ccce1c2] {
  padding: 10px 16px;
  border-radius: 16px;
  border: 2px solid lightgrey;
  width: 100%;
  background-color: transparent;
  color: white;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}
.rounded-input[data-v-4ccce1c2]:focus {
  outline: none;
  border: 2px solid white;
}
.rounded-input[data-v-4ccce1c2]:hover {
  border: 2px solid #b1b1b1;
}
.rounded-input[data-v-4ccce1c2]::-moz-placeholder {
  color: white;
}
.rounded-input[data-v-4ccce1c2]::placeholder {
  color: white;
}
@media screen and (max-height: 670px) {
.rounded-input[data-v-4ccce1c2] {
    font-size: 14px;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

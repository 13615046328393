// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.screen-container[data-v-7277d8e8]{
  width:calc(100% - 308px);
  max-width:calc(100% - 300px);
  max-height: calc(100% - 130px);
  top:8px;
  left: calc(50% + 300px);
  transform:translate(calc(-50% - 158px),0);
  position:fixed;
  /* background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  backdrop-filter: blur(32px); */
  display: flex;
  justify-content: center;
  /* border-radius: 24px; */
  padding: 8px;
  z-index:200;
}
#decorator[data-v-7277d8e8]{
  top: 0px;
  position:fixed;
  border-radius: 18px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  z-index:3999;
}
.video-element[data-v-7277d8e8]{
  width: 100%;
  z-index:4000;
}
.close-button[data-v-7277d8e8] {
  position: fixed;
  top: -8px;
  right: -8px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 26px;
  height: 26px;
  z-index:4001;
}
.close-button[data-v-7277d8e8]:hover {
  background-color: rgba(255, 255, 255, 0.9)
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

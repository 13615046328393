import { defineComponent as _defineComponent } from 'vue'
import { TrackPublication, RemoteTrackPublication } from 'livekit-client';
import { ScreenShareDTO } from '@/dto/ScreenShareDTO';
import {useMediaStore} from "../../store/media_store";
import {onMounted, ref} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';


export default /*@__PURE__*/_defineComponent({
  __name: 'MediaScreenShortComponent',
  props: {
  screen: {
    type: ScreenShareDTO,
    required: true,
  }
},
  setup(__props, { expose: __expose }) {

const mediaStore = useMediaStore();

const props = __props;

let screenPub:TrackPublication | RemoteTrackPublication |  undefined = undefined;

const screenVideoElement = ref<HTMLVideoElement>();
const screenPinElement = ref<HTMLButtonElement>();

__expose({
  updateUI() {
    _updateUI();
  }
})

const pinScreen = () => {
  mediaStore.updateBigScreenShare(props.screen);
}

const _updateUI = () => {
  if(screenVideoElement.value) {
    screenPub = props.screen.screen;
    if (screenPub) {
      screenPub.videoTrack?.attach(screenVideoElement.value);
    }
  }
}

const initUIState = () => {
  console.log("init UI screen");
  _updateUI();
}

onMounted(initUIState);


const __returned__ = { mediaStore, props, get screenPub() { return screenPub }, set screenPub(v) { screenPub = v }, screenVideoElement, screenPinElement, pinScreen, _updateUI, initUIState, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
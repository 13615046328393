<script setup lang="ts">
import { defineEmits, onMounted, ref } from 'vue';
import { vMaska } from "maska"
import { useLocalizationStore } from '@/store/localization_store';

const props = defineProps<{
  modelValue: string,
  labelKey: string,
  mask?: string;
  type?: string;
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'focus', value: FocusEvent): void,
  (e: 'blur', value: FocusEvent): void,
}>();

const label = ref(props.labelKey);

const localizationStore = useLocalizationStore();

onMounted(async () => {
  label.value = await localizationStore.getKey(props.labelKey);
});

</script>

<template>
  <div class="input-container">
    <input
      :type="type ?? 'text'"
      v-maska
      :data-maska="mask"
      :value="modelValue"
      class="rounded-input"
      :placeholder="label"
      @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<style scoped>
.input-container {
  width: 100%;
}

.rounded-input {
  padding: 10px 16px;
  border-radius: 16px;
  border: 2px solid lightgrey;
  width: 100%;
  background-color: transparent;
  color: white;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}

.rounded-input:focus {
  outline: none;
  border: 2px solid white;
}

.rounded-input:hover {
  border: 2px solid #b1b1b1;
}

.rounded-input::placeholder {
  color: white;
}

@media screen and (max-height: 670px) {
  .rounded-input {
    font-size: 14px;
  }
}

</style>

import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "participant-container-video-enabled ui-shadows" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  ref: "participantName",
  class: "participant-name-container-video-enabled"
}
const _hoisted_4 = { class: "participant-name" }
const _hoisted_5 = {
  ref: "participantSignalElement",
  class: "participant-signal fa-solid fa-signal"
}
const _hoisted_6 = {
  ref: "participantMicContainer2",
  class: "participant-mic-container div-off"
}
const _hoisted_7 = {
  ref: "participantMicElement2",
  class: "participant-mic fas fa-microphone-slash"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "profile-container" }
const _hoisted_10 = {
  class: "profile-pic not-speaking",
  ref: "participantProfilePic"
}
const _hoisted_11 = {
  ref: "participantMicContainer",
  class: "participant-mic-container div-off"
}
const _hoisted_12 = {
  ref: "participantMicElement",
  class: "participant-mic fas fa-microphone-slash"
}
const _hoisted_13 = ["id"]
const _hoisted_14 = {
  ref: "participantName",
  class: "participant-name-container"
}
const _hoisted_15 = { class: "participant-name" }
const _hoisted_16 = {
  ref: "participantSignalElement",
  class: "participant-signal fa-solid fa-signal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("video", {
        id: `video-${$props.participant!.identity}`,
        ref: "videoElement",
        class: "video-element"
      }, null, 8 /* PROPS */, _hoisted_2), [
        [_vShow, $setup.cameraEnabledState]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.participant!.name), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, null, 512 /* NEED_PATCH */)
      ], 512 /* NEED_PATCH */),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, null, 512 /* NEED_PATCH */)
      ], 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.cameraEnabledState]
    ]),
    _withDirectives(_createElementVNode("div", {
      id: `participant-${$props.participant!.identity}`,
      class: "participant-container ui-shadows",
      ref: "participantContainerElement"
    }, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createTextVNode(_toDisplayString($props.participant!.name?.substring(0,1)) + " ", 1 /* TEXT */),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, null, 512 /* NEED_PATCH */)
          ], 512 /* NEED_PATCH */)
        ], 512 /* NEED_PATCH */)
      ]),
      _createElementVNode("audio", {
        id: `audio-${$props.participant!.identity}`,
        ref: "audioElement"
      }, null, 8 /* PROPS */, _hoisted_13),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString($props.participant!.name), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_16, null, 512 /* NEED_PATCH */)
      ], 512 /* NEED_PATCH */)
    ], 8 /* PROPS */, _hoisted_8), [
      [_vShow, !$setup.cameraEnabledState]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "icon-button ui-shadows",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    ($props.icon)
      ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
          key: 0,
          icon: $props.icon
        }, null, 8 /* PROPS */, ["icon"]))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}
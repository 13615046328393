<template>
  <div id="media_remote_screens_container">
    <MediaScreenShortComponent
      v-for="[sid, screen] in mediaStore.screens" 
      :key="sid" ref="screenRef"
      :screen="screen"
     />
  </div>
</template>

<script setup lang="ts">
import MediaScreenShortComponent from "./MediaScreenShortComponent.vue";
import { ScreenShareDTO } from "@/dto/ScreenShareDTO";
import {useMediaStore} from "../../store/media_store";
import {ref} from "vue";

const mediaStore = useMediaStore();

const screenRef=ref<InstanceType<typeof MediaScreenShortComponent>[]>();

mediaStore.$subscribe((mutation, state) => {
  let target = mutation.events["target"];
  if ( target && target instanceof ScreenShareDTO) {
    screenRef.value?.forEach(s => {
      if (s.screen.screenSID === target.screenSID) {
        //update screen shares UI
        s.updateUI();
      }
    })
  }
})

</script>

<style scoped>
#media_remote_screens_container {
  max-height: 200px;
  width: 260px;
  align-self: baseline;
}
</style>
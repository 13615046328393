import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "media_remote_screens_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.mediaStore.screens, ([sid, screen]) => {
      return (_openBlock(), _createBlock($setup["MediaScreenShortComponent"], {
        key: sid,
        ref_for: true,
        ref: "screenRef",
        screen: screen
      }, null, 8 /* PROPS */, ["screen"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref } from 'vue';
import { LottieAnimation } from "lottie-web-vue";
import LoadingLottieJSON from "@/assets/lotties/loading.json";
import LocalizedText from './LocalizedText.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderAnimation',
  emits: ["endAnimation"],
  setup(__props, { expose: __expose, emit: __emit }) {

const isLoading = ref(true);

const loadingText = ref<string | null>(null);
const loadingSubtext = ref<string | null>(null);

const loadedText = ref<string | null>(null);
const loadedSubtext = ref<string | null>(null);

const errorText = ref<string | null>(null);
const errorSubtext = ref<string | null>(null);
const timeout = ref<NodeJS.Timeout | null>(null);

const isError = ref(false);

__expose({
  startLoading: (params?: {
    loading?: {
      text?: string;
      subtext?: string;
    },
    loaded?: {
      text?: string;
      subtext?: string;
    },
  }) => {
    clearTimeout(timeout.value ?? undefined);
    timeout.value = null;
    isError.value = false;
    isLoading.value = true;
    loadingText.value = params?.loading?.text ?? null;
    loadingSubtext.value = params?.loading?.subtext ?? null;
    loadedText.value = params?.loaded?.text ?? null;
    loadedSubtext.value = params?.loaded?.subtext ?? null;
  },
  endLoading: async (params?: {
    delayAfterLoaded?: number;
    isError?: boolean;
    error?: {
      text?: string;
      subtext?: string;
    },
  }): Promise<boolean> => {
    let res: ((v: boolean) => void) | null = null;
    const promise = new Promise<boolean>((resolve) => res = resolve);
    errorText.value = params?.error?.text ?? null;
    errorSubtext.value = params?.error?.subtext ?? null;
    isLoading.value = false;
    isError.value = params?.isError ?? false;
    timeout.value = setTimeout(() => {
      loadingText.value = null;
      loadingSubtext.value = null;
      loadedText.value = null;
      loadedSubtext.value = null;
      emits('endAnimation', !isError.value);
      res?.call(null, !isError.value);
      isError.value = false;
    }, params?.delayAfterLoaded ?? 2000);
    return promise;
  }
});

const emits = __emit;


const __returned__ = { isLoading, loadingText, loadingSubtext, loadedText, loadedSubtext, errorText, errorSubtext, timeout, isError, emits, get Icons() { return Icons }, get FontAwesomeIcon() { return FontAwesomeIcon }, get LottieAnimation() { return LottieAnimation }, get LoadingLottieJSON() { return LoadingLottieJSON }, LocalizedText }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
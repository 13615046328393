import { Action } from "@/domain/Action";
import { RoomScene } from "../scenes/RoomScene";
import { MoveDir } from "@/domain/gameserver/move-player.model";
import { ChatEventBus, ToggleChatState } from "../../infra/event_bus/ChatEventBus";

class GameInput {

  static readonly keyDelay = 10;

  static onMoveEvt: Action<MoveDir, boolean> = new Action();
  static onZoomEvt: Action<number, number, number> = new Action();
  static onPointerEvt: Action<boolean, number, number> = new Action();

  scene: RoomScene;
  cursors: Phaser.Types.Input.Keyboard.CursorKeys;

  counter = 0;
  isChatFocused = false;
  shiftKey: Phaser.Input.Keyboard.Key;

  constructor(scene: RoomScene) {
    this.scene = scene;
    this.cursors = this.scene!.input!.keyboard!.createCursorKeys();
    this.shiftKey = this.scene!.input!.keyboard!.addKey(Phaser.Input.Keyboard.KeyCodes.SHIFT);

    ChatEventBus.onFocusChatEvt.subscribe(this.onToggleChat.name, this.onToggleChat.bind(this));
    this.isChatFocused = false;

    this.scene.input.on("wheel", this.zoomWheel.bind(this));
    this.scene.input.on("pointerdown", this.onPointer.bind(this));
    this.scene.input.on("pointerup", this.onPointer.bind(this));
  }


  update(time: number, delta: number) {
    if (!this.scene || !this) return;
    this.counter += delta;
    if (this.counter < GameInput.keyDelay) return;
    this.counter = 0;
    
    const isShiftPressed = this.shiftKey.isDown;

    if (this.isChatFocused) return;
    if (this.cursors.left.isDown) GameInput.onMoveEvt.publish(MoveDir.LEFT, isShiftPressed);
    if (this.cursors.right.isDown) GameInput.onMoveEvt.publish(MoveDir.RIGHT, isShiftPressed);
    if (this.cursors.up.isDown) GameInput.onMoveEvt.publish(MoveDir.UP, isShiftPressed);
    if (this.cursors.down.isDown) GameInput.onMoveEvt.publish(MoveDir.DOWN, isShiftPressed);
  }

  public destroy() {  
    this.scene.input.off("wheel", this.zoomWheel.bind(this));
    this.scene.input.off("pointerdown", this.onPointer.bind(this));
    this.scene.input.off("pointerup", this.onPointer.bind(this));

    ChatEventBus.onFocusChatEvt.unsubscribe(this.onToggleChat.name);
  }

  private zoomWheel(_: Phaser.Input.Pointer, deltaX: number, deltaY: number, deltaZ: number)  {
    GameInput.onZoomEvt?.publish(deltaX, deltaY, deltaZ);
  }

  private onPointer(pointer: Phaser.Input.Pointer) {
    GameInput.onPointerEvt.publish(pointer.isDown, pointer.x, pointer.y);
  }

  private onToggleChat(toggleChatState: ToggleChatState) {
    if (toggleChatState === ToggleChatState.FOCUSED) {
      this.scene!.input!.keyboard!.disableGlobalCapture()
      this.isChatFocused = true;
    } else {
      this.scene!.input!.keyboard!.enableGlobalCapture();
      this.isChatFocused = false;
    }
  }

}

export default GameInput;
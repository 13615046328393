import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ChatMessageUI',
  props: {
    imgUrl: { type: String, required: false },
    userName: { type: String, required: true },
    message: { type: String, required: true },
    time: { type: Date, required: true },
    isItMe: { type: Boolean, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();





const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { User } from "./user.model";

@JsonObject()
export class LoginResponse {

  @JsonProperty()
  public token: string;

  @JsonProperty()
  public expiresAt: string;

  @JsonProperty({ type: User })
  public user: User;

  constructor(params?: {
    token: string;
    expiresAt: string;
    user: User;
  }) {
    if (!params) return;
    this.token = params.token;
    this.expiresAt = params.expiresAt;
    this.user = params.user;
  }
}
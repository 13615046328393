import { defineComponent as _defineComponent } from 'vue'
import { RouterView } from 'vue-router';
import LoadingModal from './components/modals/LoadingModal.vue';
import AlertModal from './components/modals/AlertModal.vue';
import { provide, ref } from 'vue';
import { AlertModalInjection, CommonToastInjection, InteractionIframeInjection, LoadingModalInjection } from './core/constants/injections';
import CommonToast from './components/CommonToast.vue';
import InteractionIframe from './components/modals/InteractionIframe.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const alertModal = ref<InstanceType<typeof AlertModal>>();
const loadingModal = ref<InstanceType<typeof LoadingModal>>();
const commonToast = ref<InstanceType<typeof CommonToast>>();
const interactionIframe = ref<InstanceType<typeof InteractionIframe>>();

provide(AlertModalInjection, alertModal);
provide(LoadingModalInjection, loadingModal);
provide(CommonToastInjection, commonToast);
provide(InteractionIframeInjection, interactionIframe);


const __returned__ = { alertModal, loadingModal, commonToast, interactionIframe, get RouterView() { return RouterView }, LoadingModal, AlertModal, CommonToast, InteractionIframe }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
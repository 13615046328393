import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import LoaderAnimation from '../LoaderAnimation.vue';
import { ValueResult } from '@/models/value-result.model';
import CommonModal from './CommonModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingModal',
  setup(__props, { expose: __expose }) {

const loadingV2Modal = ref<InstanceType<typeof CommonModal>>();
const loaderAnimation = ref<InstanceType<typeof LoaderAnimation>>();

__expose({
  open: async (params: {
    promise: Promise<ValueResult<any>>;
    zIndex?: number;
    loading?: {
      text?: string;
      subtext?: string;
    };
    loaded?: {
      text?: string;
      subtext?: string;
    };
    delayAfterLoaded?: number;
  }): Promise<void> => {
    const delayAfterLoaded = params.delayAfterLoaded ?? 2000;
    const openPromise = loadingV2Modal.value?.open({
      zIndex: params?.zIndex ?? 10000,
    });
    loaderAnimation.value?.startLoading(params);
    const valueResult = await params.promise;
    if (valueResult?.isError()) {
      await loaderAnimation.value?.endLoading({
        delayAfterLoaded: delayAfterLoaded,
        isError: true,
        error: {
          text: "Erro",
          subtext: valueResult?.getError() ?? "Erro desconhecido ao realizar conexão",
        }
      });
      return;
    }
    await loaderAnimation.value?.endLoading({
      delayAfterLoaded: delayAfterLoaded,
    });
    await openPromise;
  },
  closeImmediately,
});

function closeImmediately() {
  loadingV2Modal.value?.close();
}


const __returned__ = { loadingV2Modal, loaderAnimation, closeImmediately, LoaderAnimation, CommonModal }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
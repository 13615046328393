import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "screenVideoElement",
  class: "video-element"
}
const _hoisted_2 = { class: "name-element" }
const _hoisted_3 = {
  class: "pin-button",
  ref: "screenPinElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "screen-container",
    onClick: $setup.pinScreen
  }, [
    _createElementVNode("video", _hoisted_1, null, 512 /* NEED_PATCH */),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.screen.screenName), 1 /* TEXT */),
    _createElementVNode("button", _hoisted_3, [
      _createVNode($setup["FontAwesomeIcon"], {
        icon: $setup.Icons.imported.faMapPin
      }, null, 8 /* PROPS */, ["icon"])
    ], 512 /* NEED_PATCH */)
  ]))
}
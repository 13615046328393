import {  JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class User {
  
  @JsonProperty()
  private universeId: string;

  @JsonProperty()
  private townId: string;

  @JsonProperty()
  private id?: string;

  @JsonProperty()
  private externalId: string;

  @JsonProperty()
  private firstName: string;

  @JsonProperty()
  private lastName: string;

  @JsonProperty({ type: Date })
  private createdAtUtc: Date | null;

  @JsonProperty()
  private isGuest: boolean;

  constructor(params?: {
    id?: string,
    externalId: string,
    firstName: string,
    townId: string,
    lastName: string,
    universeId: string,
    createdAtUtc: Date | null,
    isGuest?: boolean;
  }) {
    if (!params) return;
    this.id = params.id;
    this.externalId = params.externalId;
    this.firstName = params.firstName ?? null;
    this.universeId = params.universeId ?? null;
    this.lastName = params.lastName;
    this.townId = params.townId ?? null;
    this.createdAtUtc = params.createdAtUtc ?? null;
    this.isGuest = params.isGuest ?? false; 
  }

  public getId() {
    return this.id;
  }

  public getExternalId() {
    return this.externalId;
  }

  public getFirstName() {
    return this.firstName;
  }

  public getUniverseId() {
    return this.universeId;
  }

  public getLastName() {
    return this.lastName;
  }

  public getTownId(){
    return this.townId;
  }

  public getLimitDate() {
    return this.createdAtUtc;
  }

  public getIsGuest() {
    return this.isGuest;
  }

}
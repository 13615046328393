import { Action } from "@/domain/Action";

export class ModalEventBus {

  static readonly openModals: {
    [key: string]: boolean
  } = {};

  static readonly onOpenModalEvt = new Action<string>();
  static readonly onCloseModalEvt = new Action<string>();

  static hasOpenModals() {
    return Object.values(ModalEventBus.openModals).some((open) => open);
  }

  static openModal(key: string) {
    ModalEventBus.openModals[key] = true;
    ModalEventBus.onOpenModalEvt.publish(key);
    this.updateOverflow();
  }

  static closeModal(key: string) {
    ModalEventBus.openModals[key] = false;
    ModalEventBus.onCloseModalEvt.publish(key);
    this.updateOverflow();
  }

  private static updateOverflow() {
    document.body.style.overflow = this.hasOpenModals() ? 'hidden' : 'auto';
  }

}
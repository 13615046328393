import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import CommonModal from '../modals/CommonModal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import LocalizedText from '../LocalizedText.vue';
import { usePlayersStore } from '@/store/players_store';
import { GuestType } from '@/domain/gameserver/guest-type.enum';


export default /*@__PURE__*/_defineComponent({
  __name: 'GuestModalComponent',
  setup(__props, { expose: __expose }) {

const playersStore = usePlayersStore();
const guestModal = ref<InstanceType<typeof CommonModal>>();

__expose({
  open: async (params?: {
    zIndex?: number;
  }) => {
    const openPromise = guestModal.value?.open({
      zIndex: params?.zIndex ?? 1000
    });
    return openPromise;
  },
  close: () => {
    guestModal.value?.close();
  }
});

function changePermission(params: {
  userIds: string[],
  guestType: GuestType,
}) {
  playersStore.changePermission(params);
  guestModal.value?.close();
}


const __returned__ = { playersStore, guestModal, changePermission, CommonModal, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons }, LocalizedText, get GuestType() { return GuestType } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "guest-list" }
const _hoisted_2 = { class: "guest-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "guestModal",
    "is-full-screen": false
  }, {
    default: _withCtx(() => [
      _createVNode($setup["LocalizedText"], {
        class: "title",
        "text-key": "guestModal.title"
      }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"height":"16px"} }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.playersStore.players.filter(p => p.isGuest), (player) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "guest-item ui-shadows",
            key: player.id
          }, [
            _createElementVNode("p", null, _toDisplayString(player.characterName), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["FontAwesomeIcon"], {
                onClick: ($event: any) => ($setup.changePermission({ userIds: [player.id], guestType: $setup.GuestType.UNVALIDATED_GUEST })),
                class: _normalizeClass(`guest-button ${player.guestType === $setup.GuestType.UNVALIDATED_GUEST ? 'selected' : ''}`),
                icon: $setup.Icons.imported.faLock
              }, null, 8 /* PROPS */, ["onClick", "class", "icon"]),
              _createVNode($setup["FontAwesomeIcon"], {
                onClick: ($event: any) => ($setup.changePermission({ userIds: [player.id], guestType: $setup.GuestType.GUEST })),
                class: _normalizeClass(`guest-button ${player.guestType === $setup.GuestType.GUEST ? 'selected' : ''}`),
                icon: $setup.Icons.imported.faLockOpen
              }, null, 8 /* PROPS */, ["onClick", "class", "icon"]),
              _createVNode($setup["FontAwesomeIcon"], {
                onClick: ($event: any) => ($setup.changePermission({ userIds: [player.id], guestType: $setup.GuestType.DEFAULT })),
                class: _normalizeClass(`guest-button ${player.guestType === $setup.GuestType.DEFAULT ? 'selected' : ''}`),
                icon: $setup.Icons.imported.faUserCheck
              }, null, 8 /* PROPS */, ["onClick", "class", "icon"])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.buttons[data-v-7c3ef43a] {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}
.alert-modal-medium[data-v-7c3ef43a] {
  max-width: 100%;
  width: 507px;
}
.alert-modal-small[data-v-7c3ef43a] {
  max-width: 100%;
  width: 100%;
}
.title[data-v-7c3ef43a] {
  color: var(--components-modal-color-default-title-color, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.ilustration[data-v-7c3ef43a] {
  width: 80%;
  height: 100%;
}
.alert-icon[data-v-7c3ef43a] {
  display: flex;
  width: 78px;
  height: 77px;
  justify-content: center;
  align-items: center;
}
.content-paragraph[data-v-7c3ef43a] {
  text-align: center;
  color: var(--components-modal-color-default-text-color, #fff);
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}
.content-paragraph b[data-v-7c3ef43a] {
  font-weight: 800 ;
}
@media screen and (max-width: 768px) {
.alert-modal-medium[data-v-7c3ef43a] {
    max-width: 100%;
    width: 100%;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

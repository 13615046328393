<template>
  <div v-if="props.buttons.length > 0 && props.buttons.some(button => button.visible ?? true)"
    class="context-menu-container ui-shadows"
    @mouseleave="isShowingContextMenu = false"
    ref="contextMenuElem"
    @click="(e: MouseEvent) => {
      isShowingContextMenu = !isShowingContextMenu;
      e.stopPropagation();
    }"
  >
    <i
      :style="{ backgroundColor: props.backgroundColor }"
      class="bi bi-three-dots-vertical open-context-menu"
    ></i>
    <div
      v-show="isShowingContextMenu"
      class="context-menu"
      :style="{
        top: quadrant.isSuperior ? '10px' : 'unset',
        bottom: quadrant.isSuperior ? 'unset' : '10px',
        left: quadrant.isLeft ? '0' : 'unset',
        right: quadrant.isLeft ? 'unset' : '0',
        boxShadow: isShowingContextMenu ? '0 0 5px 1px rgba(0, 0, 0, 0.1)' : 'none',
      }"
    >
      <p
        class="text-option"
        v-for="(button, i) in props.buttons"
        v-show="button.visible ?? true"
        :key="button.textKey"
        @click="(e: MouseEvent) => {
          e.stopPropagation();
          button.action();
          isShowingContextMenu = false;
        }"
        :style="button.color ? {color:`var(--components-button-color-${button.color}-filled-default-background-color)`}:{}"
        >
        <FontAwesomeIcon
          v-if="button.icon"
          :icon="button.icon"
          :style="{ marginRight: '8px' }"
        />
        <span class="text-option-text">{{ texts[i] }}</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DOMUtils } from '@/core/utils/dom_utils';
import { useLocalizationStore } from '@/store/localization_store';

const isShowingContextMenu = ref(false);
const contextMenuElem = ref<HTMLDivElement>();
const quadrant = ref({
  isSuperior: false,
  isLeft: false,
});

const props = defineProps<{
  backgroundColor?: string;
  buttons: {
    textKey: string;
    action: () => void;
    visible?: boolean;
    icon?: IconDefinition;
    color?: 'cta' | 'secondary' | 'error' | 'clear';
  }[]
}>();

defineExpose({
  getElement: () => contextMenuElem.value,
});

onMounted(async () => {
  quadrant.value = DOMUtils.detectQuadrant(contextMenuElem.value!);
  texts.value = await Promise.all(props.buttons.map(async button => {
    let build = button.textKey;
    build = button.textKey.replace(/\*(.*?)\*/g, '<b>$1</b>');
    build = await localizationStore.getKey(button.textKey);
    return build;
  }));
});

const localizationStore = useLocalizationStore();

const texts = ref<string[]>([]);

</script>



<style scoped>
.context-menu-container {
  position: relative;
  font-size: 16px;
}

.open-context-menu {
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
}

.open-context-menu:hover {
  background: var(--options-color-gray-100, #F2F1F3);
}

.context-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--token-semantic-color-bg-subtle, #F2F1F3);
  z-index: 5;
  padding: 12px;
  border-radius: 8px;
  gap: 10px;
  transition: all 0.2s ease-in-out;
  z-index: 5;
}

.context-menu:hover {
  cursor: pointer;
}

.context-menu>p {
  cursor: pointer;
  padding: 16px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  text-wrap: nowrap;
}

.context-menu>p:hover {
  background-color: var(--token-semantic-color-bg-surface, #FFFFFF);
}

.text-option-text {
  pointer-events: none;
}

</style>
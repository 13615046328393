import { defineStore } from "pinia";
import { TownsRestDao } from "../infra/opagameserver/rest/towns-rest.dao";
import LoggedUserStorage from "@/infra/local_storage/logged_user.storage";
import { UserGender } from "@/models/user-gender.enum";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";

interface IState {
  username: string;
  password: string;
}

export const useUserStore = defineStore("user_store", {
  state: (): IState => ({
    username: "",
    password: ""
  }),
  actions: {
    async login(params: {
      universeId: string | undefined;
      townId: string | undefined;
      gender: UserGender;
    }) {
      const response = await TownsRestDao.login({
        universeDomain: params.universeId,
        townSubdomain: params.townId,
        data: {
          password: this.password,
          login: this.username,
          gender: params.gender,
        }
      });
      if (response.isError()) return response;
      
      LoggedUserStorage.setData(response.getValue()!);
      
      return response;
    },
    async logout() {
      LoggedUserStorage.clearUser();
      GameServerClient.clearEvts();
      GameServerClient.disconnect();
    }
  },
});

import * as livekit from 'livekit-client';
import axios from 'axios';
import { IMediaState, IMediaActions, useMediaStore } from '../../store/media_store';
import { Store } from 'pinia';
import Constants from '@/core/constants/constants';
import { FlipBoardDataKind, FlipBoardEventBus } from '../event_bus/FlipBoardEventBus';

class MediaServerClient {
  mediaStore:Store<"media_store", IMediaState, {}, IMediaActions>;
  room:livekit.Room;
  penDataCallback:Function | undefined;
  
  constructor() {
    this.mediaStore = useMediaStore();
  }

  async onOffLocalVideo() {
    // room is possible null if not connected
    if (this.room) {
      const p = this.room.localParticipant;
      let state = !p.isCameraEnabled;
      await p.setCameraEnabled(state);
      this.updateButtons(p);
    }
  }

  async onOffLocalAudio() {
    // room is possible null if not connected
    if (this.room) {
      const p = this.room.localParticipant;
      let state = !p.isMicrophoneEnabled;
      await p.setMicrophoneEnabled(state);
      this.updateButtons(p);
    }
  }

  async onOffLocalScreen() {
    // room is possible null if not connected
    if (this.room) {
      const p = this.room.localParticipant;
      let state = !p.isScreenShareEnabled;
      await p.setScreenShareEnabled(state, {audio:true});
      this.updateButtons(p);
    }
  }

  updateButtons (participant:livekit.LocalParticipant) {
    this.mediaStore.updateButtons(participant);
  }

  sendPenData (strData:string, topic:string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(strData);
    this.room.localParticipant.publishData(data, {reliable: false, topic:topic})
  }
  
  async cleanRoom() {
    if (this.room) {
      FlipBoardEventBus.sendData
      this.clearRoomEvents();
      this.room.removeAllListeners();
      this.mediaStore.$reset();
      this.mediaStore.roomConnected = false;
      //clear local tracks
      this.room.localParticipant.setCameraEnabled(false);
      this.room.localParticipant.setMicrophoneEnabled(false);
      this.room.localParticipant.setScreenShareEnabled(false);
      this.room.localParticipant.getTrackPublications().forEach(
        async t => {
          await t.track?.detach();
      });
      //clear remote tracks
      this.room.remoteParticipants.forEach(p => {
        p.getTrackPublications().forEach(async t => {
          await t.track?.detach();
        })
      })
      //destroy room
      await this.room.disconnect();
    }

    
  }

  clearRoomEvents() {

    FlipBoardEventBus.clearBoard();

    this.room
      .off(livekit.RoomEvent.ParticipantConnected, this.handleParticipantConnected.bind(this))
      .off(livekit.RoomEvent.ParticipantDisconnected, this.handleParticipantDisconnected.bind(this))
      .off(livekit.RoomEvent.Disconnected, this.handleDisconnect.bind(this))
      .off(livekit.RoomEvent.ActiveDeviceChanged, this.handleActiveDeviceChanged.bind(this))
      .off(livekit.RoomEvent.LocalTrackPublished, this.handleLocalTrackPublished.bind(this))
      .off(livekit.RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnpublished.bind(this))
      .off(livekit.RoomEvent.MediaDevicesChanged, this.handleDevicesChanged.bind(this))
      .off(livekit.RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this))
      .off(livekit.RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed.bind(this))
      .off(livekit.RoomEvent.SignalConnected, this.handleSignalConnected.bind(this))
      .off(livekit.RoomEvent.ActiveSpeakersChanged,this.handleActiveSpeakerChange.bind(this));
  }

  async connectToRoom(roomName:string, token:string) {
    try {

      if(token) {

        console.log("connect to room ", roomName)
        //let token = await this.getToken(roomName);

        await this.cleanRoom();

        this.mediaStore.roomConnecting = true;

        this.room = new livekit.Room({
          // automatically manage subscribed video quality
          adaptiveStream: true,
          // optimize publishing bandwidth and CPU for published tracks
          dynacast: true,
          // default capture settings
          videoCaptureDefaults: {
            resolution: livekit.VideoPresets.h720.resolution,
          },
        });

        this.room.prepareConnection(Constants.getLiveKitServer(), token);

        this.room
        .on(livekit.RoomEvent.DataReceived, this.handleDataReceived.bind(this))
        .on(livekit.RoomEvent.ParticipantConnected, this.handleParticipantConnected.bind(this))
        .on(livekit.RoomEvent.ParticipantDisconnected, this.handleParticipantDisconnected.bind(this))
        .on(livekit.RoomEvent.Disconnected, this.handleDisconnect.bind(this))
        //.on(livekit.RoomEvent.Reconnecting, () => console.log('Reconnecting to room'))
        // .on(livekit.RoomEvent.Reconnected, async () => {
        //   console.log(
        //     'Successfully reconnected. server',
        //     await this.room.engine.getConnectedServerAddress(),
        //   );
        // })
        .on(livekit.RoomEvent.ActiveDeviceChanged, this.handleActiveDeviceChanged.bind(this))
        .on(livekit.RoomEvent.LocalTrackPublished, this.handleLocalTrackPublished.bind(this))
        .on(livekit.RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnpublished.bind(this))
        // .on(livekit.RoomEvent.RoomMetadataChanged, (metadata) => {
        //   console.log('new metadata for room', metadata);
        // })
        .on(livekit.RoomEvent.MediaDevicesChanged, this.handleDevicesChanged.bind(this))
        // .on(livekit.RoomEvent.AudioPlaybackStatusChanged, () => {
        //   console.log("TODO AudioPlaybackStatusChanged")
        //   //TODO
        //   // if (room.canPlaybackAudio) {
        //   //   $('start-audio-button')?.setAttribute('disabled', 'true');
        //   // } else {
        //   //   $('start-audio-button')?.removeAttribute('disabled');
        //   // }
        // })
        // .on(livekit.RoomEvent.MediaDevicesError, (e: Error) => {
        //   const failure = livekit.MediaDeviceFailure.getFailure(e);
        //   console.log('media device failure', failure);
        // })
        // .on(
        //   livekit.RoomEvent.ConnectionQualityChanged,
        //   (quality: livekit.ConnectionQuality, participant?: livekit.Participant) => {
        //     console.log('connection quality changed', participant?.identity, quality);
        //   },
        // )
        .on(livekit.RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this))
        .on(livekit.RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed.bind(this))
        .on(livekit.RoomEvent.SignalConnected, this.handleSignalConnected.bind(this))
        .on(livekit.RoomEvent.ActiveSpeakersChanged,this.handleActiveSpeakerChange.bind(this))
        // .on(livekit.RoomEvent.TrackStreamStateChanged, (pub, streamState, participant) => {
        //   console.log(
        //     `stream state changed for ${pub.trackSid} (${
        //       participant.identity
        //     }) to ${streamState.toString()}`,
        //   );
        // });
        

        await this.room.connect(Constants.getLiveKitServer(), token);
        this.room.remoteParticipants.forEach((participant) => {
          this.handleParticipantConnected(participant);
        });
        this.handleParticipantConnected(this.room.localParticipant);
        this.mediaStore.updateScreenShare(this.room);

        this.mediaStore.roomConnected = true;
        this.mediaStore.roomConnecting = false;

        this.mediaStore.setRoom(this.room);

        FlipBoardEventBus.onDataSentEvt.subscribe(this.handleDataSent.name, this.handleDataSent.bind(this));

      } else {
        await this.cleanRoom();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async handleSignalConnected() {
    console.log("SignalConnected");
    await this.room.localParticipant.setCameraEnabled(false);
    await this.room.localParticipant.setMicrophoneEnabled(false);
  }

  handleParticipantConnected(participant: livekit.Participant) {
    console.log('ParticipantConnected -> tracks');
    this.mediaStore.updateParticipant(participant, false, "on_connected");
  }

  handleParticipantDisconnected(participant: livekit.Participant) {
    console.log('participant', participant.name, participant.identity, participant.sid, 'disconnected');
    this.mediaStore.updateParticipant(participant, true, "on_disconnected");
  }

  handleDisconnect(reason?: livekit.DisconnectReason) {
    console.log('disconnected from room', { reason });
    this.mediaStore.updateParticipant(this.room.localParticipant, true, "on_disconnected");
    this.room.remoteParticipants.forEach((p) => {
      this.mediaStore.updateParticipant(p, true, "on_disconnected");
    });
    this.mediaStore.updateScreenShare(this.room);
    this.mediaStore.$reset();

  }

  async handleActiveDeviceChanged(kind: MediaDeviceKind, deviceId: string) {
    const devices = await livekit.Room.getLocalDevices(kind);
    //TODO
    console.log(devices);
  }

  handleLocalTrackPublished(publication: livekit.LocalTrackPublication) {
    const track = publication.track;
    //TODO audio, screen and buttons logic
    console.log("local track published");
    this.mediaStore.updateParticipant(this.room.localParticipant, false, "on_local_track_published");
    this.mediaStore.updateScreenShare(this.room);
  }
  
  handleLocalTrackUnpublished(
    publication: livekit.LocalTrackPublication,
    participant: livekit.LocalParticipant,
  ) {
    //TODO audio, screen and buttons logic
    console.log("local track unpublished");
    this.mediaStore.updateParticipant(participant, false, "on_local_track_unpublished");
    this.mediaStore.updateScreenShare(this.room);
  }

  handleDevicesChanged() {
    //TODO
    console.log("TODO handleDevicesChanged");
  }

  handleTrackSubscribed(
    track: livekit.RemoteTrack,
    publication: livekit.RemoteTrackPublication,
    participant: livekit.RemoteParticipant,
  ) {
    console.log('subscribed to track', publication.trackSid, participant.identity);
    //this.mediaStore.updateParticipant(participant, false, "on_track_subscribed");
    this.mediaStore.updateScreenShare(this.room);
  }
  
  async handleTrackUnsubscribed(
    track: livekit.RemoteTrack,
    publication: livekit.RemoteTrackPublication,
    participant: livekit.RemoteParticipant,
  ) {
    // remove tracks from all attached elements
    console.log('unsubscribed from track', publication.trackSid);
    await track.detach();
    //this.mediaStore.updateParticipant(participant, false, "on_track_unsubscribed");
    this.mediaStore.updateScreenShare(this.room);
  }

  
  handleActiveSpeakerChange(speakers: livekit.Participant[]) {
    // show UI indicators when participant is speaking
    //TODO
    //console.log("active speakers change")
    // speakers.forEach(p => {
    //   this.mediaStore.updateParticipant(p, false, "on_speaking_changed");
    // })
    
  }

  handleDataReceived(payload: Uint8Array, participant?: livekit.RemoteParticipant, kind?: livekit.DataPacket_Kind, topic?: string) {
    const decoder = new TextDecoder()
    const strData = decoder.decode(payload)

    if (topic != undefined && topic.includes("flipboard")) {
      FlipBoardEventBus.receiveData(strData, topic as FlipBoardDataKind);
    } else {
      if (this.penDataCallback) {
        this.penDataCallback(strData, topic);
      }
    }
    
  }

  handleDataSent(strData:string, topic:string){
    const encoder = new TextEncoder()
    const data = encoder.encode(strData);
    this.room.localParticipant.publishData(data, {reliable: false, topic:topic})
  }
  
}

export default MediaServerClient;
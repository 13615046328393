import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, nextTick } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import {FlipBoardEventBus, FlipBoardDataKind, ToggleFlipBoardState} from '@/infra/event_bus/FlipBoardEventBus';

interface Pixel {
  x: number;
  y: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FlipBoardComponent',
  setup(__props, { expose: __expose }) {
  __expose();

let ctx:CanvasRenderingContext2D;
let ctxPreview:CanvasRenderingContext2D;
let isDrawing:boolean = false;
let isErasing:boolean = false;
let isRecting:boolean = false;
let isCircling:boolean = false;
let isPainting:boolean = false;

let selectedColor:string = '#000000';//cor inicial
let lastX:number = 0;
let lastY:number = 0;
let startPreviewX:number = 0;
let startPreviewY:number = 0;

const templateVisible = ref(false);

const closeElement = ref<HTMLButtonElement>();
const flipboardContainerElement = ref<HTMLDivElement>();

const penToolActive = ref(false);
const eraseToolActive = ref(false);
const bucketToolActive = ref(false);
const rectangleToolActive = ref(false);
const circleToolActive = ref(false);

const flipboardCanvasElement = ref<HTMLCanvasElement>();
const flipboardCanvasPreviewElement = ref<HTMLCanvasElement>();
const flipboardPaper = ref<HTMLDivElement>();

const clearAllToogle = () => {
  penToolActive.value = false;
  eraseToolActive.value = false;
  bucketToolActive.value = false;
  rectangleToolActive.value = false;
  circleToolActive.value = false;
}

const tooglePen = () => {
  if (penToolActive.value == false) {
    clearAllToogle();
    penToolActive.value = true;
  } else {
    penToolActive.value = false;
  }
}

const toogleErase = () => {
  if (eraseToolActive.value == false) {
    clearAllToogle();
    eraseToolActive.value = true;
  } else {
    eraseToolActive.value = false;
  }
}

const toogleBucket = () => {
  if (bucketToolActive.value == false) {
    clearAllToogle();
    bucketToolActive.value = true;
  } else {
    bucketToolActive.value = false;
  }
}

const toogleRectangle = () => {
  if (rectangleToolActive.value == false) {
    clearAllToogle();
    rectangleToolActive.value = true;
  } else {
    rectangleToolActive.value = false;
  }
}

const toogleCircle = () => {
  if (circleToolActive.value == false) {
    clearAllToogle();
    circleToolActive.value = true;
  } else {
    circleToolActive.value = false;
  }
}

const handleCloseScreen = () => {
  closeScreen();
  FlipBoardEventBus.toggleFlipBoard(ToggleFlipBoardState.OFF);
}

const closeScreen = () => {
  templateVisible.value = false;
}

const openScreen = () => {
  templateVisible.value = true;
  nextTick(() => {
    init();
  })
}


const controlScreen = (state:ToggleFlipBoardState) => {
  if(state === ToggleFlipBoardState.ON) {
    openScreen();
  } else {
    closeScreen();
  }
}

const handleRemoteData = (strData:string, kind:FlipBoardDataKind) => {

  const data = strData.split(",");

  switch (kind) {
    case FlipBoardDataKind.DRAW:
      remoteDrawLine(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.ERASE:
      remoteErase(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.RECT:
      remoteRect(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.CIRCLE:
      remoteCircle(
        Number(data[0]), 
        Number(data[1]), 
        Number(data[2]), 
        Number(data[3]), 
        data[4], 
        Number(data[5]), 
        Number(data[6]));
    break;

    case FlipBoardDataKind.FILL:
      remoteFill(
        Number(data[0]), 
        Number(data[1]), 
        hexToRgb(data[2]), 
        Number(data[3]), 
        Number(data[4]));
    break;

    case FlipBoardDataKind.CLEAR:
      remoteClear();
    break;
  
    default:
      break;
  }

}


/**
 *  DRAW FUNCTIONS
 */


const drawLine = (x:number, y:number) => {
  ctx.beginPath();
  ctx.moveTo(lastX, lastY);
  ctx.lineTo(x, y);
  ctx.lineWidth = 2;
  ctx.strokeStyle = selectedColor;
  ctx.stroke();
  ctx.closePath();
  [lastX, lastY] = [x, y];
}

const remoteDrawLine = (x:number, y:number, 
    lx:number, ly:number, color:string, 
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {
    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height;

    if(destWidth === 0 || destHeight === 0) {
      //if the component was never oppened before
      // and is receiving data, the canvas has no size
      destWidth = sourceWidth;
      destHeight = sourceHeight;
      _updateCanvasSize(destWidth, destHeight);
    }

    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;
    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    const dlx = lx * scaleX;
    const dly = ly * scaleY;
    // Draw the line on the destination canvas with the scaled coordinates
    ctx.beginPath();
    ctx.moveTo(dlx, dly);
    ctx.lineTo(dx, dy);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  }
  
}

function erase(x:number, y:number) {
  ctx.beginPath();
  ctx.fillStyle = "#ffffff";
  ctx.arc(x, y, 13, 0, 2 * Math.PI);
  ctx.fill();
  ctx.closePath();
}

const remoteErase = (
    x:number, y:number, 
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    ctx.beginPath();
    ctx.fillStyle = "#ffffff";
    ctx.arc(dx, dy, 13, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }
}

function previewRect(x:number, y:number, canvas:HTMLCanvasElement) {
  // Limpar o canvas
  ctxPreview.clearRect(0, 0, canvas.width, canvas.height);
  // Desenhar o retângulo de prévia
  ctxPreview.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Azul transparente
  ctxPreview.strokeRect(startPreviewX, startPreviewY, x - startPreviewX, y - startPreviewY);
}

function previewCircle(x:number, y:number, canvas:HTMLCanvasElement) {
  // Limpar o canvas
  ctxPreview.clearRect(0, 0, canvas.width, canvas.height);
  // Calcular os raios da elipse com base no movimento do mouse
  const radiusX = Math.abs(x - startPreviewX);
  const radiusY = Math.abs(y - startPreviewY);

  ctxPreview.beginPath();
  ctxPreview.ellipse(startPreviewX, startPreviewY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctxPreview.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Azul transparente
  ctxPreview.stroke();
}

function applyRect(x:number, y:number) {
  ctxPreview.clearRect(0,0,ctx.canvas.width, ctx.canvas.height);
  ctx.strokeStyle = selectedColor;
  ctx.strokeRect(startPreviewX, startPreviewY, x - startPreviewX, y - startPreviewY);
  isRecting = false;
}

const remoteRect = (
    x:number, y:number, lx:number, ly:number, color:string,
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    ctx.strokeStyle = color;
    ctx.strokeRect(lx, ly, dx - lx, dy - ly);
  }
}

function applyCircle(x:number, y:number) {
  ctxPreview.clearRect(0,0,ctx.canvas.width, ctx.canvas.height);
  const radiusX = Math.abs(x - startPreviewX);
  const radiusY = Math.abs(y - startPreviewY);
  ctx.beginPath();
  ctx.ellipse(startPreviewX, startPreviewY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.strokeStyle = selectedColor;
  ctx.stroke();
  isCircling = false;
}

const remoteCircle = (
    x:number, y:number, lx:number, ly:number, color:string,
    sourceWidth:number, sourceHeight:number) => {

  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    const radiusX = Math.abs(dx - lx);
    const radiusY = Math.abs(dy - ly);
    ctx.beginPath();
    ctx.ellipse(lx, ly, radiusX, radiusY, 0, 0, 2 * Math.PI);
    ctx.strokeStyle = color;
    ctx.stroke();
  }
}


const handleCleanBoard = () => {
  clearBoard();
  FlipBoardEventBus.sendData(`0,0`, FlipBoardDataKind.CLEAR);
};

const clearBoard = () => {
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0,0, ctx.canvas.width, ctx.canvas.height);
}

const remoteClear = () => {
  clearBoard();
}


function floodFill(px:number, py:number, newColor:number[]) {
  let canvas:HTMLCanvasElement = flipboardCanvasElement.value!;
  if (canvas != undefined) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const originalColor = getColorAt(px, py, data);

    if (originalColor === newColor) return;

    function getColorAt(cx: number, cy: number, datai: Uint8ClampedArray): [number, number, number, number] {
      const index = (cy * canvas.width + cx) * 4;
      return [datai[index], datai[index + 1], datai[index + 2], 255];
    }
 
    const visited: Set<string> = new Set();
    const stack: Pixel[] = [{ x:px, y:py }];

    while (stack.length > 0) {
      const { x, y } = stack.pop()!;
      const index = (y * canvas.width + x) * 4;

      if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
        const color = getColorAt(x, y, data);
        if (color[0] === originalColor[0] && color[1] === originalColor[1] && color[2] === originalColor[2] && !visited.has(`${x},${y}`)) {
          data[index] = newColor[0];
          data[index + 1] = newColor[1];
          data[index + 2] = newColor[2];
          visited.add(`${x},${y}`);
          stack.push({ x: x + 1, y });
          stack.push({ x: x - 1, y });
          stack.push({ x: x, y: y + 1 });
          stack.push({ x: x, y: y - 1 });
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }
}


function remoteFill(px:number, py:number, newColor:number[],sourceWidth:number, sourceHeight:number) {
  let canvas:HTMLCanvasElement = flipboardCanvasElement.value!;
  if (canvas != undefined) {

    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = px * scaleX;
    const dy = py * scaleY;


    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const originalColor = getColorAt(dx, dy, data);

    if (originalColor === newColor) return;

    function getColorAt(cx: number, cy: number, datai: Uint8ClampedArray): [number, number, number, number] {
      const index = (cy * canvas.width + cx) * 4;
      return [datai[index], datai[index + 1], datai[index + 2], 255];
    }
 
    const visited: Set<string> = new Set();
    const stack: Pixel[] = [{ x:dx, y:dy }];

    while (stack.length > 0) {
      const { x, y } = stack.pop()!;
      const index = (y * canvas.width + x) * 4;

      if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
        const color = getColorAt(x, y, data);
        if (color[0] === originalColor[0] && color[1] === originalColor[1] && color[2] === originalColor[2] && !visited.has(`${x},${y}`)) {
          data[index] = newColor[0];
          data[index + 1] = newColor[1];
          data[index + 2] = newColor[2];
          visited.add(`${x},${y}`);
          stack.push({ x: x + 1, y });
          stack.push({ x: x - 1, y });
          stack.push({ x: x, y: y + 1 });
          stack.push({ x: x, y: y - 1 });
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }
}


const _updateCanvasSize = (w:number, h:number) => {
  if (flipboardCanvasElement.value) {

    let canvas = flipboardCanvasElement.value;
    let preview = flipboardCanvasPreviewElement.value;
    
    let img = new Image();
    let dataURL = canvas.toDataURL();
    img.src = dataURL;
    
    canvas.setAttribute(
         "style", `width:${(w).toFixed(0)}px;height:${(h).toFixed(0)}px;`);
    canvas.setAttribute("width",`${(w).toFixed(0)}`);
    canvas.setAttribute("height",`${(h).toFixed(0)}`);

    
    preview!.setAttribute("width",`${(w).toFixed(0)}`);
    preview!.setAttribute("height",`${(h).toFixed(0)}`);
    // v-show perde ao reescrever o style
    let previewDisplay = '';
    if(rectangleToolActive.value === false && circleToolActive.value === false) {
      previewDisplay = 'display:none'
    }
    preview!.setAttribute(
         "style", `width:${(w).toFixed(0)}px;height:${(h).toFixed(0)}px;${previewDisplay}`);
    
    ctx = flipboardCanvasElement.value?.getContext('2d')!;
    ctxPreview = flipboardCanvasPreviewElement.value?.getContext('2d')!;
    img.onload = function() {
      ctx.drawImage(img,0,0, w, h);
    };
  }
}

const fixCanvasSize = () => {
  _updateCanvasSize(flipboardPaper.value?.clientWidth!, flipboardPaper.value?.clientHeight!);
}

const hexToRgb = (hex:string) => {
  // Remove o símbolo # se presente
  hex = hex.replace(/^#/, '');
  // Verifica se o valor hexadecimal tem 3 ou 6 dígitos
  if (hex.length === 3) {
    hex = hex.split('').map(hex => hex + hex).join('');
  }
  // Converte cada par de caracteres hexadecimais para um número decimal
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b, 255 ];
}

window.addEventListener("resize", fixCanvasSize, false);

const initUIState = () => {
  console.log("init UI flipboard");
  init();
  initEvents();
}

const init = () => {
  fixCanvasSize();

  ctx = flipboardCanvasElement.value?.getContext('2d')!;
  ctxPreview = flipboardCanvasPreviewElement.value?.getContext('2d')!;

  let canvas = flipboardCanvasElement.value;
  if (canvas) {
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0,0, canvas!.width, canvas!.height );
  }
}

const initEvents = () => {
  FlipBoardEventBus.onToogleFlipBoardEvt.subscribe(controlScreen.name, controlScreen);
  FlipBoardEventBus.onDataReceivedEvt.subscribe(handleRemoteData.name, handleRemoteData);
  FlipBoardEventBus.onClearBoard.subscribe(remoteClear.name, remoteClear);

  flipboardCanvasElement.value?.addEventListener('click', (e) => {
    if (bucketToolActive.value === true && !isPainting) {
      isPainting = true;
      floodFill(e.offsetX, e.offsetY, hexToRgb(selectedColor));
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.FILL);
      isPainting = false;
    }
  });

  flipboardCanvasElement.value?.addEventListener('mouseleave', (e) => {
    isDrawing = false;
    isErasing = false;
    isRecting = false;
    isCircling = false;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mouseleave', (e) => {
    isDrawing = false;
    isErasing = false;
    isRecting = false;
    isCircling = false;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });

  flipboardCanvasElement.value?.addEventListener('mousedown', (e) => {
    isDrawing = penToolActive.value;
    isErasing = eraseToolActive.value;
    isRecting = rectangleToolActive.value;
    isCircling = circleToolActive.value;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mousedown', (e) => {
    isRecting = rectangleToolActive.value;
    isCircling = circleToolActive.value;
    if (isRecting || isCircling) {
      [startPreviewX, startPreviewY] = [e.offsetX, e.offsetY];
    }
  });
  
  flipboardCanvasElement.value?.addEventListener('mousemove', (e) => {
    if (isDrawing){
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.DRAW);
      drawLine(e.offsetX, e.offsetY);
    } else if (isErasing) {
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.ERASE);
      erase(e.offsetX, e.offsetY);
    }
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mousemove', (e) => {
    if (isRecting) {
      let canvasPreview = flipboardCanvasPreviewElement.value;
      previewRect(e.offsetX, e.offsetY, canvasPreview!);
    } else if (isCircling) {
      let canvasPreview = flipboardCanvasPreviewElement.value;
      previewCircle(e.offsetX, e.offsetY, canvasPreview!);
    }
  });

  flipboardCanvasElement.value?.addEventListener('mouseup', (e) => {
    isDrawing = false;
    isErasing = false;
  });
  flipboardCanvasPreviewElement.value?.addEventListener('mouseup', (e) => {
    if (isRecting === true) {
      applyRect(e.offsetX, e.offsetY);
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${startPreviewX},${startPreviewY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.RECT);
    } else if (isCircling) {
      applyCircle(e.offsetX, e.offsetY);
      FlipBoardEventBus.sendData(`${e.offsetX},${e.offsetY},${startPreviewX},${startPreviewY},${selectedColor},${flipboardCanvasElement.value?.width},${flipboardCanvasElement.value?.height}`, FlipBoardDataKind.CIRCLE);
    }
  });
}

onMounted(initUIState);


const __returned__ = { get ctx() { return ctx }, set ctx(v) { ctx = v }, get ctxPreview() { return ctxPreview }, set ctxPreview(v) { ctxPreview = v }, get isDrawing() { return isDrawing }, set isDrawing(v) { isDrawing = v }, get isErasing() { return isErasing }, set isErasing(v) { isErasing = v }, get isRecting() { return isRecting }, set isRecting(v) { isRecting = v }, get isCircling() { return isCircling }, set isCircling(v) { isCircling = v }, get isPainting() { return isPainting }, set isPainting(v) { isPainting = v }, get selectedColor() { return selectedColor }, set selectedColor(v) { selectedColor = v }, get lastX() { return lastX }, set lastX(v) { lastX = v }, get lastY() { return lastY }, set lastY(v) { lastY = v }, get startPreviewX() { return startPreviewX }, set startPreviewX(v) { startPreviewX = v }, get startPreviewY() { return startPreviewY }, set startPreviewY(v) { startPreviewY = v }, templateVisible, closeElement, flipboardContainerElement, penToolActive, eraseToolActive, bucketToolActive, rectangleToolActive, circleToolActive, flipboardCanvasElement, flipboardCanvasPreviewElement, flipboardPaper, clearAllToogle, tooglePen, toogleErase, toogleBucket, toogleRectangle, toogleCircle, handleCloseScreen, closeScreen, openScreen, controlScreen, handleRemoteData, drawLine, remoteDrawLine, erase, remoteErase, previewRect, previewCircle, applyRect, remoteRect, applyCircle, remoteCircle, handleCleanBoard, clearBoard, remoteClear, floodFill, remoteFill, _updateCanvasSize, fixCanvasSize, hexToRgb, initUIState, init, initEvents, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
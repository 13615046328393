<script setup lang="ts">
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineEmits } from 'vue';

defineProps<{
  icon?: IconDefinition;
}>();

defineEmits<{
  (e: 'click'): void
}>();

</script>

<template>
  <button class="icon-button ui-shadows" @click="$emit('click')">
    <FontAwesomeIcon v-if="icon" :icon="icon"/>
    <slot></slot>
  </button>
</template>

<style scoped lang="css">

.icon-button {
  margin-right: 8px;
  font-size: 20px;
}

</style>
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pen-tools-container" }
const _hoisted_2 = { class: "pen-tools ui-shadows" }
const _hoisted_3 = { class: "color-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "color",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedColor) = $event)),
          onInput: $setup.updateColor
        }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vModelText, $setup.selectedColor]
        ])
      ]),
      _createElementVNode("button", {
        onClick: $setup.updatePen,
        class: _normalizeClass({ active: $setup.penActive })
      }, [
        _createVNode($setup["FontAwesomeIcon"], {
          icon: $setup.Icons.imported.faPen
        }, null, 8 /* PROPS */, ["icon"])
      ], 2 /* CLASS */),
      _createElementVNode("button", {
        onClick: $setup.updateErase,
        class: _normalizeClass({ active: $setup.eraseActive })
      }, [
        _createVNode($setup["FontAwesomeIcon"], {
          icon: $setup.Icons.imported.faEraser
        }, null, 8 /* PROPS */, ["icon"])
      ], 2 /* CLASS */),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cleanBoard')))
      }, [
        _createVNode($setup["FontAwesomeIcon"], {
          icon: $setup.Icons.imported.faTrash
        }, null, 8 /* PROPS */, ["icon"])
      ])
    ])
  ]))
}
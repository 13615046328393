import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "text-option-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.buttons.length > 0 && $setup.props.buttons.some(button => button.visible ?? true))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "context-menu-container ui-shadows",
        onMouseleave: _cache[0] || (_cache[0] = ($event: any) => ($setup.isShowingContextMenu = false)),
        ref: "contextMenuElem",
        onClick: _cache[1] || (_cache[1] = (e) => {
      $setup.isShowingContextMenu = !$setup.isShowingContextMenu;
      e.stopPropagation();
    })
      }, [
        _createElementVNode("i", {
          style: _normalizeStyle({ backgroundColor: $setup.props.backgroundColor }),
          class: "bi bi-three-dots-vertical open-context-menu"
        }, null, 4 /* STYLE */),
        _withDirectives(_createElementVNode("div", {
          class: "context-menu",
          style: _normalizeStyle({
        top: $setup.quadrant.isSuperior ? '10px' : 'unset',
        bottom: $setup.quadrant.isSuperior ? 'unset' : '10px',
        left: $setup.quadrant.isLeft ? '0' : 'unset',
        right: $setup.quadrant.isLeft ? 'unset' : '0',
        boxShadow: $setup.isShowingContextMenu ? '0 0 5px 1px rgba(0, 0, 0, 0.1)' : 'none',
      })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.buttons, (button, i) => {
            return _withDirectives((_openBlock(), _createElementBlock("p", {
              class: "text-option",
              key: button.textKey,
              onClick: (e) => {
          e.stopPropagation();
          button.action();
          $setup.isShowingContextMenu = false;
        },
              style: _normalizeStyle(button.color ? {color:`var(--components-button-color-${button.color}-filled-default-background-color)`}:{})
            }, [
              (button.icon)
                ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                    key: 0,
                    icon: button.icon,
                    style: { marginRight: '8px' }
                  }, null, 8 /* PROPS */, ["icon"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", _hoisted_2, _toDisplayString($setup.texts[i]), 1 /* TEXT */)
            ], 12 /* STYLE, PROPS */, _hoisted_1)), [
              [_vShow, button.visible ?? true]
            ])
          }), 128 /* KEYED_FRAGMENT */))
        ], 4 /* STYLE */), [
          [_vShow, $setup.isShowingContextMenu]
        ])
      ], 544 /* NEED_HYDRATION, NEED_PATCH */))
    : _createCommentVNode("v-if", true)
}
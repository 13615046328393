<template>
  <div class="input-container ui-shadows">
    <FontAwesomeIcon :icon="Icons.imported.faComment"></FontAwesomeIcon>
    <input
      ref="myInput"
      :type="type ?? 'text'"
      v-maska
      :data-maska="mask"
      :value="modelValue"
      class="rounded-input"
      :placeholder="label"
      @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineExpose, onMounted, ref } from 'vue';
import { vMaska } from "maska"
import { useLocalizationStore } from '@/store/localization_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';

const props = defineProps<{
  modelValue: string,
  labelKey: string,
  mask?: string;
  type?: string;
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'focus', value: FocusEvent): void,
  (e: 'blur', value: FocusEvent): void,
}>();

const label = ref(props.labelKey);

const localizationStore = useLocalizationStore();

defineExpose({
  setFocus(v:boolean){
    if(v) {
      myInput.value?.focus();
    } else {
      myInput.value?.blur();
    }
  }
})

onMounted(async () => {
  label.value = await localizationStore.getKey(props.labelKey);
});

const myInput = ref<InstanceType<typeof HTMLInputElement>>();

</script>



<style scoped>
.input-container {
  flex: 1;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.70);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 12px;
  flex-shrink: 0;
  height: 60px;
  backdrop-filter: blur(32px);

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.input-container:focus {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
  border: 1px linear #FFF;
}

.input-container svg {
  width: 19px;
  height: 19px;
}

.rounded-input {
  height: 100%;
  background: none;
  border: none;
  outline: none;
}

.rounded-input::placeholder {
  color: #353535;
}

</style>

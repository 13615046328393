<script setup lang="ts">
import { ref } from 'vue';
import CommonModal from '../modals/CommonModal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import LocalizedText from '../LocalizedText.vue';
import { usePlayersStore } from '@/store/players_store';
import { GuestType } from '@/domain/gameserver/guest-type.enum';

const playersStore = usePlayersStore();
const guestModal = ref<InstanceType<typeof CommonModal>>();

defineExpose({
  open: async (params?: {
    zIndex?: number;
  }) => {
    const openPromise = guestModal.value?.open({
      zIndex: params?.zIndex ?? 1000
    });
    return openPromise;
  },
  close: () => {
    guestModal.value?.close();
  }
});

function changePermission(params: {
  userIds: string[],
  guestType: GuestType,
}) {
  playersStore.changePermission(params);
  guestModal.value?.close();
}

</script>

<template>
  <CommonModal ref="guestModal" :is-full-screen="false">
    <LocalizedText class="title" text-key="guestModal.title"></LocalizedText>
    <div style="height: 16px;"></div>
    <div class="guest-list">
      <div class="guest-item ui-shadows" v-for="player in playersStore.players.filter(p => p.isGuest)" :key="player.id">
        <p>{{ player.characterName }}</p>
        <div class="guest-buttons">
          <FontAwesomeIcon
            @click="changePermission({ userIds: [player.id], guestType: GuestType.UNVALIDATED_GUEST })"
            :class="`guest-button ${player.guestType === GuestType.UNVALIDATED_GUEST ? 'selected' : ''}`"
            :icon="Icons.imported.faLock"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            @click="changePermission({ userIds: [player.id], guestType: GuestType.GUEST })"
            :class="`guest-button ${player.guestType === GuestType.GUEST ? 'selected' : ''}`"
            :icon="Icons.imported.faLockOpen"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            @click="changePermission({ userIds: [player.id], guestType: GuestType.DEFAULT })"
            :class="`guest-button ${player.guestType === GuestType.DEFAULT ? 'selected' : ''}`"
            :icon="Icons.imported.faUserCheck"
          ></FontAwesomeIcon>
        </div>
      </div>
    </div>
  </CommonModal>
</template>

<style scoped>

.title {
  font-weight: 800;
  font-size: 32px;
  color: #fafafa;
  text-align: center;
}

.guest-item p {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
}

.guest-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
}

.guest-buttons {
  display: flex;
  gap: 2px;
}

.guest-button {
  padding: 8px;
  background-color: #aaa;
  border-radius: 8px;
  width: 30px;
}

.guest-button:not(.selected) {
  cursor: pointer;
}

.guest-button:not(.selected):hover {
  background-color: #ccc;
}

.guest-button.selected {
  background-color: #58af6e;
  color: #fff;
}

.guest-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 350px;
  overflow-y: auto;
  padding: 16px;
}

</style>
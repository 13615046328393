// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button-container[data-v-1ef0aead] {
  width: 100%;
}
.rounded-button[data-v-1ef0aead] {
  padding: 16px 32px;
  border-radius: 24px;
  border: 2px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: #832999;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}
.rounded-button[data-v-1ef0aead]:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

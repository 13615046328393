import LoggedUserStorage from "@/infra/local_storage/logged_user.storage";
import { TownsRestDao } from "@/infra/opagameserver/rest/towns-rest.dao";
import { LoginResponse } from "@/models/login-response.model";
import { UserGender } from "@/models/user-gender.enum";
import { ValueResult } from "@/models/value-result.model";
import { defineStore } from "pinia";

interface IState {
  firstName: string;
  lastName: string;
  gender: UserGender;
}

export const useGuestLoginStore = defineStore("guest_login_store", {
  state: (): IState => ({
    firstName: "",
    lastName: "",
    gender: UserGender.MALE,
  }),
  actions: {
    async guestLogin(params: {
      universeId: string | undefined;
      townId: string | undefined;
    }): Promise<ValueResult<LoginResponse | null>> {
      const response = await TownsRestDao.guestLogin({
        universeDomain: params.universeId,
        townSubdomain: params.townId,
        data: {
          firstName: this.firstName,
          lastName: this.lastName,
          gender: this.gender,
        }
      });
      if (response.isError()) return response;
      
      LoggedUserStorage.setData(response.getValue()!);
      
      return response;
    },
  },
});

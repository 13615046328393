// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.screen-container[data-v-c8300bf4]{
  position: relative;
  overflow: hidden;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 24px;
  flex: 1;
  max-height: 130px;
  min-height: 50px;
  cursor: pointer;
  margin: 8px;
}
.screen-container[data-v-c8300bf4]:hover {
  background: #000;
  opacity: 0.95;
}
.video-element[data-v-c8300bf4]{
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.name-element[data-v-c8300bf4] {
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: #353535;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  font-size: 12px;
  padding: 6px 12px;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-weight: 800;
  border-radius: 8px;
  line-height: normal;
}
.pin-button[data-v-c8300bf4] {
  position: absolute;
  top: 8px;
  right: 8px;

  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  border-radius: 12px;
  width: 32px;
  height: 32px;
  color: #353535;
}
.pin-button[data-v-c8300bf4]:hover {
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

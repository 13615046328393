import { defineComponent as _defineComponent } from 'vue'
import { Participant, RemoteTrack, RemoteTrackPublication, ParticipantEvent, Track, TrackPublication, LocalParticipant, RemoteParticipant } from 'livekit-client';
import { forEachChild } from 'typescript';
import {onMounted, onUnmounted, ref} from 'vue';

enum ConnectionQuality {
  Excellent = 'excellent',
  Good = 'good',
  Poor = 'poor',
  Lost = 'lost',
  Unknown = 'unknown',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ParticipantComponent',
  props: {
  participant: {
    type: Participant,
    required: true,
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;

let startTime = Date.now();
let cameraPub:TrackPublication | undefined = undefined;
let micPub:TrackPublication | undefined = undefined;

const videoElement = ref<HTMLVideoElement>();
const audioElement = ref<HTMLAudioElement>();
const participantSignalElement = ref<HTMLDivElement>();
const participantContainerElement = ref<HTMLDivElement>();
const participantProfilePic = ref<HTMLDivElement>();
const participantMicElement = ref<HTMLDivElement>();
const participantMicContainer= ref<HTMLDivElement>()
const participantMicElement2 = ref<HTMLDivElement>();
const participantMicContainer2= ref<HTMLDivElement>()

let cameraEnabledState = ref(false);
let audioEnabledState:boolean = false;
let micActiveState:boolean = false;
let isSpeakingState:boolean = false;
let signalQualityState:string = ConnectionQuality.Unknown;

__expose({
  updateUI() {
    _updateUI();
  }
})

const _updateUI = () => {

  console.log("update trigger", props.participant.name, props.participant.sid);
  console.log(props.participant["eventName"]);
  setVideo();
  setAudio();
  setMic();
  setSignalQuality();
  setSpeaking(undefined);
}

const initUIState = () => {
  console.log("init UI");
  //console.log(props.participant);
  props.participant
    .on(ParticipantEvent.TrackSubscribed, trackUpdateSubscription)
    .on(ParticipantEvent.TrackUnsubscribed, trackUpdateSubscription)
    .on(ParticipantEvent.TrackMuted, trackMuted)
    .on(ParticipantEvent.TrackUnmuted, trackUnMuted)
    .on(ParticipantEvent.IsSpeakingChanged, isSpeakingChanged)
    .on(ParticipantEvent.ConnectionQualityChanged, connectionQualityChanged);
  _updateUI();
}

const trackUpdateSubscription = (remoteTrack:RemoteTrack, remoteTrackPublication:RemoteTrackPublication) => {
  console.log("trackupdate", props.participant.name)
  setVideo();
  setAudio();
  setMic();
}

const trackMuted = (pub: TrackPublication) => {
  console.log("on_track_muted", props.participant.name);
  setVideo();
  setAudio();
  setMic();
}

const trackUnMuted = (pub: TrackPublication) => {
  console.log("on_track_unmuted", props.participant.name);
  setVideo();
  setAudio();
  setMic();
}

const isSpeakingChanged = (speaking: boolean) => {
  console.log("is speak changed", props.participant.name);
  setSpeaking(speaking);
}

const connectionQualityChanged = (connectionQuality: ConnectionQuality) => {
  console.log("quality changed", props.participant.name);
  setSignalQuality();
}

const destroy = () => {
  console.log("destroy");
  props.participant.off(ParticipantEvent.TrackSubscribed, trackUpdateSubscription);
  props.participant.off(ParticipantEvent.TrackUnsubscribed, trackUpdateSubscription);
  props.participant.off(ParticipantEvent.TrackMuted, trackMuted);
  props.participant.off(ParticipantEvent.TrackUnmuted, trackUnMuted);
  props.participant.off(ParticipantEvent.IsSpeakingChanged, isSpeakingChanged);
  props.participant.off(ParticipantEvent.ConnectionQualityChanged, connectionQualityChanged);
}

const setVideo = () => {
  if (videoElement.value) {
    let element:HTMLVideoElement = videoElement.value;
  
    cameraPub = props.participant.getTrackPublication(Track.Source.Camera);
    const cameraEnabled = cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
    
    //check if the state changes before update all elements
    if (cameraEnabled != cameraEnabledState.value) {
      //update camera state
      cameraEnabledState.value = cameraEnabled ? cameraEnabled : false;
      if (cameraEnabled) {
        if (props.participant instanceof LocalParticipant) {
          // flip
          element.style.transform = 'scale(-1, 1)';
        } else if (!cameraPub?.videoTrack?.attachedElements.includes(element)) {
          const renderStartTime = Date.now();
          // measure time to render
          element.onloadeddata = () => {
            const elapsed = Date.now() - renderStartTime;
            let fromJoin = 0;
            if (props.participant.joinedAt && props.participant.joinedAt.getTime() < startTime) {
              fromJoin = Date.now() - startTime;
            }
            console.log(
              `RemoteVideoTrack ${cameraPub?.trackSid} (${element.videoWidth}x${element.videoHeight}) rendered in ${elapsed}ms`,
              fromJoin > 0 ? `, ${fromJoin}ms from start` : '',
            );
          };
        }
        cameraPub?.videoTrack?.attach(element);
      } else {
        // clear information display
        if (cameraPub?.videoTrack) {
          // detach manually whenever possible
          cameraPub.videoTrack?.detach(element);
        } else {
          element.src = '';
          element.srcObject = null;
        }
      }
    }
  }
}

const setAudio = () => {
  if (audioElement.value) {
    let element:HTMLAudioElement = audioElement.value;

    micPub = props.participant.getTrackPublication(Track.Source.Microphone);
    const micEnabled = (micPub && micPub.isSubscribed && (micPub.isMuted === false))?true:false;
    //console.log("mic enabled", micEnabled)
    //console.log(micPub, micPub?.isSubscribed, micPub?.isMuted);
    if (micEnabled != audioEnabledState) {
      audioEnabledState = micEnabled ? micEnabled : false;
      if (micEnabled) {
        if (!(props.participant instanceof LocalParticipant)) {
          // don't attach local audio
          element.onloadeddata = () => {
            if (props.participant.joinedAt && props.participant.joinedAt.getTime() < startTime) {
              const fromJoin = Date.now() - startTime;
              console.log(`RemoteAudioTrack ${micPub?.trackSid} played ${fromJoin}ms from start`);
            }
          };
          micPub?.audioTrack?.attach(element);
        }
      } else {
        if (!(props.participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.detach(element);
        }
      }
    }
  }
}

const setMic = () => {
  if (participantMicElement.value &&
     participantMicContainer.value &&
     participantMicContainer2.value && 
     participantMicElement2.value) {

    let icon: HTMLDivElement = participantMicElement.value;
    let container: HTMLDivElement = participantMicContainer.value;
    let container2: HTMLDivElement = participantMicContainer2.value;
    let icon2: HTMLDivElement = participantMicElement2.value;
    
    micPub = props.participant.getTrackPublication(Track.Source.Microphone);
    const micEnabled = (micPub && (micPub.isMuted === false))?true:false;
    
    //update only if state changes
    if (micEnabled != micActiveState) {
      micActiveState = micEnabled ? micEnabled : false;

      //update mic state on css
      if (micActiveState) {
        icon.classList.remove("fa-microphone-slash");
        icon.classList.add("fa-microphone");
        container.classList.add("div-on");
        container.classList.remove("div-off");

        icon2.classList.remove("fa-microphone-slash");
        icon2.classList.add("fa-microphone");
        container2.classList.add("div-on");
        container2.classList.remove("div-off");
      } else {
        icon.classList.add("fa-microphone-slash");
        icon.classList.remove("fa-microphone");
        container.classList.remove("div-on");
        container.classList.add("div-off");

        icon2.classList.add("fa-microphone-slash");
        icon2.classList.remove("fa-microphone");
        container2.classList.remove("div-on");
        container2.classList.add("div-off");
      }
    }
  }
}


const setSignalQuality = () => {
  if (participantSignalElement.value) {

    let element:HTMLDivElement = participantSignalElement.value;
    const quality = props.participant.connectionQuality.valueOf();

    if(quality != signalQualityState) {
      signalQualityState = quality;
      switch (signalQualityState) {
        case ConnectionQuality.Poor:
          element.classList.remove("color-off");
          element.classList.add("color-poor");
          element.classList.remove("color-good");
          element.classList.remove("color-excellent");
          break;
        case ConnectionQuality.Good:
          element.classList.remove("color-off");
          element.classList.remove("color-poor");
          element.classList.add("color-good");
          element.classList.remove("color-excellent");
          break;
        case ConnectionQuality.Excellent:
          element.classList.remove("color-off");
          element.classList.remove("color-poor");
          element.classList.remove("color-good");
          element.classList.add("color-excellent");
          break;
        default:
          element.classList.add("color-off");
          element.classList.remove("color-poor");
          element.classList.remove("color-good");
          element.classList.remove("color-excellent");
          break;
      }
    }
  }
}

const setSpeaking = (isSpeaking:boolean | undefined) => {
  if (participantProfilePic.value) {
    let element:HTMLDivElement = participantProfilePic.value;
    const speaking = isSpeaking == undefined ? props.participant.isSpeaking: isSpeaking;
    if (speaking != isSpeakingState) {
      isSpeakingState = speaking;
      if(isSpeakingState) {
        element.classList.add("speaking");
        element.classList.remove("not-speaking");
      } else {
        element.classList.remove("speaking");
        element.classList.add("not-speaking");
      }
    }
  }
}

onMounted(initUIState);
onUnmounted(destroy);

const __returned__ = { props, ConnectionQuality, get startTime() { return startTime }, set startTime(v) { startTime = v }, get cameraPub() { return cameraPub }, set cameraPub(v) { cameraPub = v }, get micPub() { return micPub }, set micPub(v) { micPub = v }, videoElement, audioElement, participantSignalElement, participantContainerElement, participantProfilePic, participantMicElement, participantMicContainer, participantMicElement2, participantMicContainer2, get cameraEnabledState() { return cameraEnabledState }, set cameraEnabledState(v) { cameraEnabledState = v }, get audioEnabledState() { return audioEnabledState }, set audioEnabledState(v) { audioEnabledState = v }, get micActiveState() { return micActiveState }, set micActiveState(v) { micActiveState = v }, get isSpeakingState() { return isSpeakingState }, set isSpeakingState(v) { isSpeakingState = v }, get signalQualityState() { return signalQualityState }, set signalQualityState(v) { signalQualityState = v }, _updateUI, initUIState, trackUpdateSubscription, trackMuted, trackUnMuted, isSpeakingChanged, connectionQualityChanged, destroy, setVideo, setAudio, setMic, setSignalQuality, setSpeaking }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
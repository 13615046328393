// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/flipboard/pen.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/flipboard/eraser2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pen-canvas-container[data-v-5d9480e7]{
  position: fixed;
  height: calc(100% + 4px);
  /* left: calc(25); */
  /* transform:translate(-50%,0); */
  z-index: 5000;
}
.pen-canvas[data-v-5d9480e7] {
  /* position: relative; */
  /* width: 100%;
  height: calc(100% - 18px); */
  /* border: 1px solid greenyellow; */
}
.pen-active[data-v-5d9480e7] {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 38, auto;
}
.erase-active[data-v-5d9480e7] {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) 14 14, auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

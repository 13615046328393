import { defineComponent as _defineComponent } from 'vue'
import EmojiConstants, { Emoji, EMOJI_TIMER } from "../../core/constants/emoji_constants";
import { ref, computed } from 'vue';

type EmojiRow = {
  id: number;
  items: Emoji[];
}

type EmojiTab = {
  title: string,
  content: EmojiRow[]
}

const columnsPerRow = 8;


export default /*@__PURE__*/_defineComponent({
  __name: 'EmojiContainerComponent',
  emits: ["close-container", "clear-emoji", "send-emoji"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();


const emit = __emit

const emojiTimer = ref(EMOJI_TIMER.TEMP);
const selectedTab = ref(0);
const tabs = computed(() => {
  let tabs: EmojiTab[] = [];
  for (let x = 0; x < EmojiConstants.EMOJI_CATEGORIES.length; x++) {
    let rowsArray: EmojiRow[] = [];
    let items = EmojiConstants.EMOJIS[EmojiConstants.EMOJI_CATEGORIES[x].name];
    for (let i = 0; i < items.length; i += columnsPerRow) {
      rowsArray.push({
        id: i,
        items: items.slice(i, i + columnsPerRow)
      });
    }
    tabs.push({
      title: EmojiConstants.EMOJI_CATEGORIES[x].icon,
      content: rowsArray
    })
  }
  return tabs;
});

const choiceEmoji = (emoji: Emoji) => {
  emit("close-container");
  emit("send-emoji", emoji, emojiTimer.value);
}

const clearEmoji = () => {
  emit("close-container");
  emit("clear-emoji");
}

const close = () => {
  emit("close-container");
}


const __returned__ = { emit, emojiTimer, selectedTab, columnsPerRow, tabs, choiceEmoji, clearEmoji, close, get EMOJI_TIMER() { return EMOJI_TIMER } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
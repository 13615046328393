import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "controllers" }
const _hoisted_2 = { id: "media-controllers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: $setup.onOffLocalAudio,
        class: _normalizeClass(["ui-shadows", $setup.mediaStore.toggleAudioButton? 'on' : 'off'])
      }, [
        _createVNode($setup["FontAwesomeIcon"], {
          icon: $setup.mediaStore.toggleAudioButton? $setup.Icons.imported.faMicrophone : $setup.Icons.imported.faMicrophoneSlash
        }, null, 8 /* PROPS */, ["icon"])
      ], 2 /* CLASS */),
      _createElementVNode("button", {
        onClick: $setup.onOffLocalVideo,
        class: _normalizeClass(["ui-shadows", $setup.mediaStore.toggleVideoButton? 'on' : 'off'])
      }, [
        _createVNode($setup["FontAwesomeIcon"], {
          icon: $setup.mediaStore.toggleVideoButton? $setup.Icons.imported.faVideo : $setup.Icons.imported.faVideoSlash
        }, null, 8 /* PROPS */, ["icon"])
      ], 2 /* CLASS */),
      _createElementVNode("button", {
        class: _normalizeClass(["screen-sharing ui-shadows", $setup.mediaStore.toggleScreenShareButton? 'on' : 'off']),
        onClick: $setup.onOffLocalScreen
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", {
          viewBox: "0 0 30 21",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M3 21C1.34315 21 0 19.6569 0 18V3C0 1.34315 1.34315 0 3 0H27C28.6569 0 30 1.34315 30 3V18C30 19.6569 28.6569 21 27 21H3ZM15.7071 5.79289C15.3166 5.40237 14.6834 5.40237 14.2929 5.79289L10.7929 9.29289C10.4024 9.68342 10.4024 10.3166 10.7929 10.7071C11.1834 11.0976 11.8166 11.0976 12.2071 10.7071L14 8.91421V15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15V8.91421L17.7929 10.7071C18.1834 11.0976 18.8166 11.0976 19.2071 10.7071C19.5976 10.3166 19.5976 9.68342 19.2071 9.29289L15.7071 5.79289Z"
          })
        ], -1 /* HOISTED */)
      ]), 2 /* CLASS */),
      _createElementVNode("button", {
        class: _normalizeClass(["screen-sharing ui-shadows", $setup.flipboardOn ? 'on' : 'off']),
        onClick: _cache[0] || (_cache[0] = () => $setup.onOffFlipboard())
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          version: "1.1",
          x: "0px",
          y: "0px",
          viewBox: "0 0 256 256",
          "enable-background": "new 0 0 256 256",
          "xml:space": "preserve"
        }, [
          _createElementVNode("g", null, [
            _createElementVNode("g", null, [
              _createElementVNode("g", null, [
                _createElementVNode("path", {
                  fill: "#000000",
                  d: "M109.3,4.6c-2.3,1-4.3,3.2-5.2,5.5c-0.4,1-0.7,5.3-0.7,9.7c0,7.5,0.1,8,1.5,9.6l1.5,1.7H128h21.6l1.5-1.8c1.5-1.7,1.5-2,1.3-10.6c-0.2-9.5-0.6-10.5-4.4-13.4c-1.6-1.2-2.6-1.3-19.3-1.4C115,3.8,110.7,4,109.3,4.6z"
                }),
                _createElementVNode("path", {
                  fill: "#000000",
                  d: "M10.1,99.5l0.2,77.9l1.5,3.1c1.9,3.9,5.6,7.6,9.5,9.5l3.1,1.5l46.6,0.2l46.5,0.1v17.8v17.8H88.5c-28.9,0-29.1,0-31.8,1.4c-1.5,0.8-3.6,2.5-4.5,3.7c-1.8,2.2-1.9,2.8-1.9,7.2c0,4.4,0.1,5,1.9,7.2c1,1.3,3.1,3,4.5,3.7l2.7,1.4h67.8c59.9,0,68.1-0.1,70.1-1c8.4-3.4,10.4-14,4-20c-3.7-3.6-3.7-3.6-35.2-3.6h-28.9v-17.8v-17.8l47.2-0.1l47.1-0.2l3.1-1.5c3.9-1.9,7.6-5.6,9.5-9.5l1.5-3.1l0.2-77.9l0.1-77.9h-41.8h-41.8v3.2c0,6.7-2.9,11.9-8.2,14.6c-3,1.5-3.1,1.5-22.2,1.7c-23.1,0.2-28.3-0.2-31.8-2.7c-4-2.8-6-6.7-6.4-12.2l-0.3-4.6H51.7H10L10.1,99.5z M181.2,100.9c0.3,0,6-5.3,12.6-11.9c10.4-10.4,12.3-12,14.4-12.4c3.3-0.6,6.3,1.1,7.9,4.2c2.2,4.5,1.7,5.2-17.8,24.3l-17.6,17.1l-11.5-11.7c-6.3-6.5-13.3-13.6-15.4-15.9l-4-4.2l-25.4,26.1c-14,14.4-25.7,26.2-25.9,26.2c-0.3,0-5.7-4.5-11.9-10.1c-6.3-5.6-11.6-10.1-11.9-10.1c-0.2,0-5.2,4.5-10.9,10c-5.8,5.5-11.1,10.4-11.8,10.8c-5.3,2.9-12-2.9-10-8.7c0.9-2.7,31.5-32.6,32.7-32c0.4,0.2,5.7,4.9,11.7,10.3c6,5.3,11.1,9.6,11.4,9.5c0.3-0.1,12.1-12,26.2-26.5l25.7-26.4l15.4,15.8C173.6,93.8,180.8,100.9,181.2,100.9z"
                })
              ])
            ])
          ])
        ], -1 /* HOISTED */)
      ]), 2 /* CLASS */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.mediaStore.roomConnected]
    ])
  ]))
}
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class ImutableModel {
  @JsonProperty()
  public id: string;
  @JsonProperty()
  public createdAt: Date;

  constructor(params) {
    if (!params) return;
  }
}
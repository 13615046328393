// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gradient[data-v-5aad6caa] {
  position: fixed;
  background: linear-gradient(360deg, #7C2DCA 0%, #EF4F83 100%);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.login-inside[data-v-5aad6caa] {
  background: linear-gradient(360deg, #853EBC 0%, #FF588E 100%);
  border-radius: 48px;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 48px;
  gap: 32px;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.3);
  width: 28%;
  min-width: 400px;
}
.gender-container[data-v-5aad6caa] {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-around;
  gap: 8px;
}
.gender-selector[data-v-5aad6caa] {
  border: 4px solid lightgrey;
  border-radius: 16px;
  cursor: pointer;
  background-color: #FFFFFF75;
  border-radius: 16px;
  box-sizing: content-box;
}
.gender-selector img[data-v-5aad6caa] {
  width: 100px;
  height: 100px;
  padding: 8px;
}
.gender-selector[data-v-5aad6caa]:hover {
  border-color: #b1b1b1;
}
.gender-selector[data-v-5aad6caa]:active {
  border: 4px solid #EF4F83;
  border-radius: 16px;
}
.selected-border[data-v-5aad6caa] {
  border-color: #EF4F83 !important;
  border-width: 4px !important;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

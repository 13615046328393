import { EventBus } from '../EventBus';
import { Scene } from 'phaser';

export class TileMapTest extends Scene
{
    camera: Phaser.Cameras.Scene2D.Camera;
    background: Phaser.GameObjects.Image;
    gameOverText : Phaser.GameObjects.Text;

    myId:string = new Date().getTime().toString();

    constructor ()
    {
        super('TileMapTest');
        //connect web socket
    }

    create ()
    {
        this.camera = this.cameras.main
        this.camera.setBackgroundColor(0xffffff);
        EventBus.emit('current-scene-ready', this);

        this.createCharacter("teste");

        
    }

    changeScene ()
    {
        this.scene.start('MainMenu');
    }

    createCharacter (data:any) {
      console.log("create char", data);
      this.add.image(512, 300, 'logo').setDepth(100);
    }
}

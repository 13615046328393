<script setup lang="ts">
import { RouterView } from 'vue-router';
import LoadingModal from './components/modals/LoadingModal.vue';
import AlertModal from './components/modals/AlertModal.vue';
import { provide, ref } from 'vue';
import { AlertModalInjection, CommonToastInjection, InteractionIframeInjection, LoadingModalInjection } from './core/constants/injections';
import CommonToast from './components/CommonToast.vue';
import InteractionIframe from './components/modals/InteractionIframe.vue';

const alertModal = ref<InstanceType<typeof AlertModal>>();
const loadingModal = ref<InstanceType<typeof LoadingModal>>();
const commonToast = ref<InstanceType<typeof CommonToast>>();
const interactionIframe = ref<InstanceType<typeof InteractionIframe>>();

provide(AlertModalInjection, alertModal);
provide(LoadingModalInjection, loadingModal);
provide(CommonToastInjection, commonToast);
provide(InteractionIframeInjection, interactionIframe);

</script>

<template>
  <AlertModal ref="alertModal" />
  <LoadingModal ref="loadingModal" />
  <CommonToast ref="commonToast" />
  <InteractionIframe ref="interactionIframe" />

  <RouterView />
</template>

<style scoped></style>

import { defineStore } from "pinia";
import { ConnectionState, LocalParticipant, Participant, RemoteTrackPublication, Room, Track, TrackPublication } from 'livekit-client';
import { ScreenShareDTO } from "@/dto/ScreenShareDTO";

export interface IMediaState {
  toggleVideoButton:boolean,
  toggleAudioButton:boolean,
  toggleScreenShareButton:boolean,
  remoteParticipants:Map<string, Participant>,
  localParticipant:LocalParticipant | null,
  screens:Map<string,ScreenShareDTO>
  screen:ScreenShareDTO | undefined,
  showScreenFrame:boolean
  roomConnecting:boolean
  roomConnected:boolean
  room:Room | undefined
}

export interface IMediaActions {
  updateButtons,
  updateParticipant,
  updateScreenShare,
  hideScreenShare,
  cleanRemote,
  setRoom,
  disconnectFromRoom,
  _updateLocalScreen,
  _updateRemoteScreens,
  _updateLocalParticipant,
  _updateRemoteParticipant,
  updateBigScreenShare
}

export const useMediaStore = defineStore("media_store", {

  state: (): IMediaState => ({
    toggleVideoButton: false,
    toggleAudioButton: false,
    toggleScreenShareButton: false,
    remoteParticipants: new Map<string, Participant>(),
    localParticipant: null,
    screens:new Map<string,ScreenShareDTO>(),
    screen:undefined,
    showScreenFrame:false,
    roomConnecting:false,
    roomConnected:false,
    room:undefined
  }),

  actions: {

    setRoom(room:Room) {
      this.room = room;
    },

    updateButtons(participant:LocalParticipant) {
      this.toggleAudioButton = participant.isMicrophoneEnabled;
      this.toggleVideoButton = participant.isCameraEnabled;
      this.toggleScreenShareButton = participant.isScreenShareEnabled;
    },

    updateParticipant(participant:Participant, deleteParticipant:boolean = false, eventName:string) {
      //console.log("update participant");
      if (participant instanceof LocalParticipant) {
        this.updateButtons(participant);
        this._updateLocalParticipant(participant, deleteParticipant, eventName);
      } else {
        this._updateRemoteParticipant(participant, deleteParticipant, eventName);
      }
    },

    updateBigScreenShare(screenShareDTO?:ScreenShareDTO) {
      console.log("update big screen");
      console.log(screenShareDTO);
      this.screen = screenShareDTO;
      this.showScreenFrame = true;
    },

    hideScreenShare() {
      this.showScreenFrame = false;
      this.screen = undefined;
    },

    updateScreenShare(room:Room) {
      //console.log("update screen share local and remote");
      if(room.state === ConnectionState.Connected) {
        this._updateLocalScreen(room);
        this._updateRemoteScreens(room);

        let screenKeys = Array.from(this.screens.keys());
        if(this.screen === undefined && screenKeys.length) {
          //console.log("screen undefined and keys has length");
          let dto:ScreenShareDTO|undefined = this.screens.get(screenKeys[screenKeys.length-1]) as ScreenShareDTO;
          if(dto !== undefined) {
            this.updateBigScreenShare(dto);
          }
        } else {
          //console.log("not screen undefined and keys has length");
          // console.log(this.screen);
          // console.log(this.screens);
          console.log(screenKeys);
        }

      }
    },

    _updateLocalScreen(room:Room) {
      let screenSharePub: TrackPublication | undefined = 
        room.localParticipant.getTrackPublication(
          Track.Source.ScreenShare,
        );
      let screenShareAudioPub: TrackPublication | undefined;
      if (!screenSharePub || !screenSharePub.isSubscribed) {
        if (this.screens.get(room.localParticipant.sid)){
          //clear big screen before delete
          if (this.screen?.screenSID === room.localParticipant.sid){
            this.hideScreenShare();
          }
          this.screens.delete(room.localParticipant.sid);
        }
      } else {
        let dto:ScreenShareDTO = new ScreenShareDTO();
        screenShareAudioPub = room.localParticipant.getTrackPublication(
          Track.Source.ScreenShareAudio,
        );
        dto.screen = screenSharePub;
        dto.screenAudio = screenShareAudioPub;
        dto.screenName = (room.localParticipant.name ?  room.localParticipant.name : "anonymous") + "'s screen";
        dto.screenSID = room.localParticipant.sid;
        this.screens.set(room.localParticipant.sid, dto);
        console.log("set new screen");
      }
    },

    _updateRemoteScreens(room:Room) {
      
      room.remoteParticipants.forEach(((p) => {

        let screenSharePub: RemoteTrackPublication | undefined;
        let screenShareAudioPub: RemoteTrackPublication | undefined;

        //console.log(p);
        //console.log(p.getTrackPublications());

        screenSharePub = p.getTrackPublication(Track.Source.ScreenShare);
        if (!screenSharePub || !screenSharePub.isSubscribed) {
          if (this.screens.get(p.sid)){
            //clear big screen before delete
            if (this.screen?.screenSID === p.sid){
              this.hideScreenShare();
            }
            this.screens.delete(p.sid);
          }
        } else {
          let dto:ScreenShareDTO = new ScreenShareDTO();
          screenShareAudioPub = p.getTrackPublication(
            Track.Source.ScreenShareAudio,
          );
          dto.screen = screenSharePub;
          dto.screenAudio = screenShareAudioPub;
          dto.screenName = (p.name ?  p.name : "anonymous") + "'s screen";
          dto.screenSID = p.sid;
          this.screens.set(p.sid, dto);
          console.log("set new remote screen");
        }
      }).bind(this));
    },

    _updateLocalParticipant(participant:LocalParticipant, deleteParticipant:boolean = false, eventName:string) {
      if(deleteParticipant) {
        this.localParticipant = null;
      } else {
        this.localParticipant = participant;
        this.localParticipant["eventName"] = eventName;
      }
    },

    _updateRemoteParticipant(participant:Participant, deleteParticipant:boolean = false, eventName:string) {

      if (deleteParticipant) {
        console.log("delete");
        this.remoteParticipants.delete(participant.identity);
        console.log(this.remoteParticipants);
      } else {
        if (!this.remoteParticipants.get(participant.identity) && eventName == "on_connected"){
          this.remoteParticipants.set(participant.identity, participant);
        } else if (this.remoteParticipants.get(participant.identity)) {
          console.log("update", participant.name, eventName);
          //this.remoteParticipants.delete(participant.identity);
          participant["eventName"] = eventName;
          this.remoteParticipants.set(participant.identity, participant);
          //console.log(this.remoteParticipants);
        }
      }
    },

    cleanRemote(remoteId:string) {
      console.log("clean remote", remoteId);
      this.remoteParticipants.delete(remoteId);
    },

    async disconnectFromRoom() {
      if(this.room) {
        await this.room.disconnect();
        this.$reset();
      }
    }
    
  }

  
});

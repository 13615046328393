import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import CommonModal from './CommonModal.vue';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionIframe',
  setup(__props, { expose: __expose }) {

const modal = ref<InstanceType<typeof CommonModal>>();

const iframeUrl = ref<string | null>(null);

__expose({
  open,
  close,
});

async function open<T=void>(params: {
  iframeUrl?: string;
  zIndex?: number;
}) {
  const modalPromise = modal.value?.open<T>(params);
  iframeUrl.value = params.iframeUrl ?? null;
  return modalPromise;
}

async function close<T=void>(res: T) {
  if (!res) return;
  modal.value?.close<T>(res);
}


const __returned__ = { modal, iframeUrl, open, close, CommonModal, get LoggedUserStorage() { return LoggedUserStorage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "rounded-button ui-shadows",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
      title: "props.labelKey"
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("svg", {
        viewBox: "0 0 31 31",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", { d: "M28.4576 16.5873L3.44611 26.9655C3.02549 27.14 2.56891 27.0664 2.22458 26.7682C1.88031 26.4702 1.74196 26.0288 1.85446 25.5875L4.19514 16.4083H13.2206C13.7222 16.4083 14.1288 16.0017 14.1288 15.5001C14.1288 14.9985 13.7222 14.5918 13.2206 14.5918H4.1952L1.85446 5.41279C1.74196 4.97153 1.88031 4.53014 2.22458 4.23207C2.56885 3.934 3.02531 3.86025 3.44605 4.03475L28.4576 14.413C29.1312 14.6925 29.1836 15.3158 29.1836 15.5001C29.1836 15.6845 29.1312 16.3078 28.4576 16.5873Z" }),
        _createElementVNode("path", { d: "M28.4576 16.5873L3.44611 26.9655C3.02549 27.14 2.56891 27.0664 2.22458 26.7682C1.88031 26.4702 1.74196 26.0288 1.85446 25.5875L4.19514 16.4083H13.2206C13.7222 16.4083 14.1288 16.0017 14.1288 15.5001C14.1288 14.9985 13.7222 14.5918 13.2206 14.5918H4.1952L1.85446 5.41279C1.74196 4.97153 1.88031 4.53014 2.22458 4.23207C2.56885 3.934 3.02531 3.86025 3.44605 4.03475L28.4576 14.413C29.1312 14.6925 29.1836 15.3158 29.1836 15.5001C29.1836 15.6845 29.1312 16.3078 28.4576 16.5873Z" }),
        _createElementVNode("path", { d: "M29.1538 12.7353L4.14228 2.35699C3.08858 1.91972 1.89823 2.11208 1.03568 2.85874C0.173129 3.60553 -0.187609 4.7561 0.0942971 5.86157L1.9908 13.2988C2.27147 14.3264 2.73333 14.6188 4.1952 14.5918L1.85446 5.41279C1.74196 4.97153 1.88031 4.53014 2.22458 4.23207C2.56885 3.934 3.02531 3.86025 3.44605 4.03475L28.4576 14.413C29.1312 14.6925 29.1836 15.3158 29.1836 15.5001C29.1836 15.6845 29.1312 16.3078 28.4576 16.5873L3.44611 26.9655C3.02549 27.14 2.56891 27.0664 2.22458 26.7682C1.88031 26.4702 1.74196 26.0288 1.85446 25.5875L4.19514 16.4083C2.84904 16.3315 2.32032 16.5048 2.04217 17.5L0.0942971 25.1387C-0.187609 26.2442 0.173069 27.3948 1.03562 28.1415C1.8998 28.8898 3.09046 29.0797 4.14228 28.6433L29.1538 18.265C30.2926 17.7925 31 16.7331 31 15.5001C31 14.2672 30.2926 13.2078 29.1538 12.7353Z" })
      ], -1 /* HOISTED */)
    ]))
  ]))
}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.participant-container[data-v-23074005] {
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 24px;
  padding: 16px 32px 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;
}
.participant-container-video-enabled[data-v-23074005] {
  position: relative;
  display: flex;
  width: 100%;
  padding: 8px;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  border-radius: 24px;
}
.participant-name-container-video-enabled[data-v-23074005] {
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  align-items: center;

  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  padding: 6px 12px;
  border-radius: 8px;
}
.participant-container-video-enabled .participant-name[data-v-23074005] {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.5px;
}
.participant-container-video-enabled .participant-mic-container[data-v-23074005] {
  bottom: 16px;
  right: 16px;
}
.video-element[data-v-23074005]{
  width: 100%;
  border-radius: 17px;
}
.participant-name-container[data-v-23074005] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.participant-name[data-v-23074005]{
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.participant-signal[data-v-23074005] {
  margin-left:5px;
  margin-right:5px;
  height:15px;
  font-size: 12px;
}
.participant-mic-container[data-v-23074005] {
  position: absolute;
  right: -4px;
  bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  width: 20px;
  height: 20px;
}
.participant-mic[data-v-23074005] {
  font-size: 10px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-pic[data-v-23074005] {
  position: relative;
  width:50px;
  height:50px;
  border-radius: 10000px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
  background-color: #c1bcfd;
}
.profile-container[data-v-23074005] {
  display:flex;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}
.div-off[data-v-23074005] {
  background-color: #b95356;
}
.div-on[data-v-23074005] {
  background-color: #58af6e;
}
.color-off[data-v-23074005] {
  color: #353535;
}
.color-on[data-v-23074005] {
  color: #58af6e;
}
.color-poor[data-v-23074005]{
  color:darkred;
}
.color-good[data-v-23074005]{
  color:darkorange;
}
.color-excellent[data-v-23074005]{
  color:green;
}
.speaking[data-v-23074005] {
  border: 2px solid #58af6e;
  box-shadow: 0px 0px 20px 1.21px rgba(13, 149, 51, 1.00);
}
.not-speaking[data-v-23074005] {
  border: 2px solid transparent;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

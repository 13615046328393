import { library } from '@fortawesome/fontawesome-svg-core';
import { faPix, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faSmile, faPen, faEraser, faCaretDown, faMinus, faPlus, faTag, faTrash, faCartShopping, faChevronLeft, faChevronRight, faUser, faShieldHalved, faBox, faHouse, faBuilding, faGear, faXmark, faBoxOpen, faFileCircleCheck, faHouseCircleCheck, faArrowRight, faChevronDown, faLocationDot, faTruck, faFileDownload, faPaperPlane, faFileExcel, faDownload, faFilter, faArrowUpFromBracket, faArrowLeft, faEllipsisV, faExclamation, faCircleExclamation, faCreditCard, faPizzaSlice, faEarthAmericas, faCheck, faEye, faEyeSlash, faDollarSign, faUtensils, faCartPlus, faRotateLeft, faCircleXmark, faPenToSquare, faDollar, faVanShuttle, faRotateRight, faUpload, faTable, faArrowDown, faUnlock, faEnvelope, faCopy, faLocationPin, faStar, faSearch, faCalendar, faCalendarAlt, faFileInvoiceDollar, faFile, faRotate, faRepeat, faUpRightAndDownLeftFromCenter, faWindowMinimize, faLock, faCoins, faCircleCheck, faArrowRotateLeft, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faComment, faMapPin, faCircleQuestion, faFileCircleQuestion, faPersonCircleQuestion, faUserCheck, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faCalendar as faCalendarRegular } from '@fortawesome/free-regular-svg-icons';

export class Icons {
  static readonly imported = {
    faTag,
    faPlus,
    faTrash,
    faMinus,
    faSearch,
    faCaretDown,
    faCartShopping,
    faChevronLeft,
    faUserCheck,
    faChevronRight,
    faUser,
    faShieldHalved,
    faBox,
    faBoxOpen,
    faFileCircleCheck,
    faHouseCircleCheck,
    faHouse,
    faBuilding,
    faGear,
    faXmark,
    faArrowRight,
    faChevronDown,
    faLocationDot,
    faTruck,
    faRotateLeft,
    faCircleXmark,
    faFilter,
    faFileDownload,
    faPaperPlane,
    faFileExcel,
    faDownload,
    faArrowLeft,
    faEllipsisV,
    faArrowUpFromBracket,
    faExclamation,
    faFileCircleQuestion,
    faPersonCircleQuestion,
    faCircleExclamation,
    faCartPlus,
    faDollar,
    faEarthAmericas,
    faEraser,
    faUtensils,
    faDollarSign,
    faCircleQuestion,
    faCreditCard,
    faPizzaSlice,
    faCheck,
    faEye,
    faEyeSlash,
    faPen,
    faPenToSquare,
    faLocationPin,
    faStar,
    faCopy,
    faVanShuttle,
    faRotateRight,
    faWhatsapp,
    faUpload,
    faTable,
    faArrowDown,
    faUnlock,
    faEnvelope,
    faFileInvoiceDollar,
    faCalendar,
    faCalendarAlt,
    faCalendarRegular,
    faPix,
    faFile,
    faRotate,
    faRepeat,
    faUpRightAndDownLeftFromCenter,
    faWindowMinimize,
    faLock,
    faCoins,
    faCircleCheck,
    faArrowRotateLeft,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faComment,
    faMapPin,
    faSmile,
    faLockOpen,
  };

  public static import() {
    library.add(this.imported);
  }
}
<script setup lang="ts">
import { useLocalizationStore } from '@/store/localization_store';
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps<{
  textKey: string,
  replacer?: (text: string) => string,
}>();

const localizationStore = useLocalizationStore();

const text = ref(props.textKey)

onMounted(async () => {
  text.value = await localizationStore.getKey(props.textKey);
  text.value = props.replacer?.(text.value) ?? text.value;
  text.value = text.value.replace(/\*(.*?)\*/g, '<b>$1</b>');
});

</script>

<template>
  <p v-html="text"></p>
</template>

<style scoped>
p {
  margin: 0;
}
</style>
import { Scene } from 'phaser';

export class Preloader extends Scene
{
    constructor ()
    {
        super('Preloader');
    }

    init ()
    {
        //  A simple progress bar. This is the outline of the bar.
        this.add.rectangle(512, 384, 468, 32).setStrokeStyle(1, 0xffffff);

        //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
        const bar = this.add.rectangle(512-230, 384, 4, 28, 0xffffff);

        //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
        this.load.on('progress', (progress: number) => {

            //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
            bar.width = 4 + (460 * progress);

        });
    }

    preload ()
    {
        //  Load the assets for the game - Replace with your own assets
        this.load.setPath('assets');

        // Preload images from character 
        this.load.spritesheet('char_demo_front_walking_male', 'character_demo/kuririn_front_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_back_walking_male', 'character_demo/kuririn_back_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_left_walking_male', 'character_demo/kuririn_left_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_right_walking_male', 'character_demo/kuririn_right_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_front_idle_male', 'character_demo/kuririn_front_idle.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });

        this.load.spritesheet('char_demo_front_walking_female', 'character_demo/joana_front_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_back_walking_female', 'character_demo/joana_back_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_left_walking_female', 'character_demo/joana_left_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_right_walking_female', 'character_demo/joana_right_walk.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });
        this.load.spritesheet('char_demo_front_idle_female', 'character_demo/joana_front_idle.png', { frameWidth: 128, frameHeight: 128, startFrame: 0, endFrame:35 });

        this.load.image('shadow', 'character_demo/shadow.png');
    }

    create ()
    {
        //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
        //  For example, you can define global animations here, so we can use them in other scenes.

        //  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
        this.scene.start('RoomScene');
    }
}

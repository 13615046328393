import { PaginationDTO } from "@/dto/pagination.dto";
import TownStorage from "@/infra/local_storage/town.storage";
import { TownsRestDao } from "@/infra/opagameserver/rest/towns-rest.dao";
import { Town } from "@/models/town.model";
import { ValueResult } from "@/models/value-result.model";
import { defineStore } from "pinia";

interface IState {
  town: Town | null;
}

export const useTownStore = defineStore("town_store", {
  state: (): IState => ({
    town: null
  }),
  actions: {
    async getTown(params: {
      filters: {
        subdomains: string[]
      },
      pagination?: PaginationDTO
    }): Promise<ValueResult<Town | null>> {
      const response = await TownsRestDao.getTowns({
        ...params
      });
      
      const [responseValue, responseError] = [response?.getValue()! ,response.getError()!];
      if (responseError) return ValueResult.fromError(responseError);
      this.town = responseValue.items[0] ?? null;
      if (!responseValue.items[0]) return new ValueResult();

      TownStorage.setData(responseValue.items[0]);
      
      return new ValueResult({ value: responseValue.items[0] });
    },
  }
});
import { Action } from "@/domain/Action";

export enum ToggleFlipBoardState {
  ON = 'active',
  OFF = 'innactive',
}

export enum FlipBoardDataKind {
  DRAW = "flipboard_draw",
  ERASE = "flipboard_erase",
  CLEAR = "flipboard_clear",
  COLOR = "flipboard_color",
  RECT = "flipboard_rect",
  CIRCLE = "flipboard_circle",
  FILL = "flipboard_fill"
}

export class FlipBoardEventBus {
  static onDataSentEvt: Action<string, FlipBoardDataKind> = new Action();
  static onDataReceivedEvt: Action<string, FlipBoardDataKind> = new Action();
  static onToogleFlipBoardEvt: Action<ToggleFlipBoardState> = new Action();
  static onClearBoard: Action<string> = new Action();

  static toggleFlipBoard(toggleFlipBoardState: ToggleFlipBoardState) {
    FlipBoardEventBus.onToogleFlipBoardEvt.publish(toggleFlipBoardState);
  }

  static sendData(message: string, kind:FlipBoardDataKind) {
    FlipBoardEventBus.onDataSentEvt.publish(message, kind);
  }

  static receiveData(message: string, kind:FlipBoardDataKind) {
    FlipBoardEventBus.onDataReceivedEvt.publish(message, kind);
  }

  static clearBoard() {
    FlipBoardEventBus.onClearBoard.publish("clearboard");
  }

}
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AlertModal"], { ref: "alertModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["LoadingModal"], { ref: "loadingModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["CommonToast"], { ref: "commonToast" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["InteractionIframe"], { ref: "interactionIframe" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["RouterView"])
  ], 64 /* STABLE_FRAGMENT */))
}
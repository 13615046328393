import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "media_local_participant_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.mediaStore.localParticipant)
      ? (_openBlock(), _createBlock($setup["ParticipantComponent"], {
          key: 0,
          participant: $setup.mediaStore.localParticipant,
          ref: "participant"
        }, null, 8 /* PROPS */, ["participant"]))
      : _createCommentVNode("v-if", true)
  ]))
}
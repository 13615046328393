<script setup lang="ts">
import LocalizedText from './LocalizedText.vue';

const props = defineProps<{
  labelKey: string,
}>();

defineEmits<{
  (e: 'click'): void,
}>();

</script>

<template>
  <div class="button-container">
    <button
      class="rounded-button ui-shadows"
      @click="$emit('click')"
    >
      <LocalizedText :text-key="props.labelKey"></LocalizedText>
    </button>
  </div>
</template>

<style scoped>
.button-container {
  width: 100%;
}

.rounded-button {
  padding: 16px 32px;
  border-radius: 24px;
  border: 2px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: #832999;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.rounded-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>

import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-select-container ui-shadows" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["LocalizedText"], { "text-key": $props.label }, null, 8 /* PROPS */, ["text-key"]),
    _createElementVNode("select", {
      class: "text-select",
      name: $props.name,
      value: $props.value?.toUpperCase(),
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event, (($event.target as HTMLInputElement).value))))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, i) => {
        return (_openBlock(), _createElementBlock("option", {
          class: "text-option",
          value: option.value.toUpperCase(),
          key: option.value
        }, _toDisplayString($setup.optionsLocalized[i].label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ]))
}
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "loading-container" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    ($setup.isLoading)
      ? (_openBlock(), _createBlock($setup["LottieAnimation"], {
          key: 0,
          animationData: $setup.LoadingLottieJSON,
          loop: true,
          autoPlay: true,
          style: {"width":"66px"}
        }, null, 8 /* PROPS */, ["animationData"]))
      : _createCommentVNode("v-if", true),
    (!$setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!$setup.isError)
            ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                key: 0,
                class: "loaded-icon",
                icon: $setup.Icons.imported.faCircleCheck
              }, null, 8 /* PROPS */, ["icon"]))
            : _createCommentVNode("v-if", true),
          ($setup.isError)
            ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                key: 1,
                class: "loaded-icon error",
                icon: $setup.Icons.imported.faXmark
              }, null, 8 /* PROPS */, ["icon"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"height":"var(--semantic-spacing-stack-200, 16px)"} }, null, -1 /* HOISTED */)),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 2,
          textKey: $setup.loadingText ?? 'loaderAnimation.loading',
          class: "loading-text"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true),
    (!$setup.isLoading && !$setup.isError)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 3,
          textKey: $setup.loadedText ?? 'loaderAnimation.loaded',
          class: "loading-text loaded"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true),
    (!$setup.isLoading && $setup.isError)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 4,
          textKey: $setup.errorText ?? 'loaderAnimation.error',
          class: "loading-text error"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"height":"4px"} }, null, -1 /* HOISTED */)),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 5,
          textKey: $setup.loadingSubtext ?? 'loaderAnimation.loadingSubtext',
          class: "loading-subtext"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true),
    (!$setup.isLoading && !$setup.isError)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 6,
          textKey: $setup.loadedSubtext ?? 'loaderAnimation.loadedSubtext',
          class: "loading-subtext"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true),
    (!$setup.isLoading && $setup.isError)
      ? (_openBlock(), _createBlock($setup["LocalizedText"], {
          key: 7,
          textKey: $setup.errorSubtext ?? 'loaderAnimation.errorSubtext',
          class: "loading-subtext error"
        }, null, 8 /* PROPS */, ["textKey"]))
      : _createCommentVNode("v-if", true)
  ]))
}
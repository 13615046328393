// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pen-tools-container[data-v-0d684b70] {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.pen-tools[data-v-0d684b70] {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
}
.color-picker[data-v-0d684b70] {
  width: 30px;
  height: 30px;
  margin:2px;
}
.color-picker input[data-v-0d684b70]{
  width: 30px;
  height: 30px;
}
button[data-v-0d684b70] {
  width:30px;
  height:30px;
  margin:2px;
  border-radius:4px;
}
.active[data-v-0d684b70] {
  background-color:#58af6e;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

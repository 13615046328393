// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button-container[data-v-3d670c4c] {
  width: 100%;
}
.rounded-button[data-v-3d670c4c] {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.70);
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width:60px;
}
.rounded-button[data-v-3d670c4c]:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}
.rounded-button svg[data-v-3d670c4c] {
  width: 18px;
  height: 18px;
  fill: #353535;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

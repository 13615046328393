// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.media-container[data-v-5b644420] {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 260px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.media-person-container[data-v-5b644420] {
  flex-grow: 1;
  overflow: auto;
  padding-right: 4px;
}
.phaser-container[data-v-5b644420] {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.phaser-container canvas[data-v-5b644420] {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.button[data-v-5b644420] {
  background-color: #8300C7;
  color: white;
  border-radius: 7px;
  padding: 12px 24px;
  max-width: 170px;
}
.initial-buttons[data-v-5b644420] {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 15px;
}
.bottom-toolbar[data-v-5b644420] {
  position: fixed;
  left: calc(50%);
  transform: translateX(-50%);
  bottom: 16px;
  display: inline-flex;
}
.bottom-toolbar .divider[data-v-5b644420] {
  width: 1.5px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 10000px;
  opacity: 0.7;
  margin-right: 8px;
  margin-top: 8px;
}
.bottom-toolbar button[data-v-5b644420],
.bottom-toolbar .context-menu-container[data-v-5b644420] {
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;

  transition: all 100ms ease-out;
}
.bottom-toolbar button.on[data-v-5b644420],
.bottom-toolbar button.screen-sharing.on[data-v-5b644420] {
  background: #58af6e;
}
.bottom-toolbar button.on[data-v-5b644420]:hover,
.bottom-toolbar button.screen-sharing.on[data-v-5b644420]:hover {
  background: #3E9553;
}
.bottom-toolbar button.off[data-v-5b644420] {
  background: #b95356;
}
.bottom-toolbar button.off[data-v-5b644420]:hover {
  background: #AB4144;
}
.bottom-toolbar button svg[data-v-5b644420] {
  color: #FFFFFF;
  width: 20px;
  height: 20px;
}
.bottom-toolbar button.screen-sharing[data-v-5b644420],
.bottom-toolbar .context-menu-container[data-v-5b644420] {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
}
.bottom-toolbar button.screen-sharing[data-v-5b644420]:hover,
.bottom-toolbar .context-menu-container[data-v-5b644420]:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}
.bottom-toolbar button.screen-sharing.on svg[data-v-5b644420] {
  fill: #FFF;
}
.bottom-toolbar button.screen-sharing svg[data-v-5b644420] {
  width: 24px;
  height: 24px;
  fill: #353535;
}
.bottom-toolbar button svg.fa-video-slash[data-v-5b644420] {
  width: 21px;
  height: 21px;
}
.bottom-toolbar button svg.fa-microphone-slash[data-v-5b644420] {
  width: 24px;
  height: 24px;
}
.bottom-toolbar .open-context-menu[data-v-5b644420] {
  color: #353535;
}
.bottom-toolbar .open-context-menu[data-v-5b644420]:hover {
  background: none;
}
.bottom-toolbar .context-menu[data-v-5b644420] {
  top: 0px !important;
  bottom: unset !important;
  transform: translateY(-100%) !important;
  transition: all 300ms ease-out;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

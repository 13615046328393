export class PaginatedListDTO<T> {
  items: T[];

  pageCount: number;

  constructor(params: { items: T[]; pageCount: number }) {
    if (!params) return;
    this.items = params.items;
    this.pageCount = params.pageCount;
  }

  static empty<T>(): PaginatedListDTO<T> {
    return new PaginatedListDTO({
      items: [],
      pageCount: 0,
    });
  }
}
<script setup lang="ts">
import { useLocalizationStore } from '@/store/localization_store';
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import LocalizedText from './LocalizedText.vue';

const props = defineProps<{
  name: string,
  label: string,
  value: string,
  options: {
    label: string,
    value: string
  }[],
}>();

defineEmits<{
  (e: 'change', evt: Event, value: string): void
}>();

const localizationStore = useLocalizationStore();
const optionsLocalized = ref(props.options);

onMounted(async () => {
  optionsLocalized.value = await Promise.all(props.options.map(async (option) => ({
    label: await localizationStore.getKey(option.label),
    value: option.value
  })));
});

</script>

<template>
  <div class="text-select-container ui-shadows">
    <LocalizedText :text-key="label"></LocalizedText>
    <select
      class="text-select"
      :name="name"
      :value="value?.toUpperCase()"
      @change="$emit('change', $event, (($event.target as HTMLInputElement).value))"
    >
      <option
        class="text-option"
        v-for="option, i in options"
        :value="option.value.toUpperCase()"
        :key="option.value"
      >{{ optionsLocalized[i].label }}</option>
    </select>
  </div>
</template>

<style scoped>

.text-select-container {
  border: 1px solid rgba(255, 255, 255, 0.70);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  background-color: var(--token-semantic-color-bg-subtle, #f5f5f5);
  border-radius: 8px;
  padding: 8px;
}

.text-select {
  outline: none;
  border: none;
  border-radius: 5px;
}

</style>
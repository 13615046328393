import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { ImutableModel } from "./imutable.model";


@JsonObject()
export class Town extends ImutableModel {
  @JsonProperty()
  public universeId: string;
  @JsonProperty()
  public name: string;
  @JsonProperty()
  public subdomain: string;
  @JsonProperty()
  public logoUrl: string;

  constructor(params?: {
    id: string;
    universeId: string;
    name: string;
    subdomain: string;
    logoUrl: string;
  }) {
    super(params);
    if (!params) return;
    this.id = params.id;
    this.universeId = params.universeId;
    this.name = params.name;
    this.subdomain = params.subdomain;
    this.logoUrl = params.logoUrl;
  }
}
import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Vector2 } from "./vector-2.model";
import { MoveDir } from "./move-player.model";

@JsonObject()
export class ChangeMediaRoomRes {

  @JsonProperty()
  userId: string;
  
  @JsonProperty()
  token: string;

  @JsonProperty()
  userName: string;

  @JsonProperty()
  roomName: string;

}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.blackout[data-v-c747386e] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}
.blackout.open[data-v-c747386e] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-modal[data-v-c747386e] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(360deg, #8300C7 0%, #FF508C 70.74%);
  border-radius: var(--components-modal-border-radius-default, 7px);
  padding: 16px;
  z-index: 3;
  color: var(--token-modal-color-text-color);
  display: grid;
  overflow: auto;
  padding:  var(--semantic-size-components-modal-spacing-container-padding-horizontal, 64px) var(--semantic-size-components-modal-spacing-container-padding-bottom-with-button, 64px);
  padding-top: var(--semantic-size-components-modal-spacing-container-padding-vertical, 80px);
}
.modal-fullscreen-content[data-v-c747386e] {
  max-width: 700px;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons-controller[data-v-c747386e] {
  position: absolute;
  top: 28px;
  right: 26px;
  display: flex;
  align-items: flex-start;
  gap: var(--semantic-size-semantic-spacing-inline-300, 24px);
  z-index: 3;
  transform: translate(0%, 0%);
}
.control-btn[data-v-c747386e] {
  width: 12px;
  cursor: pointer;
  color: var(--token-modal-color-close-icon-color);
}
@media screen and (max-width: 768px) {
.common-modal[data-v-c747386e] {
    width: calc(100% - 32px);
    padding: 16px 16px;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, defineExpose, computed } from 'vue';
import CommonModal from './CommonModal.vue';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import CommonButton from '../CommonButton.vue';
import { useLocalizationStore } from '@/store/localization_store';

const localizationStore = useLocalizationStore();
const titleAlign = ref<'start' | 'center'>('center');
const titleElem = ref<HTMLHeadElement>();
const contentElem = ref<HTMLParagraphElement>();
const modalType = ref<'default' | 'success' | 'error'>('default');
const buttons = ref<{
  text: string;
  action: () => void;
  cta?: 'cta' | 'secondary' | 'error';
  type?: 'filled' | 'outlined';
  size?: 'small' | 'medium' | 'large';
  ilustration?: string;
}[]>([]);
const titleColor = ref<string>();
const icon = ref<IconDefinition | null>(null);
const alertModalV2 = ref<InstanceType<typeof CommonModal>>();
const isFullScreen = ref<boolean>(false);
const canClose = ref<boolean>(true);
const size = ref<'small' | 'medium'>('small');
const ilustration = ref<string>('');

defineExpose({
  open: async (params: {
    modalType?: 'default' | 'success' | 'error', 
    title: string,
    customContentStyle?: {
      color?: string,
      background?: string,
      fontSize?: string, 
    }
    customContent?: string;
    content: string,
    buttons?: {
      text: string;
      action: () => void;
      cta?: 'cta' | 'secondary' | 'error';
      type?: 'filled' | 'outlined';
      size?: 'small' | 'medium' | 'large';
    }[],
    titleColor?: "cta" | "success" | "error",
    zIndex?: number,
    isFullScreen?: boolean,
    canClose?: boolean,
    size?: 'small' | 'medium'
    icon?: IconDefinition,
    ilustration?: string;
  }) => {
    // Configuração padrão dos parâmetros
    titleElem.value!.innerText = await localizationStore.getKey(params.title) ?? '';
    contentElem.value!.innerHTML = await localizationStore.getKey(params.content) ?? '';
    isFullScreen.value = params.isFullScreen ?? false;
    canClose.value = params.canClose ?? true;
    size.value = params.size ?? 'small';
    alertModalV2.value?.open({ zIndex: params.zIndex ?? 1000 });
    icon.value = params.icon ?? null;

    // Configuração dinâmica com base no modalType
    modalType.value = params.modalType ?? 'default';
    ilustration.value = params.ilustration ?? '';

    switch (modalType.value) {
      case 'success':
        titleColor.value = 'success';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
            cta: 'cta',
            type: 'filled',
          },
        ];
        break;

      case 'error':
        titleColor.value = 'error';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
            cta: 'secondary',
            type: 'outlined',
          },
        ];
        break;

      default:
        titleColor.value = params.titleColor ?? 'cta';
        buttons.value = params.buttons ?? [
          {
            text: 'Ok',
            action: () => {
              alertModalV2.value?.close();
            },
          },
        ];
        break;
    }
  },
  close: () => {
    alertModalV2.value?.close();
  },
});

const titleColorClass = computed(() => {
  switch (titleColor.value) {
    case 'success':
      return 'var(--components-modal-color-success-title-color, #31812A)';
    case 'error':
      return 'var(--components-modal-color-error-title-color, #C63D3D)';
    default:
      return 'var(--components-modal-color-default-title-color, #fff)';
  }
});
</script>

<template>
  <CommonModal ref="alertModalV2" :can-close="canClose" :is-full-screen="isFullScreen">
    <div :class="size === 'small' ? 'alert-modal-small' : 'alert-modal-medium'">
      <div class="d-flex align-items-center flex-column">
        <div class="d-flex align-items-center justify-content-center">
          <FontAwesomeIcon 
            v-if="icon && !ilustration"
            :icon="icon"
            class="alert-icon" 
            :style="{ color: titleColorClass }" 
          />
          <img
            v-if="ilustration"
            :src="ilustration"
            class="ilustration"
          />
        </div>
        <div style="height: var(--semantic-size-components-modal-spacing-gap-icon-title, 32px);"></div>
        <div>
          <h3 ref="titleElem" class="title" :style="{ color: titleColorClass, textAlign: titleAlign }"></h3>
        </div>
        <div style="height: var(--semantic-size-components-modal-spacing-gap-title-slot, 16px);"></div>
        <div ref="contentElem" class="content-paragraph"></div>
      </div>
      <div style="height: var(--semantic-size-components-modal-spacing-gap-slot-button, 48px);"></div>
      <div :class="'buttons'" :style="{ justifyContent: buttons.length > 1 ? 'space-between' : 'center'}">
        <CommonButton
          v-if="buttons[0] != undefined"
          @click="buttons[0]?.action"
          :label-key="buttons[0]?.text"
          :class="size === 'medium' ? 'w-100' : ''"
        />
        <CommonButton
          v-if="buttons[1] != undefined"
          @click="buttons[1]?.action"
          :label-key="buttons[1]?.text"
        />
      </div>
    </div>
  </CommonModal>
</template>

<style scoped>
.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.alert-modal-medium {
  max-width: 100%;
  width: 507px;
}

.alert-modal-small {
  max-width: 100%;
  width: 100%;
}

.title {
  color: var(--components-modal-color-default-title-color, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;  
}

.ilustration {
  width: 80%;
  height: 100%;
}

.alert-icon {
  display: flex;
  width: 78px;
  height: 77px;
  justify-content: center;
  align-items: center;
}

.content-paragraph {
  text-align: center;
  color: var(--components-modal-color-default-text-color, #fff);
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

.content-paragraph b {
  font-weight: 800 ;
}

@media screen and (max-width: 768px) {
  .alert-modal-medium {
    max-width: 100%;
    width: 100%;
  }
}
</style>

import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { CachePlayer } from "./cache-player.model";
import { Place } from "./place.model";

@JsonObject()
export class FetchedState {
  @JsonProperty({ type: CachePlayer })
  users: CachePlayer[];

  @JsonProperty({ type: Place })
  place: Place;

  @JsonProperty()
  bucketId: string;
}
import { SerializeUtils } from "@/core/utils/serialize.utils";
import { User } from "@/models/user.model";
import { LoginResponse } from "@/models/login-response.model";

class LoggedUserStorage {

  private static userKey = 'userInfo';
  private static tokenKey = 'tokenInfo';
  private static expiresAt = 'expiresAt';

  static setData(loginData: LoginResponse) {
    const userValue = JSON.stringify(SerializeUtils.serializer.serialize(loginData.user));
    if (!userValue) return;
    window.localStorage.setItem(LoggedUserStorage.userKey, JSON.stringify(loginData.user));
    window.localStorage.setItem(LoggedUserStorage.tokenKey, loginData.token);
    window.localStorage.setItem(LoggedUserStorage.expiresAt, loginData.expiresAt?.toString() ?? '0');
  }

  static updateUser(user: User) {
    const currentUser = LoggedUserStorage.getUser();
    if (!currentUser) return;
    if (currentUser.getId() !== user.getId()) return; 
    
    const userValue = JSON.stringify(SerializeUtils.serializer.serialize(user));
    if (!userValue) {
      return;
    }
    window.localStorage.setItem(LoggedUserStorage.userKey, JSON.stringify(user));
  }

  static clearUser() {
    window.localStorage.removeItem(LoggedUserStorage.userKey);
    window.localStorage.removeItem(LoggedUserStorage.tokenKey);
    window.localStorage.removeItem(LoggedUserStorage.expiresAt);
  }

  static getUser(): User | null {
    const userStr = JSON.parse(window.localStorage.getItem(LoggedUserStorage.userKey) ?? 'null');
    const user = SerializeUtils.serializer.deserializeObject(userStr,  User) as User;
    if (!user) return null;

    return user;
  }

  static getToken(): string | null {
    return window.localStorage.getItem(LoggedUserStorage.tokenKey);
  }

  static setToken(token: string) {
    window.localStorage.setItem(LoggedUserStorage.tokenKey, token);
  }

  static getExpiresAt(): Date | null {
    const expiresAt = window.localStorage.getItem(LoggedUserStorage.expiresAt);
    if (!expiresAt) return null;
    return new Date(parseInt(expiresAt));
  }
}

export default LoggedUserStorage;
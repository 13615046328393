<template>
  <div class="emoji-component-modal ui-shadows">
    <div>
      <ul class="tabs" style="overflow-x: visible;">
        <li v-for="(tab, index) in tabs" :key="index" @click="selectedTab = index"
          :class="selectedTab == index ? 'active' : 'innactive'">
          <svg v-html="tab.title" width="24" height="24"></svg>
        </li>
      </ul>
      <div v-show="selectedTab === index" v-for="(tab, index) in tabs" :key="index">
        <div class="emoji-container">
          <div class="emoji-row" v-for="row in tab.content" :key="row.id">
            <div class="emoji-col" v-for="item in row.items" :key="item.title" @click="choiceEmoji(item)">{{ item.emoji }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top:8px; display:flex">
      <button class="ui-shadows" style="font-family:komikaKaps;margin-right:16px;border-radius:8px;"
        @click="close">cancel</button>
      <button class="ui-shadows" style="font-family:komikaKaps;margin-right:16px;border-radius:8px;"
        @click="clearEmoji">clear emoji</button>
      <input type="radio" v-model="emojiTimer" name="timer" :value="EMOJI_TIMER.TEMP" checked />
      <div style="margin-right:8px;width:24px;height:24px;display: flex;">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
          viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <g>
              <g>
                <path fill="#000000" d="M54.8,15.4v5.4H128h73.2v-5.4V10H128H54.8V15.4z" />
                <path fill="#000000"
                  d="M69,31c0,17.4,5.1,38.8,13,54.2c5.2,10.1,9.2,15.5,17.9,23.5c8.5,7.9,10.2,10.9,10.1,18.2c-0.1,6.7-2.7,11.8-8.7,16.5C82.3,158.7,69,192,69,224.4v5.4h60.7h60.7v-5c0-2.8-0.3-8.1-0.7-12c-3.1-29.7-14.6-55-31.4-69c-6.8-5.6-9-9.8-9-17.1c0-4.2,0.3-5.7,1.9-8.6c1-2,3-4.5,4.3-5.8c11.3-9.9,16.3-16.1,21.8-26.9c7.7-15.1,13-37.2,13-54.8v-4.3h-60.7H69V31z M178.7,42.3c-0.7,7.3-3,17.7-5.4,24.8c-5.1,15.3-10.9,24.5-21.5,34.4c-6.3,5.9-7.7,7.5-9.6,11.7c-1.8,4-2.3,5.8-2.6,11c-0.7,12.7,2.1,19.1,13.2,29.9c8,7.9,12.3,13.9,16.2,22.4c4.9,10.7,8.5,24.6,9.7,36.8l0.5,5.6h-49.7H79.8l0.3-2.2c0.2-1.2,0.5-4,0.7-6.2c1.1-10.2,4.9-24.1,9.2-33.4c4.2-9.1,8.5-15,16.5-22.9c10.2-10.1,13.4-16.5,13.4-27.7c0-9.4-3.3-16.9-10-22.7c-5.8-5-8.5-7.8-11.9-12.3c-7.9-10.6-14.7-28.7-16.8-44.9c-0.4-3.2-0.9-6.6-1-7.7l-0.3-1.8h49.6h49.7L178.7,42.3z" />
                <path fill="#000000" d="M54.8,240.6v5.4H128h73.2v-5.4v-5.4H128H54.8V240.6z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <input type="radio" v-model="emojiTimer" name="timer" :value="EMOJI_TIMER.FIXED" />
      <div style="width:24px;height:24px;display: flex;"><svg xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 256 256"
          enable-background="new 0 0 256 256" xml:space="preserve">
          <g>
            <g>
              <g>
                <path fill="#000000"
                  d="M53.6,10.9c-3,2.1-3.2,0.9,11.1,50.6c7.5,25.8,13.6,47.1,13.6,47.3c0,0.2-5.3,0.3-11.7,0.3c-11.6,0-11.8,0-13.5,1.4l-1.7,1.4v18.7v18.7l1.7,1.4l1.7,1.4H89h34.2v45.4v45.5l1.5,1.4c1.9,1.9,4.6,1.9,6.5,0l1.5-1.4v-45.5v-45.4H167c26.1-0.1,34.6-0.2,35.3-0.8c2.2-1.7,2.3-3,2.3-21.1v-17.8l-1.7-1.7l-1.7-1.7h-12.5c-6.8,0-12.5-0.2-12.5-0.4c0-0.2,6.4-21.5,14.2-47.3c8.6-28.4,14-47.3,13.9-48c-0.2-0.6-0.9-1.7-1.7-2.3c-1.2-1.2-2.5-1.2-74.5-1.2C62,10,54.8,10.1,53.6,10.9z M193.1,20.1c-0.2,0.5-20.6,67.9-26.6,87.9c-0.3,1-2.7,1.1-39.2,1.1H88.4l-1.5-4.9c-0.8-2.7-6.5-22.5-12.7-44.1c-6.2-21.6-11.4-39.6-11.5-40c-0.2-0.5,13.1-0.7,65.2-0.7C180,19.4,193.2,19.6,193.1,20.1z M195.1,130.7v12.1h-67.3H60.6v-12.1v-12.1h67.3h67.3V130.7z" />
              </g>
            </g>
          </g>
        </svg></div>
    </div>
  </div>
</template>

<script setup lang="ts">

import EmojiConstants, { Emoji, EMOJI_TIMER } from "../../core/constants/emoji_constants";
import { ref, computed } from 'vue';

type EmojiRow = {
  id: number;
  items: Emoji[];
}

type EmojiTab = {
  title: string,
  content: EmojiRow[]
}

const emit = defineEmits<{
  (e: "close-container"): void,
  (e: "clear-emoji"): void,
  (e: "send-emoji", emoji: Emoji, timer: string): void
}>()

const emojiTimer = ref(EMOJI_TIMER.TEMP);
const selectedTab = ref(0);
const columnsPerRow = 8;

const tabs = computed(() => {
  let tabs: EmojiTab[] = [];
  for (let x = 0; x < EmojiConstants.EMOJI_CATEGORIES.length; x++) {
    let rowsArray: EmojiRow[] = [];
    let items = EmojiConstants.EMOJIS[EmojiConstants.EMOJI_CATEGORIES[x].name];
    for (let i = 0; i < items.length; i += columnsPerRow) {
      rowsArray.push({
        id: i,
        items: items.slice(i, i + columnsPerRow)
      });
    }
    tabs.push({
      title: EmojiConstants.EMOJI_CATEGORIES[x].icon,
      content: rowsArray
    })
  }
  return tabs;
});

const choiceEmoji = (emoji: Emoji) => {
  emit("close-container");
  emit("send-emoji", emoji, emojiTimer.value);
}

const clearEmoji = () => {
  emit("close-container");
  emit("clear-emoji");
}

const close = () => {
  emit("close-container");
}

</script>




<style scoped>
/* Estilos para os tabs e o conteúdo */
.tabs {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0px;
  width: 100%;
}

.tabs li {
  cursor: pointer;
  height: 40px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #c0c0c0;
  flex-grow: 1;
  flex-basis: 0;
  align-content: center;
  text-align: center;
}

.tabs li.innactive:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}

.tabs li.active {
  background-color: #58af6e;
  color: #ffffff;
}

.tabs li.active svg {
  fill: #ffffff;
}

.emoji-component-modal {
  position: fixed;
  bottom: 100px;
  left: calc(50% - 140px);
  z-index: 110;
  height: 300px;
  width: 387px;
  /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%); */
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  border-radius: 8px;
  border: 1px solid #ffffff;
  backdrop-filter: blur(32px);
  padding: 8px;
}

.emoji-container {

  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 200px;
  width: 100%;
  border: 1px solid #c0c0c0;
}

.emoji-row {
  display: flex;
}

.emoji-col {
  width: 12.5%;
  /* Cada coluna ocupará 12.5% da largura total */
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid rgb(255, 255, 255);
  border-left: 1px solid rgb(255, 255, 255);
  padding: 10px;
  font-size: 20px;
  width: 44px;
  height: 44px;
  font-family: "NotoColorEmoji", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.emoji-col:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}</style>
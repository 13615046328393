import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "toast-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "common-toast",
    key: $setup.state,
    style: _normalizeStyle($setup.getStyle())
  }, [
    (_openBlock(), _createElementBlock("div", {
      class: "loader",
      key: $setup.timer ?? 0,
      style: _normalizeStyle({
      width: `calc(${$setup.timer && $setup.maxTimer ? (($setup.timer / $setup.maxTimer) * 100).toFixed(2) : null}% - 4px)`
    })
    }, null, 4 /* STYLE */)),
    _createElementVNode("p", _hoisted_1, _toDisplayString($setup.text), 1 /* TEXT */),
    ($setup.onUndo)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "toast-undo",
          onClick: _cache[0] || (_cache[0] = () => {
      $setup.onUndo?.call(null);
      $setup.closeModal();
    })
        }, "Desfazer"))
      : _createCommentVNode("v-if", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "toast-divider" }, null, -1 /* HOISTED */)),
    _createVNode($setup["FontAwesomeIcon"], {
      class: "toast-icon",
      icon: $setup.Icons.imported.faXmark,
      onClick: $setup.closeModal
    }, null, 8 /* PROPS */, ["icon"])
  ], 4 /* STYLE */)), [
    [_vShow, $setup.isShowing]
  ])
}
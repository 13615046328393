import { defineComponent as _defineComponent } from 'vue'
import { useChatStore } from '@/store/chat_store';
import ChatMessageUI from './ChatMessageUI.vue';
import CommonButton from '../CommonButton.vue';
import LocalizedText from '../LocalizedText.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import GameServerClient from '@/infra/opagameserver/gameserver/GameServerClient';
import { ChatLocation, ChatMessage } from '@/domain/gameserver/send-message.model';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';
import { ToggleChatState } from '@/infra/event_bus/ChatEventBus';
import ChatInput from '../ChatInput.vue';
import ChatButton from '../ChatButton.vue';
import CommonDropdown from '../CommonDropdown.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatUI',
  setup(__props, { expose: __expose }) {
  __expose();

const chatStore = useChatStore();

onMounted(() => {
  GameServerClient.onChatMessageEvt.subscribe(addToHistory.name, addToHistory);
});

onUnmounted(() => {
  GameServerClient.onChatMessageEvt.unsubscribe(addToHistory.name);
});

function addToHistory(chatMessage: ChatMessage) {
  if (chatMessage.getChatLocation() in chatStore.chatHistory) {
    chatStore.chatHistory[chatMessage.getChatLocation()].push(chatMessage);
  } else {
    chatStore.chatHistory[chatMessage.getChatLocation()] = [chatMessage];
  }
}

const chatInput = ref<InstanceType<typeof ChatInput>>();


const __returned__ = { chatStore, addToHistory, chatInput, ChatMessageUI, get ChatLocation() { return ChatLocation }, get LoggedUserStorage() { return LoggedUserStorage }, get ToggleChatState() { return ToggleChatState }, ChatInput, ChatButton, CommonDropdown }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { defineComponent as _defineComponent } from 'vue'
import ParticipantComponent from "./ParticipantComponent.vue";
import {RemoteParticipant} from "livekit-client";
import {useMediaStore} from "../../store/media_store";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MediaParticipantsComponent',
  setup(__props, { expose: __expose }) {
  __expose();

const mediaStore = useMediaStore();

const participants=ref<InstanceType<typeof ParticipantComponent>[]>();

mediaStore.$subscribe((mutation, state) => {
  let target = mutation.events["target"];
  if ( target && target instanceof RemoteParticipant) {
    participants.value?.forEach(p => {
      if (p.participant?.identity === target.identity) {
        //update remote participant UI
        p.updateUI();
      }
    })
  }
})


const __returned__ = { mediaStore, participants, ParticipantComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
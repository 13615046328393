// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.text-select-container[data-v-cc235168] {
  border: 1px solid rgba(255, 255, 255, 0.70);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  background-color: var(--token-semantic-color-bg-subtle, #f5f5f5);
  border-radius: 8px;
  padding: 8px;
}
.text-select[data-v-cc235168] {
  outline: none;
  border: none;
  border-radius: 5px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

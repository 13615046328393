<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';
import LocalizedText from '@/components/LocalizedText.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import Constants from '@/core/constants/constants';
import { AlertModalInjection, LoadingModalInjection } from '@/core/constants/injections';
import { ImutableModel } from '@/models/imutable.model';
import { UserGender } from '@/models/user-gender.enum';
import { ValueResult } from '@/models/value-result.model';
import router, { routes } from '@/router';
import { useGuestLoginStore } from '@/store/guest_login_store';
import { useTownStore } from '@/store/town_store';
import { useUniverseStore } from '@/store/universe_store';
import { inject, onMounted, ref, Ref } from 'vue';

const splittedHostname = window.location.hostname.split('.')[0].split('_');

const guestLoginStore = useGuestLoginStore();
const townStore = useTownStore();
const universeStore = useUniverseStore();

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);

const [town, universe, showTownInput, showUniverseInput] = [
  ref<string | undefined>(undefined),
  ref<string | undefined>(undefined),
  ref<boolean>(true),
  ref<boolean>(true),
];

onMounted(async () => {
  guestLoginStore.$reset();
  if(!Constants.debugMode) [town.value, universe.value] = splittedHostname;
  if(town.value && !universe.value) universe.value = town.value;
  if (typeof(universe.value) === 'string' && typeof(town.value) === 'string') {
    const promises = ValueResult.fromPromises<ImutableModel | null>([
      townStore.getTown({ filters: {subdomains: [town.value]} }),
      universeStore.getUniverse({ filters: {domains: [universe.value] }}),
    ]);
    loadingModal?.value.open({
      promise: promises,
      zIndex: 2
    });
    await promises;
    if (townStore.town?.universeId
    && universeStore.universe?.id
    && townStore.town?.universeId === universeStore.universe?.id) {
      showTownInput.value = false;
      showUniverseInput.value = false;
    }
  }
});

async function guestLogin() {
  if (!guestLoginStore.firstName || !guestLoginStore.lastName) {
    alertModal?.value.open({
      title: 'login.loginErrorTitle',
      content: 'login.loginErrorMessage',
    });
    return;
  }
  const promise = guestLoginStore.guestLogin({
    townId: town.value ?? 'default',
    universeId: universe.value ?? 'default',
  });
  loadingModal?.value.open({
    promise,
  });
  const res = await promise;
  if (res.isError()) {
    alertModal?.value.open({
      title: 'Login Error',
      content: res.getError() ?? 'Login Error',
    });
    return;
  }
  router.push({ name: routes.gameScene.name });
}

</script>

<template>
  <div class="gradient">
    <div class="login-inside">
      <LocalizedText style="font-size: 32px; font-weight: bold; color: #fff; text-transform: uppercase;" text-key="guestLogin.title"></LocalizedText>

      <div style="display: flex; flex-direction: column; gap: 8px; width: 100%;">
        <CommonInput
          labelKey="login.universeDomain"
          :modelValue="universe ?? ''"
          v-if="showUniverseInput"
          @update:model-value="(v) => { universe = v }"
        ></CommonInput>
        <CommonInput
          labelKey="login.townSubdomain"
          :modelValue="town ?? ''"
          v-if="showTownInput"
          @update:model-value="(v) => { town = v }"
        ></CommonInput>
        <CommonInput
          labelKey="guestLogin.firstName"
          :modelValue="guestLoginStore.firstName ?? ''"
          @update:model-value="(v) => guestLoginStore.firstName = v"
        ></CommonInput>
        <CommonInput
          labelKey="guestLogin.lastName"
          :modelValue="guestLoginStore.lastName ?? ''"
          @update:model-value="(v) => guestLoginStore.lastName = v"
        ></CommonInput>
      </div>
      
      <div class="gender-container">
        <div class="gender-selector" :class="guestLoginStore.gender == UserGender.FEMALE ? 'selected-border' : ''">
          <img src="@/assets/genders/female-dark.png" @click="() => { guestLoginStore.gender = UserGender.FEMALE }"/>
        </div>
        <div class="gender-selector" :class="guestLoginStore.gender == UserGender.MALE ? 'selected-border' : ''">
          <img src="@/assets/genders/male-dark.png" @click="() => { guestLoginStore.gender = UserGender.MALE }"/>
        </div>
      </div>

      <CommonButton
        label-key="guestLogin.enter"
        @click="guestLogin"
      ></CommonButton>

    </div>
  </div>
</template>

<style scoped>

.gradient {
  position: fixed;
  background: linear-gradient(360deg, #7C2DCA 0%, #EF4F83 100%);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-inside {
  background: linear-gradient(360deg, #853EBC 0%, #FF588E 100%);
  border-radius: 48px;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 48px;
  gap: 32px;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.3);
  width: 28%;
  min-width: 400px;
}

.gender-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-around;
  gap: 8px;
}

.gender-selector {
  border: 4px solid lightgrey;
  border-radius: 16px;
  cursor: pointer;
  background-color: #FFFFFF75;
  border-radius: 16px;
  box-sizing: content-box;
}

.gender-selector img {
  width: 100px;
  height: 100px;
  padding: 8px;
}

.gender-selector:hover {
  border-color: #b1b1b1;
}

.gender-selector:active {
  border: 4px solid #EF4F83;
  border-radius: 16px;
}

.selected-border {
  border-color: #EF4F83 !important;
  border-width: 4px !important;
}

</style>
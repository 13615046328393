import { defineComponent as _defineComponent } from 'vue'
import { Icons } from '@/font-awesome';
import { useLocalizationStore } from '@/store/localization_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref } from 'vue';

const delayToUpdate = 1000 / 60;
type StateData = 'default' | 'error';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonToast',
  setup(__props, { expose: __expose }) {

const isShowing = ref<boolean>(false);
const text = ref<string | null>(null);
const state = ref<StateData>('default');
const maxTimer = ref<number | null>(null);
const timer = ref<number | null>(null);
const onUndo = ref<(() => void) | null>(null);

const closePromiseResolve = ref<(() => void) | null>(null);

const localizationStore = useLocalizationStore();

__expose({
  open: async (params: {
    textKey: string;
    state?: StateData;
    timer?: number;
    onUndo?: () => void;
  }) => {
    text.value = await localizationStore.getKey(params.textKey);
    state.value = params.state ?? 'default';
    timer.value = params.timer ?? 4000;
    maxTimer.value = timer.value;
    onUndo.value = params.onUndo ?? null;
    isShowing.value = true;
    
    setupTimer();

    const closePromise = new Promise<void>((resolve) => {
      closePromiseResolve.value = resolve;
    });
    await closePromise;
  },
  close: closeModal,
});

function setupTimer() {
  const interval = setInterval(() => {
    if ((timer.value ?? 0) <= 0) {
      clearInterval(interval);
      closeModal();
    }
    timer.value = (timer.value ?? 0) - delayToUpdate;
  }, delayToUpdate);
}

function closeModal() {
  isShowing.value = false;
  closePromiseResolve.value?.call(null);
}

function getStyle() {
  if (state.value === 'error') {
    return {
      border: `1px solid var(--semantic-color-error-default, #C63D3D)`,
      background: `var(--semantic-color-error-default, #C63D3D)`,
    };
  }
  return {
    border: `1px solid var(--semantic-color-success-default, #31812A)`,
    background: `var(--semantic-color-success-default, #31812A)`,
  };
}


const __returned__ = { delayToUpdate, isShowing, text, state, maxTimer, timer, onUndo, closePromiseResolve, localizationStore, setupTimer, closeModal, getStyle, get Icons() { return Icons }, get FontAwesomeIcon() { return FontAwesomeIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
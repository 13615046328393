<template>
  <div id="media_remote_participants_container">
    <ParticipantComponent v-for="[identity, participant] in mediaStore.remoteParticipants" 
      :participant="participant" :key="identity" ref="participants"/>
  </div>
</template>

<script setup lang="ts">
import ParticipantComponent from "./ParticipantComponent.vue";
import {RemoteParticipant} from "livekit-client";
import {useMediaStore} from "../../store/media_store";
import {ref} from "vue";

const mediaStore = useMediaStore();

const participants=ref<InstanceType<typeof ParticipantComponent>[]>();

mediaStore.$subscribe((mutation, state) => {
  let target = mutation.events["target"];
  if ( target && target instanceof RemoteParticipant) {
    participants.value?.forEach(p => {
      if (p.participant?.identity === target.identity) {
        //update remote participant UI
        p.updateUI();
      }
    })
  }
})

</script>

<style>
#media_remote_participants_container {
  margin:8px 0 8px 8px;
}
</style>
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#controllers {
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-right: 8px;
}
#controllers #media-controllers {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
#controllers button, #controllers .context-menu-container {
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;

  transition: all 100ms ease-out;
}
#controllers button.on, #controllers button.screen-sharing.on {
  background: #58af6e;
}
#controllers button.on:hover, #controllers button.screen-sharing.on:hover {
  background: #3E9553;
}
#controllers button.off {
  background: #b95356;
}
#controllers button.off:hover {
  background: #AB4144;
}
#controllers button svg {
  color: #FFFFFF;
  width: 24px;
  height: 24px;
}
#controllers button.screen-sharing, #controllers .context-menu-container {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
}
#controllers button.screen-sharing:hover, #controllers .context-menu-container:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
}
#controllers button.screen-sharing.on svg {
  fill: #FFF;
}
#controllers button.screen-sharing svg {
  width: 24px;
  height: 24px;
  fill: #353535;
}
#controllers button svg.fa-video-slash {
  width: 21px;
  height: 21px;
}
#controllers button svg.fa-microphone-slash {
  width: 24px;
  height: 24px;
}
#controllers .open-context-menu {
  color: #353535;
}
#controllers .open-context-menu:hover {
  background: none;
}
#controllers .context-menu {
  top: 0px !important;
  bottom: unset !important;
  transform: translateY(-100%) !important;
  transition: all 300ms ease-out;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

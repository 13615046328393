import { defineComponent as _defineComponent } from 'vue'
import { inject, onMounted, Ref, ref } from 'vue';
import CommonInput from '../components/CommonInput.vue';
import CommonButton from '../components/CommonButton.vue';
import { useUserStore } from '../store/login_store';
import LocalizedText from '@/components/LocalizedText.vue';
import { AlertModalInjection, LoadingModalInjection } from '@/core/constants/injections';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import router, { routes } from '@/router';
import { useTownStore } from '../store/town_store';
import { useUniverseStore } from '../store/universe_store';
import { ValueResult } from '@/models/value-result.model';
import { ImutableModel } from '@/models/imutable.model';
import Constants from '@/core/constants/constants';
import { UserGender } from '@/models/user-gender.enum';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props, { expose: __expose }) {
  __expose();

const loginStore = useUserStore();
const townStore = useTownStore();
const universeStore = useUniverseStore();

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);

const splittedHostname = window.location.hostname.split('.')[0].split('_');
const [town, universe, gender, showTownInput, showUniverseInput] = [
  ref<string | undefined>(undefined),
  ref<string | undefined>(undefined),
  ref<UserGender>(UserGender.MALE),
  ref<boolean>(true),
  ref<boolean>(true)
  ];

async function login() {
  if (!loginStore.username || !loginStore.password) {
    alertModal?.value.open({
      title: 'login.loginErrorTitle',
      content: 'login.loginErrorMessage',
    });
    return;
  }
  const promise = loginStore.login({
    universeId: universe.value,
    townId: town.value,
    gender: gender.value
  });
  loadingModal?.value.open({
    promise,
  });
  const res = await promise;
  if (res.isError()) {
    alertModal?.value.open({
      title: 'Login Error',
      content: res.getError() ?? 'Login Error',
    });
    return;
  }
  router.push({ name: routes.gameScene.name });
}

onMounted(async () => {
  loginStore.$reset();
  if(!Constants.debugMode) [town.value, universe.value] = splittedHostname;
  if(town.value && !universe.value) universe.value = town.value;
  if (typeof(universe.value) === 'string' && typeof(town.value) === 'string') {
    const promises = ValueResult.fromPromises<ImutableModel | null>([
        townStore.getTown({ filters: {subdomains: [town.value]} }),
        universeStore.getUniverse({ filters: {domains: [universe.value] }}),
      ]);
    loadingModal?.value.open({
      promise: promises,
      zIndex: 2
    });
    await promises;
    if (townStore.town?.universeId
    && universeStore.universe?.id
    && townStore.town?.universeId === universeStore.universe?.id) {
      showTownInput.value = false;
      showUniverseInput.value = false;
    }
  }
});

function guestLogin() {
  router.push({ name: routes.guestLogin.name });
}


const __returned__ = { loginStore, townStore, universeStore, loadingModal, alertModal, splittedHostname, town, universe, gender, showTownInput, showUniverseInput, login, guestLogin, CommonInput, CommonButton, LocalizedText, get UserGender() { return UserGender } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
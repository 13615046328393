// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.common-toast[data-v-f8f75208] {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  max-width: 400px;
  display: inline-flex;
  padding: var(--semantic-spacing-inset-squishy-medium-vertical, 12px) 12px var(--semantic-spacing-inset-squishy-medium-vertical, 12px) var(--semantic-spacing-inset-squishy-small-horizontal, 16px);
  align-items: center;
  gap: var(--semantic-spacing-inline-400, 32px);
  justify-content: space-between;
  border-radius: var(--semantic-border-radius-default, 7px);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}
.toast-text[data-v-f8f75208] {
  color: var(--options-color-neutral-white, #FFF);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.toast-undo[data-v-f8f75208] {
  color: var(--options-color-neutral-white, #FFF);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.toast-icon[data-v-f8f75208] {
  color: var(--options-color-neutral-white, #FFF);
  cursor: pointer;
}
.toast-divider[data-v-f8f75208] {
  position: absolute;
  height: calc(100% - 2px);
  width: 2px;
  background: var(--options-color-neutral-white, #FFF);
  opacity: 0.1;
  bottom: 1px;
  right: 36px;
}
.loader[data-v-f8f75208] {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: var(--semantic-border-radius-default, 7px) var(--semantic-border-radius-default, 7px) 0px 0px;
  height: calc(var(--components-button-spacing-small-gap, 4px) * 1px);
  opacity: 0.5;
  background: var(--options-color-neutral-white, #FFF);
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import axios from 'axios';
import { Universe } from "@/models/universe.model";
import { ValueResult } from "@/models/value-result.model";
import Constants from '@/core/constants/constants';
import { SerializeUtils } from '@/core/utils/serialize.utils';
import { PaginationDTO } from '@/dto/pagination.dto';
import { PaginatedListDTO } from '@/dto/paginated-list.dto';

export class UniversesRestDao {
  private static controller = "universes";

  static async getUniverse(params: {
    filters?: {
      domains?: string[],
    },
    pagination?: PaginationDTO,
  }): Promise<ValueResult<PaginatedListDTO<Universe> | null>> {
    try {
      const res = await axios.get(`${Constants.getRestUrl()}/${this.controller}`, {
        params: {
          ...params.filters,
          ...params.pagination
        },
      });
      const universe = SerializeUtils.serializer.deserializeObjectArray(res.data.items, Universe) as Universe[];
      
      return new ValueResult({
        value: new PaginatedListDTO<Universe>({
          items: universe,
          pageCount: res.data.pageCount,
        })
      });
    } catch (error) {
      return ValueResult.fromError(error);
    }
  }
}
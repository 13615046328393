import { LocalizationStorage } from '@/infra/local_storage/localization.storage';
import { defineStore } from 'pinia';

interface ILanguageKeys {
  [key: string]: string | ILanguageKeys;
}

interface IState {
  currentLanguage: string | null,
  languageKeys: ILanguageKeys | null;
  promise: Promise<void> | null,
}

export const useLocalizationStore = defineStore('localization_store', {
  state: (): IState => ({
    currentLanguage: null,
    languageKeys: null,
    promise: null,
  }),
  actions: {
    async fetchLanguageKeys(language?: string) {
      const promise = new Promise<void>(async (res, _) => {
        try {
          if (this.promise) {
            await this.promise;
            res();
            return;
          }
          if (this.currentLanguage === language) return;
          const userLang = language ?? LocalizationStorage.getLanguage() ?? navigator.language.split('-')[0] ?? 'en';
          this.currentLanguage = userLang;
          const json = await fetch(`/localization/${userLang}.json`);
          this.languageKeys = await json.json();
          LocalizationStorage.saveLanguage(userLang);
          res();
        } catch {
          if (language !== 'en') await this.fetchLanguageKeys('en');
        } finally {
          this.promise = null;
        }
      });
      this.promise = promise;
      return promise;
    },
    async getKey(key: string): Promise<string> {
      if (!this.languageKeys) await this.fetchLanguageKeys();
      if (!this.languageKeys) return key;
      const split = key.split('.');
      let currentValue: ILanguageKeys | string = this.languageKeys;
      for (let i = 0; i < split.length; i++) {
        if (!currentValue) return key;
        if (typeof (currentValue as ILanguageKeys)[split[i]] === 'string') {
          currentValue = (currentValue as ILanguageKeys)[split[i]] as string;
          break;
        }
        if (i === split.length - 1) return key;
        currentValue = (currentValue as ILanguageKeys)[split[i]];
      }
      return currentValue as string;
    },
  }
});
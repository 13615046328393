import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "rounded-button ui-shadows",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, [
      _createVNode($setup["LocalizedText"], {
        "text-key": $setup.props.labelKey
      }, null, 8 /* PROPS */, ["text-key"])
    ])
  ]))
}
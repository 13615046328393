import "./registerServiceWorker";
import App from './App.vue';
import { createApp } from 'vue';
import { GlobalData } from "./global";
import router from './router/index';


const app = createApp(App);
app.use(GlobalData.Pinia);
app.use(router);
app.mount("#app");
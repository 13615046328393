import { defineComponent as _defineComponent } from 'vue'
import PenToolsComponent from '@/components/media/PenToolsComponent.vue';
import {onMounted, ref} from 'vue';
import MediaServerClient from '@/infra/opagameserver/MediaServerClient';
// const emit = defineEmits([
//   'sendData'
// ]);


export default /*@__PURE__*/_defineComponent({
  __name: 'PenCanvasComponent',
  props: {
  mediaServer : {
    type: MediaServerClient,
    required: true
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;


const penCanvas = ref<HTMLCanvasElement>();
let ctx:CanvasRenderingContext2D;
let isDrawing:boolean = false;
let isErasing:boolean = false;
let selectedColor:string = '#000000';//cor inicial
let lastX:number = 0;
let lastY:number = 0;

let penActive = ref(false);
let eraseActive = ref(false);

const handlePenActive = (isActive:boolean) => {
  penActive.value = isActive;
};

const handleEraseActive = (isActive:boolean) => {
  eraseActive.value = isActive;
};

const handleColorChange = (newColor:string) => {
  selectedColor = newColor;
};

const handleCleanBoard = () => {
  clearBoard();
  props.mediaServer.sendPenData('0,0', 'clear');
};

const clearBoard = () => {
  ctx.clearRect(0,0, ctx.canvas.width, ctx.canvas.height);
}

function erase(x:number, y:number) {
  ctx.clearRect(x - 13, y - 13, 26, 26); // Apaga um quadrado de 24x24 pixels
}

const drawLine = (x:number, y:number) => {
  ctx.beginPath();
  ctx.moveTo(lastX, lastY);
  ctx.lineTo(x, y);
  ctx.lineWidth = 2;
  ctx.strokeStyle = selectedColor;
  ctx.stroke();
  ctx.closePath();
  [lastX, lastY] = [x, y];
  //console.log(lastX, lastY, x, y);
}

const drawRemoteLine = (
    x:number, y:number, 
    lx:number, ly:number, color:string, 
    sourceWidth:number, sourceHeight:number) => {

  if (penCanvas.value) {

    let canvas = penCanvas.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    const dlx = lx * scaleX;
    const dly = ly * scaleY;

    // Draw the line on the destination canvas with the scaled coordinates
    ctx.beginPath();
    ctx.moveTo(dlx, dly);
    ctx.lineTo(dx, dy);
    ctx.lineWidth = 2;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  }
}

const eraseRemote = (
    x:number, y:number, 
    sourceWidth:number, sourceHeight:number) => {

  if (penCanvas.value) {

    let canvas = penCanvas.value;
    let destWidth = canvas.width;
    let destHeight = canvas.height; 
    
    // Calculate the scale factors for both x and y axes
    const scaleX = destWidth / sourceWidth;
    const scaleY = destHeight / sourceHeight;

    // Apply the scale factors to the coordinates
    const dx = x * scaleX;
    const dy = y * scaleY;
    ctx.clearRect(dx - 13, dy - 13, 26, 26);
  }
}

__expose({
  onRemoteReceived(data:string, topic:string) {
    let p = data.split(',');
    if (topic === "draw") {
      drawRemoteLine(
        p[0] as unknown as number, //x
        p[1] as unknown as number, //y
        p[2] as unknown as number, //lastx
        p[3] as unknown as number, //lasty
        p[4], //cor
        p[5] as unknown as number, //canvas width
        p[6] as unknown as number, //canvas height
        );
    } else if (topic === "erase") {
      eraseRemote(
        p[0] as unknown as number,
        p[1] as unknown as number,
        p[5] as unknown as number,
        p[6] as unknown as number
      );
    } else if (topic === "clear") {
      clearBoard();
    }
  },

  updateCanvas(width:number, height:number) {
    _updateCanvasSize(width, height);
  }
})

const _updateCanvasSize = (w:number, h:number) => {
  if (penCanvas.value) {

    let canvas = penCanvas.value;
    
    let img = new Image();
    let dataURL = canvas.toDataURL();
    img.src = dataURL;
    
    canvas.setAttribute(
         "style", `width:${(w).toFixed(0)}px;height:${(h).toFixed(0)}px;`);
    canvas.setAttribute("width",`${(w).toFixed(0)}`);
    canvas.setAttribute("height",`${(h).toFixed(0)}`);
    
    ctx = penCanvas.value?.getContext('2d')!;
    img.onload = function() {
      ctx.drawImage(img,0,0, w, h);
    };
  }
}

const init = () => {

  ctx = penCanvas.value?.getContext('2d')!;

  penCanvas.value?.addEventListener('mousedown', (e) => {
    isDrawing = penActive.value;
    isErasing = eraseActive.value;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });

  penCanvas.value?.addEventListener('mouseleave', (e) => {
    isDrawing = false;
    isErasing = false;
    [lastX, lastY] = [e.offsetX, e.offsetY];
  });
  
  penCanvas.value?.addEventListener('mousemove', (e) => {
    if (isDrawing){
      props.mediaServer.sendPenData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${penCanvas.value?.width},${penCanvas.value?.height}`, 'draw');
      drawLine(e.offsetX, e.offsetY);
      
    } else if (isErasing) {
      props.mediaServer.sendPenData(`${e.offsetX},${e.offsetY},${lastX},${lastY},${selectedColor},${penCanvas.value?.width},${penCanvas.value?.height}`, 'erase');
      erase(e.offsetX, e.offsetY);
    }
  });

  penCanvas.value?.addEventListener('mouseup', () => {
    isDrawing = false;
    isErasing = false;
  });
}

onMounted(init);


const __returned__ = { props, penCanvas, get ctx() { return ctx }, set ctx(v) { ctx = v }, get isDrawing() { return isDrawing }, set isDrawing(v) { isDrawing = v }, get isErasing() { return isErasing }, set isErasing(v) { isErasing = v }, get selectedColor() { return selectedColor }, set selectedColor(v) { selectedColor = v }, get lastX() { return lastX }, set lastX(v) { lastX = v }, get lastY() { return lastY }, set lastY(v) { lastY = v }, get penActive() { return penActive }, set penActive(v) { penActive = v }, get eraseActive() { return eraseActive }, set eraseActive(v) { eraseActive = v }, handlePenActive, handleEraseActive, handleColorChange, handleCleanBoard, clearBoard, erase, drawLine, drawRemoteLine, eraseRemote, _updateCanvasSize, init, PenToolsComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
<script setup lang="ts">
import { ref } from 'vue';
import CommonModal from './CommonModal.vue';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';

const modal = ref<InstanceType<typeof CommonModal>>();

const iframeUrl = ref<string | null>(null);

defineExpose({
  open,
  close,
});

async function open<T=void>(params: {
  iframeUrl?: string;
  zIndex?: number;
}) {
  const modalPromise = modal.value?.open<T>(params);
  iframeUrl.value = params.iframeUrl ?? null;
  return modalPromise;
}

async function close<T=void>(res: T) {
  if (!res) return;
  modal.value?.close<T>(res);
}

</script>

<template>
  <CommonModal :teleport="true" ref="modal" :is-full-screen="true">
    <iframe class="interaction-iframe" v-if="iframeUrl" :src="`${iframeUrl}?token=${LoggedUserStorage.getToken()}`" sandbox="allow-same-origin allow-scripts">
      
    </iframe>
  </CommonModal>
</template>

<style scoped>
.interaction-iframe {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
</style>
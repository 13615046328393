import { JsonObject, JsonProperty } from "typescript-json-serializer";

export enum MoveFailType {
  COLLIDED = "collided",
  TOO_FAST    = "too_fast",
}

@JsonObject()
export class MoveFailed {

  @JsonProperty()
  reason: MoveFailType;

  @JsonProperty()
  delayToMoveMs: number;


}
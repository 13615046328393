import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class EmojiRes {

  @JsonProperty()
  placeId: string;

  @JsonProperty()
  userId: string;

  @JsonProperty()
  emoji: string;

  @JsonProperty()
  sentTime: number;

  @JsonProperty()
  temporary: boolean;

  @JsonProperty()
  active: boolean;

  constructor(params: {
    userId: string;
    placeId: string;
    emoji: string;
    sentTime: number;
    temporary: boolean;
    active: boolean;
  }) {
    if (!params) return;
    this.userId = params.userId;
    this.placeId = params.placeId;
    this.emoji = params.emoji;
    this.sentTime = params.sentTime;
    this.temporary = params.temporary;
    this.active = params.active;
  }
}
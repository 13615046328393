import {  io, Socket } from "socket.io-client";
import Constants from "@/core/constants/constants";
import LoggedUserStorage from "../../local_storage/logged_user.storage";
import { MovePlayerModel } from "@/domain/gameserver/move-player.model";
import { SerializeUtils } from "@/core/utils/serialize.utils";
import { FetchedState } from "@/domain/gameserver/fetched-state.model";
import { CachePlayer } from "@/domain/gameserver/cache-player.model";
import { MovePlayerRes } from "@/domain/gameserver/move-player-res.model";
import { MoveFailed } from "@/domain/gameserver/move-failed.model";
import { Action } from "@/domain/Action";
import { TeleportRes } from "@/domain/gameserver/teleport-res.model";
import { InteractionFailed } from "@/domain/gameserver/interaction-failed";
import { ChatMessage, SendMessageDto } from "@/domain/gameserver/send-message.model";
import { ChangeMediaRoomRes } from "@/domain/gameserver/change-media-room-res.model";
import { IframeEvt } from "@/domain/gameserver/iframe-evt.model";
import { EmojiMessageDto } from "@/domain/gameserver/emoji-mesage.model";
import { EmojiRes } from "@/domain/gameserver/emoji-res.model";
import { EmojiFailed } from "@/domain/gameserver/emoji-failed.model";
import { AllowGuestDto } from "@/domain/gameserver/allow-guest.model";
import { CachePlayersChangeRes } from "@/domain/gameserver/cache-players-change-res.model";

export class GameServerClient {
  
  private static client: Socket;
  private static wasHandshakeDone = false;
  
  //#region Events Observer
  public static onHandshakeDoneEvt: Action = new Action();
  public static onFetchStateEvt: Action<FetchedState> = new Action();
  public static onPlayerMoveEvt: Action<MovePlayerRes> = new Action();
  public static onPlayerMoveFailedEvt: Action<MoveFailed> = new Action();
  public static onPlayerJoinEvt: Action<CachePlayer> = new Action();
  public static onPlayerLeaveEvt: Action<string> = new Action();
  public static onChangeMediaRoomEvt: Action<ChangeMediaRoomRes> = new Action();
  public static onCachePlayersChangeEvt: Action<CachePlayersChangeRes> = new Action();
  public static onTeleportEvt: Action<TeleportRes> = new Action();
  public static onInteractionFailedEvt: Action<InteractionFailed> = new Action();
  public static onChatMessageEvt: Action<ChatMessage> = new Action();
  public static onIframeEvt: Action<IframeEvt> = new Action();
  public static onEmojiSentEvt: Action<EmojiRes> = new Action();
  public static onEmojiCleanEvt: Action<EmojiRes> = new Action();
  public static onEmojiFailedEvt: Action<EmojiFailed> = new Action();
  //#endregion

  //#region Connection
  static async connect(params?: {
    token?: string,
    wsUri?: string,
  }) {
    return new Promise<void>((res, rej) => {
      GameServerClient.disconnect();
      // console.log("[GameServerClient] Starting connection...");
      const connUri = params?.wsUri || Constants.getWsUrl();
      GameServerClient.client = io(connUri, {
        query: {
          token: params?.token ?? LoggedUserStorage.getToken(),
        },
        transports: ["websocket"],
      });

      GameServerClient.client.on("connect", () => {
        // console.log("[GameServerClient] Connected to " + connUri);
        GameServerClient.fetchState();
        res();
      });

      GameServerClient.client.on("disconnect", () => {
        // console.log("[GameServerClient] Disconnected from " + connUri);
        rej();
      });

      // console.log("[GameServerClient] Binding events...");
      GameServerClient.client.on("fetchedState", GameServerClient.onFetchState);
      GameServerClient.client.on("playerMoved", GameServerClient.onPlayerMove);
      GameServerClient.client.on("playerAdded", GameServerClient.onPlayerJoin);
      GameServerClient.client.on("moveFailed", GameServerClient.onPlayerMoveFailed);
      GameServerClient.client.on("interactionFailed", GameServerClient.onInteractionFailed);
      GameServerClient.client.on("playerRemoved", GameServerClient.onPlayerLeave);
      GameServerClient.client.on("changeUserMediaRoom", GameServerClient.onChangeMediaRoom);
      GameServerClient.client.on("onCachePlayersChange", GameServerClient.onCachePlayersChange);
      GameServerClient.client.on("teleport", GameServerClient.onTeleport);
      GameServerClient.client.on("chatMessage", GameServerClient.onReceiveChatMessage);
      GameServerClient.client.on("iframe", GameServerClient.onIframe);
      GameServerClient.client.on("handshakeDone", () => {
        GameServerClient.wasHandshakeDone = true;
        GameServerClient.fetchState();
        GameServerClient.onHandshakeDoneEvt.publish();
      });
      GameServerClient.client.on("emojiSent", GameServerClient.onEmojiSent);
      GameServerClient.client.on("emojiClean", GameServerClient.onEmojiClean);
      GameServerClient.client.on("emojiFailed", GameServerClient.onEmojiFailed);
    });
  }

  static async disconnect() {
    GameServerClient.client?.disconnect();
    GameServerClient.wasHandshakeDone = false;
  }

  static async clearEvts() {
    GameServerClient.onHandshakeDoneEvt.clear();
    GameServerClient.onFetchStateEvt.clear();
    GameServerClient.onPlayerMoveEvt.clear();
    GameServerClient.onPlayerMoveFailedEvt.clear();
    GameServerClient.onPlayerJoinEvt.clear();
    GameServerClient.onPlayerLeaveEvt.clear();
    GameServerClient.onChangeMediaRoomEvt.clear();
    GameServerClient.onTeleportEvt.clear();
    GameServerClient.onInteractionFailedEvt.clear();
    GameServerClient.onChatMessageEvt.clear();
    GameServerClient.onIframeEvt.clear();
    GameServerClient.onEmojiSentEvt.clear();
    GameServerClient.onEmojiCleanEvt.clear();
    GameServerClient.onEmojiFailedEvt.clear();
  }
  //#endregion

  //#region Registered Events

  private static async onFetchState(data: any) {
    // console.log("[EVENT] onFetchState", data);
    const fetchedState = SerializeUtils.serializer.deserializeObject(data, FetchedState);
    if (!fetchedState) return;
    GameServerClient.onFetchStateEvt.publish(fetchedState);
  }

  private static async onPlayerMove(data: any) {
    // console.log("[EVENT] onPlayerMove", data);
    const movePlayerRes = SerializeUtils.serializer.deserializeObject(data, MovePlayerRes);
    if (!movePlayerRes) return;
    GameServerClient.onPlayerMoveEvt.publish(movePlayerRes);
  }

  private static async onPlayerMoveFailed(data: any) {
    // console.log("[EVENT] onPlayerMoveFailed", data);
    const moveFailed = SerializeUtils.serializer.deserializeObject(data, MoveFailed);
    if (!moveFailed) return;
    GameServerClient.onPlayerMoveFailedEvt.publish(moveFailed);
  }

  private static async onInteractionFailed(data: any) {
    // console.log("[EVENT] onInteractionFailed", data);
    const interactionFailed = SerializeUtils.serializer.deserializeObject(data, InteractionFailed);
    if (!interactionFailed) return;
    GameServerClient.onInteractionFailedEvt.publish(interactionFailed);
  }

  private static async onPlayerJoin(data: any) {
    // console.log("[EVENT] onPlayerJoin", data);
    const cachePlayer = SerializeUtils.serializer.deserializeObject(data, CachePlayer);
    if (!cachePlayer) return;
    GameServerClient.onPlayerJoinEvt.publish(cachePlayer);
  }

  private static async onPlayerLeave(data: any) {
    console.log("[EVENT] onPlayerLeave", data);
    const userId = data;
    if (!userId) return;
    GameServerClient.onPlayerLeaveEvt.publish(userId);
  }

  private static async onChangeMediaRoom(data: any) {
    // console.log("[EVENT] onChangeMediaRoom", data);
    const mediaRoomRes = SerializeUtils.serializer.deserializeObject(data, ChangeMediaRoomRes);
    if (!mediaRoomRes) return;
    GameServerClient.onChangeMediaRoomEvt.publish(mediaRoomRes);
  }

  private static async onCachePlayersChange(data: any) {
    // console.log("[EVENT] onCachePlayersChange", data);
    const cachePlayers = SerializeUtils.serializer.deserializeObject(data, CachePlayersChangeRes);
    if (!cachePlayers) return;
    GameServerClient.onCachePlayersChangeEvt.publish(cachePlayers);
  }

  private static onTeleport(data: any) {
    // console.log("[EVENT] onTeleport", data);
    const teleportRes = SerializeUtils.serializer.deserializeObject(data, TeleportRes);
    if (!teleportRes) return;
    GameServerClient.onTeleportEvt.publish(teleportRes);
  }

  private static onReceiveChatMessage(data: any) {
    // console.log("[EVENT] onReceiveChatMessage", data);
    const chatMessage = SerializeUtils.serializer.deserializeObject(data, ChatMessage);
    if (!chatMessage) return;
    GameServerClient.onChatMessageEvt.publish(chatMessage);
  }

  private static onIframe(data: any) {
    // console.log("[EVENT] onIframeEvt", data);
    const iframeEvt = SerializeUtils.serializer.deserializeObject(data, IframeEvt);
    if (!iframeEvt) return;
    GameServerClient.onIframeEvt.publish(iframeEvt);
  }

  private static async onEmojiSent(data: EmojiRes) {
    // console.log("[EVENT] onPlayerMove", data);
    const emojiRes = SerializeUtils.serializer.deserializeObject(data, EmojiRes);
    if (!emojiRes) return;
    GameServerClient.onEmojiSentEvt.publish(emojiRes);
  }

  private static async onEmojiClean(data: EmojiRes) {
    // console.log("[EVENT] onPlayerMove", data);
    const emojiRes = SerializeUtils.serializer.deserializeObject(data, EmojiRes);
    if (!emojiRes) return;
    GameServerClient.onEmojiCleanEvt.publish(emojiRes);
  }

  private static async onEmojiFailed(data: EmojiFailed) {
    // console.log("[EVENT] onPlayerMoveFailed", data);
    const emojiFailed = SerializeUtils.serializer.deserializeObject(data, EmojiFailed);
    if (!emojiFailed) return;
    GameServerClient.onEmojiFailedEvt.publish(emojiFailed);
  }

  //#endregion

  //#region Input Events

  public static fetchState() {
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    // console.log("[EMIT] fetchState");
    GameServerClient.client.emit("fetchState");
  }

  public static movePlayer(movePlayer: MovePlayerModel) {
    // console.log("[EMIT] movePlayer", movePlayer);
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("move", { ...movePlayer, clientTimestamp: Date.now() });
  }

  public static interact(tiledObjId: number) {
    // console.log("[EMIT] interact", tiledObjId);
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("interact", { objId: tiledObjId });
  }

  public static sendChatMessage(sendMessageDto: SendMessageDto) {
    // console.log("[EMIT] sendChatMessage", sendMessageDto);
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("sendMessage", sendMessageDto);
  }

  public static sendEmoji(emojiMessageDto: EmojiMessageDto) {
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("setEmoji", emojiMessageDto);
  }

  public static clearEmoji() {
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("clearEmoji");
  }

  public static changeGuestPermission(allowGuestDto: AllowGuestDto) {
    if (!GameServerClient.wasHandshakeDone) return;
    if (!GameServerClient.client || GameServerClient.client.disconnected) return;
    GameServerClient.client.emit("allowGuest", allowGuestDto);
  }

  //#endregion
  
}


export default GameServerClient;
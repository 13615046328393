import GameInput from "../game_objects/GameInput";
import GameServerClient from "../../infra/opagameserver/gameserver/GameServerClient";
import { Scene } from "phaser";
import { Character } from "../game_objects/Character";
import { FetchedState } from "@/domain/gameserver/fetched-state.model";
import { CachePlayer } from "@/domain/gameserver/cache-player.model";
import LoggedUserStorage from "@/infra/local_storage/logged_user.storage";
import { DesiredPos } from "../game_objects/DesiredPos";
import { Place } from "@/domain/gameserver/place.model";
import { MovePlayerRes } from "@/domain/gameserver/move-player-res.model";
import { Vector2 } from "@/domain/gameserver/vector-2.model";
import { MyCharacter } from "../game_objects/MyCharacter";
import { MapLayers } from "@/domain/map-layers.enum";
import Constants from "@/core/constants/constants";
import { EmojiRes } from "@/domain/gameserver/emoji-res.model";
import { Action } from "@/domain/Action";
import { CachePlayersChangeRes } from "@/domain/gameserver/cache-players-change-res.model";

export class RoomScene extends Scene {

  public static onPlayerListChanged: Action<Character[]> = new Action();

  camera: Phaser.Cameras.Scene2D.Camera;
  background: Phaser.GameObjects.Image;
  gameOverText: Phaser.GameObjects.Text;
  gameClient: GameServerClient;
  gameInput: GameInput;
  connUri: string;

  //TODO get ID from store
  myId: string = new Date().getTime().toString();
  characters: Character[] = [];
  sentTest: boolean = false;

  map: Phaser.Tilemaps.Tilemap;

  constructor() {
    super("RoomScene");
    this.connectAndMapEvents();
    GameInput.onZoomEvt.subscribe(this.zoomWheel.name, this.zoomWheel.bind(this));
  }

  resize() {
    if (this.cameras && this.game) {
      this.game!.scale.resize(window.innerWidth, window.innerHeight);
      this.cameras!.main.setSize(this.game.scale.width, this.game.scale.height);
    } 
  }

  private zoomWheel(_deltaX: number, _deltaY: number, deltaZ: number) {
    try {
      if (!this?.cameras?.main) return;
      const zoomDelta = deltaZ * 0.01;
      this.cameras.main.zoom += zoomDelta;
      this.cameras.main.zoom = Phaser.Math.Clamp(this.cameras.main.zoom, 0.5, 10); 
    } catch {}
  }
  

  create() {
    // Adicione um listener para o evento de redimensionamento
    if (window) {
      window!.addEventListener('resize', this.resize.bind(this));
      this.resize();
    }
    //adiciona zoom na rodinha
    // Cria um InputPlugin e adiciona um listener para o evento de wheel
    this.camera = this.cameras.main;
    this.camera.setRoundPixels(true);
    this.camera.setBackgroundColor(0x2F7F4F);
    this.gameInput = new GameInput(this);
  }

  update(time: number, delta: number): void {
    this.characters.forEach((char) => char.update(time, delta));
    this.gameInput.update(time, delta);
  }

  async connectAndMapEvents() {
    
    GameServerClient.onFetchStateEvt.subscribe("sceneOnFetchedState", async (fetchedState: FetchedState) => {
      
      console.log("[RECEIVED FETCH] State was received at scene", fetchedState);

      await this.createMap({
        place: fetchedState.place,
        bucketId: fetchedState.bucketId
      });

      this.createCharacters({
        users: fetchedState.users
      });

    });

    GameServerClient.onPlayerJoinEvt.subscribe("sceneOnPlayerJoin", (data: CachePlayer) => {
      this.addCharacters({
        players: [data],
      });
    });

    GameServerClient.onPlayerLeaveEvt.subscribe("sceneOnPlayerLeave", (userId: string) => {
      this.removePlayers({
        userIds: [userId],
      });
    })

    GameServerClient.onPlayerMoveEvt.subscribe("sceneOnPlayerMove", (movePlayerRes: MovePlayerRes) => {
      const character = this.characters.find((char) => char.id === movePlayerRes.userId);
      if (!character) {
        GameServerClient.fetchState();
        return;
      }
      const pixelPos = movePlayerRes.position.toPixel({
        tileWidth: this.map.tileWidth,
        tileHeight: this.map.tileHeight,
      });
      // const { clientTimestamp, serverTimestamp } = movePlayerRes;
      character?.addDesiredPosition(new DesiredPos({
        x: pixelPos?.x,
        y: pixelPos?.y,
        delayInMs: movePlayerRes.movingDelay ?? 300,
      }));
    });

    GameServerClient.onCachePlayersChangeEvt.subscribe("sceneOnCachePlayersChange", (cachePlayers: CachePlayersChangeRes) => {
      this.characters.forEach((char) => {
        const cachePlayer = cachePlayers.cachePlayers.find((cachePlayer) => cachePlayer.userId === char.id);
        if (!cachePlayer) return;

        char.guestType = cachePlayer.guestType;
        char.characterName = cachePlayer.userName;

      });
      RoomScene.onPlayerListChanged.publish(this.characters);
    });

    GameServerClient.onEmojiSentEvt.subscribe("sceneOnEmojiSent", (emojiSentRes: EmojiRes) => {
      const character = this.characters.find((char) => char.id === emojiSentRes.userId);
      if (!character) {
        return;
      }
      if(emojiSentRes.emoji != undefined) {
        character?.setEmoji({
          emoji: emojiSentRes.emoji,
          sentTime: emojiSentRes.sentTime,
          temporary: emojiSentRes.temporary,
          active: emojiSentRes.active
        });
      } else {
        console.log("Invalid Emoji sent");
      }
    });

    GameServerClient.onEmojiCleanEvt.subscribe("sceneOnEmojiClean", (emojiSentRes: EmojiRes) => {
      const character = this.characters.find((char) => char.id === emojiSentRes.userId);
      if (!character) {
        return;
      }
      character?.clearEmoji();
    });

    GameServerClient.onTeleportEvt.subscribe("sceneOnTeleport", (teleportRes) => {
      const character = this.characters.find((char) => char.id === teleportRes.userId);
      if (!character) {
        GameServerClient.fetchState();
        return;
      }
      if (!this.map) return;

      const pixelPos = teleportRes.pos.toPixel({
        tileWidth: this.map.tileWidth,
        tileHeight: this.map.tileHeight,
      });
      character?.teleport({
        moveDir: teleportRes.direction,
        pos: pixelPos,
      });
    });
    
    await GameServerClient.connect();

  }

  onDestroy() {
    window.removeEventListener('resize', this.resize?.bind(this));
    GameServerClient.disconnect();
  }

  private async createMap(params: {
    place: Place,
    bucketId: string,
  }) {
    try {
      const mapUrls = params.place.getFilesCloudStorageUrl(params.bucketId);
      
      let imageFiles: string[] = [];
      const loadPromises = mapUrls.map(e => {
        return new Promise<void>((res, rej) => {
          const fileNameNoExt = e.name.replace(/\.[^/.]+$/, "");
          if (e.name !== "index.json") imageFiles.push(fileNameNoExt);
          const url = e.url;
          const loader = e.name === "index.json" ? this.load.tilemapTiledJSON("map", url) : this.load.image(fileNameNoExt, url);
          loader.start();
          loader.once("complete", () => res());
          loader.once("loaderror", () => rej());
        });
      });
      await Promise.all(loadPromises);
      
      this.map = this.make.tilemap({ key: "map" });
      const tiles = imageFiles.map(imageFile => ({ key: imageFile, tilemapLayer: this.map.addTilesetImage(imageFile)})).filter(e => e.tilemapLayer);
      
      const tileMapsLayers = tiles.map(e => e.tilemapLayer!);
      this.map.layers.forEach((e, i) => {
        const createdLayer = this.map.createLayer(e.name, tileMapsLayers);
        console.log(e.name, i*1000);
        createdLayer?.setDepth(i*1000);
      });

      // BIND INTERACTIVE OBJECTS
      const interactiveObjectsLayer = this.map.getObjectLayer(MapLayers.INTERACTIVE_OBJECTS);
      interactiveObjectsLayer?.objects.forEach(object => {
        const rect = this.add.rectangle(
          object?.x!,
          object?.y!,
          object.width!,
          object.height!,
        ).setOrigin(0, 0).setInteractive();
        if (Constants.debugMode) rect.setStrokeStyle(1, 0x00ff00);
        rect.setDepth(50000)
        rect.on('pointerdown', () => GameServerClient.interact(object.id) );
      });


    } catch (error) {
      console.error('Erro ao criar o mapa:', error);
    }

  }

  private createCharacters(params: {
    users: CachePlayer[],
  }) {
    if (!this.map) return; 
    this.characters.forEach((char) => char.destroy());
    this.characters = [];
    this.addCharacters({
      players: params.users,
    });
    const myCharacter = this.characters.find((char) => char.isItMe);
    if (myCharacter) this.camera.startFollow(myCharacter);
  }

  private addCharacters(params: {
    players: CachePlayer[]
  }) {
    try {
      if (!this.map) return;
      params.players.forEach((data) => {
        const existentPlayer = this.characters.find((char) => char.id === data.userId);
        if (existentPlayer) {
          this.characters.slice(this.characters.indexOf(existentPlayer), 1);
          existentPlayer.destroy();
        }

        const pixelPos = data.position.toPixel({
          tileWidth: this.map.tileWidth,
          tileHeight: this.map.tileHeight
        });
        const isItMe = LoggedUserStorage.getUser()?.getId() === data.userId;
        
        const charactersLayerIndex = this.map.layers.findIndex((layer) => layer.name === "characters");
        const params = {
          scene: this,
          x: pixelPos.x,
          y: pixelPos.y,
          characterName: data.userName,
          isItMe: LoggedUserStorage.getUser()?.getId() === data.userId,
          id: data.userId,
          moveDir: data.lastMoveDir,
          gender: data.gender,
          playersDepth: charactersLayerIndex * 1000,
          emoji: data.emoji.emoji,
          emojiSentTime:data.emoji.sentTime,
          emojiTemporary:data.emoji.temporary,
          emojiActive:data.emoji.active,
          isGuest: data.isGuest,
          guestType: data.guestType ?? undefined,
          tileSize: new Vector2({x:this.map.tileWidth, y:this.map.tileHeight})
        };

        const char = isItMe ? new MyCharacter(params) : new Character(params);
        //char?.setDepth(charactersLayerIndex > 0 ? charactersLayerIndex : this.map.layers.length);
        this.characters.push(char);
      });

      RoomScene.onPlayerListChanged.publish(this.characters);
    } catch {}
  }

  private removePlayers(params: {
    userIds: string[]
  }) {
    if (!this.map) return;
    params.userIds.forEach((userId) => {
      const char = this.characters.find((char) => char.id === userId);
      if (char) char.destroy();
      this.characters = this.characters.filter((char) => char.id !== userId);
    });

    RoomScene.onPlayerListChanged.publish(this.characters);
  }

}

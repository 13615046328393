import { JsonObject, JsonProperty } from "typescript-json-serializer";

export enum ChatLocation {
  MEDIA_ROOM = "media_room",
  PLACE = "place",
  TOWN = "town",
  GROUP = "group",
  PRIVATE_MESSAGE = "private_message",
}

export class SendMessageDto {
  readonly message: string;

  readonly location: ChatLocation;

  readonly destinationUserId?: string;

  readonly groupId?: string;
}


@JsonObject()
export class ChatMessage {

  @JsonProperty()
  private id: string;

  @JsonProperty()
  private message: string;

  @JsonProperty()
  private authorUserId: string;

  @JsonProperty()
  private authorName: string;

  @JsonProperty()
  private chatLocation: ChatLocation;

  @JsonProperty()
  private mediaRoomId?: string;

  @JsonProperty()
  private placeId?: string;

  @JsonProperty()
  private townId?: string;

  @JsonProperty()
  private privateChatUsers?: string[];

  @JsonProperty()
  private groupId?: string;

  @JsonProperty()
  private createdAtUtc: Date;

  @JsonProperty()
  private updatedAtUtc?: Date;

  constructor(params: {
    id: string;
    message: string;
    authorUserId: string;
    authorName: string;
    chatLocation: ChatLocation;
    mediaRoomId?: string;
    placeId?: string;
    townId?: string;
    privateChatUsers?: string[];
    groupId?: string;
    createdAtUtc: Date;
    updatedAtUtc?: Date;
  }) {
    if (!params) return;
    this.id = params.id;
    this.message = params.message;
    this.authorUserId = params.authorUserId;
    this.authorName = params.authorName;
    this.chatLocation = params.chatLocation;
    this.mediaRoomId = params.mediaRoomId;
    this.placeId = params.placeId;
    this.townId = params.townId;
    this.privateChatUsers = params.privateChatUsers;
    this.groupId = params.groupId;
    this.createdAtUtc = params.createdAtUtc;
    this.updatedAtUtc = params.updatedAtUtc;
  }

  public getMessage(): string {
    return this.message;
  }

  public getAuthorUserId(): string {
    return this.authorUserId;
  }

  public getAuthorName(): string {
    return this.authorName;
  }

  public getMediaRoomId(): string | undefined {
    return this.mediaRoomId;
  }

  public getPlaceId(): string | undefined {
    return this.placeId;
  }

  public getTownId(): string | undefined {
    return this.townId;
  }

  public getChatLocation(): ChatLocation {
    return this.chatLocation;
  }
  
  public getPrivateChatUsers(): string[] | undefined {
    return this.privateChatUsers;
  }

  public getGroupId(): string | undefined {
    return this.groupId;
  }

  public getCreatedAtUtc(): Date {
    return this.createdAtUtc;
  }

}
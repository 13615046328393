// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-container[data-v-2a312a52] {
  flex: 1;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.70);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 12px;
  flex-shrink: 0;
  height: 60px;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);

  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.input-container[data-v-2a312a52]:focus {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 20.000000298023224%, rgba(240, 229, 255, 0.80) 100%);
  border: 1px linear #FFF;
}
.input-container svg[data-v-2a312a52] {
  width: 19px;
  height: 19px;
}
.rounded-input[data-v-2a312a52] {
  height: 100%;
  background: none;
  border: none;
  outline: none;
}
.rounded-input[data-v-2a312a52]::-moz-placeholder {
  color: #353535;
}
.rounded-input[data-v-2a312a52]::placeholder {
  color: #353535;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

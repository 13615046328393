import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DOMUtils } from '@/core/utils/dom_utils';
import { useLocalizationStore } from '@/store/localization_store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContextMenu',
  props: {
    backgroundColor: { type: String, required: false },
    buttons: { type: Array, required: true }
  },
  setup(__props: any, { expose: __expose }) {

const isShowingContextMenu = ref(false);
const contextMenuElem = ref<HTMLDivElement>();
const quadrant = ref({
  isSuperior: false,
  isLeft: false,
});

const props = __props;

__expose({
  getElement: () => contextMenuElem.value,
});

onMounted(async () => {
  quadrant.value = DOMUtils.detectQuadrant(contextMenuElem.value!);
  texts.value = await Promise.all(props.buttons.map(async button => {
    let build = button.textKey;
    build = button.textKey.replace(/\*(.*?)\*/g, '<b>$1</b>');
    build = await localizationStore.getKey(button.textKey);
    return build;
  }));
});

const localizationStore = useLocalizationStore();

const texts = ref<string[]>([]);


const __returned__ = { isShowingContextMenu, contextMenuElem, quadrant, props, localizationStore, texts, get FontAwesomeIcon() { return FontAwesomeIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { defineComponent as _defineComponent } from 'vue'
import { onMounted, onUnmounted, ref } from 'vue';
import { EventBus } from './EventBus';
import StartGame from './main';
import Phaser from 'phaser';


export default /*@__PURE__*/_defineComponent({
  __name: 'PhaserGame',
  emits: ['current-active-scene'],
  setup(__props, { expose: __expose, emit: __emit }) {

const scene = ref();
const game = ref();

const emit = __emit;

onMounted(() => {

  game.value = StartGame('game-container');
  EventBus.on('current-scene-ready', (scene_instance: Phaser.Scene) => {
    emit('current-active-scene', scene_instance);
    scene.value = scene_instance;
  });

});

onUnmounted(() => {

  if (game.value) {
    game.value.destroy(true);
    game.value = null;
  }

});

__expose({ scene, game });

function blurAll() {
  Array.from(document.querySelectorAll('input')).forEach(el => el.blur())
}


const __returned__ = { scene, game, emit, blurAll }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class Place {

  @JsonProperty()
  private id: string;

  @JsonProperty()
  private universeId: string;

  @JsonProperty()
  private townId: string;

  @JsonProperty()
  private name: string;

  @JsonProperty()
  private description: string;

  @JsonProperty()
  private tileMapFiles: string[] = [];

  @JsonProperty()
  private order: number = 0;

  @JsonProperty()
  private isSpawnPoint: boolean = true;

  constructor(params: {
    id?: string;
    universeId: string;
    townId: string;
    name: string;
    description: string;
    tileMapFiles?: string[];
    order: number;
    isSpawnPoint?: boolean;
    createdAtUtc?: Date;
    updatedAtUtc?: Date;
  }) {
    if (!params) return;
    this.universeId = params.universeId;    
    this.townId = params.townId;
    this.name = params.name;
    this.description = params.description;
    this.tileMapFiles = params.tileMapFiles ?? [];
    this.order = params.order;
    this.isSpawnPoint = params.isSpawnPoint ?? true;
  }

  public getFilesCloudStorageUrl(bucketId: string): { url: string; name: string }[] {
    return this.tileMapFiles.map((file) => {
      return {
        url: `https://${bucketId}.storage.googleapis.com/maps/${this.universeId}/${this.townId}/${this.id}/${file}`,
        name: file,
      };
    });
  }
}

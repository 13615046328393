import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "media_remote_participants_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.mediaStore.remoteParticipants, ([identity, participant]) => {
      return (_openBlock(), _createBlock($setup["ParticipantComponent"], {
        participant: participant,
        key: identity,
        ref_for: true,
        ref: "participants"
      }, null, 8 /* PROPS */, ["participant"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
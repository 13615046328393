import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/genders/female-dark.png'
import _imports_1 from '@/assets/genders/male-dark.png'
import _imports_2 from '@/assets/image/left_scenario_login.svg'
import _imports_3 from '@/assets/image/right_scenario_login.svg'


const _hoisted_1 = { class: "login-main" }
const _hoisted_2 = {
  key: 0,
  class: "town-logo"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "login-container" }
const _hoisted_5 = { class: "input-login" }
const _hoisted_6 = { class: "gender-container" }
const _hoisted_7 = { style: {"display":"flex","gap":"8px","justify-content":"space-between","width":"100%"} }
const _hoisted_8 = {
  key: 1,
  class: "universe-logo"
}
const _hoisted_9 = { class: "univ-flex" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "gradient" }, null, -1 /* HOISTED */)),
    (!$setup.showUniverseInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: $setup.townStore.town?.logoUrl,
            alt: ""
          }, null, 8 /* PROPS */, _hoisted_3)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        (_openBlock(), _createBlock($setup["LocalizedText"], {
          class: "title-first-part",
          "text-key": "login.welcome",
          replacer: (t) => t.replaceAll('%TOWN%', $setup.townStore.town?.name ?? 'Town'),
          key: $setup.townStore.town?.name
        }, null, 8 /* PROPS */, ["replacer"]))
      ]),
      _createElementVNode("div", {
        class: "login-inside",
        style: _normalizeStyle({
        marginBottom: $setup.showUniverseInput ? '0px' : '48px',
      })
      }, [
        _createVNode($setup["LocalizedText"], {
          class: "title-second-part",
          "text-key": "login.title"
        }),
        _createElementVNode("div", _hoisted_5, [
          ($setup.showUniverseInput)
            ? (_openBlock(), _createBlock($setup["CommonInput"], {
                key: 0,
                labelKey: "login.universeDomain",
                modelValue: $setup.universe ?? '',
                "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => { $setup.universe = v })
              }, null, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true),
          ($setup.showTownInput)
            ? (_openBlock(), _createBlock($setup["CommonInput"], {
                key: 1,
                labelKey: "login.townSubdomain",
                modelValue: $setup.town ?? '',
                "onUpdate:modelValue": _cache[1] || (_cache[1] = (v) => { $setup.town = v })
              }, null, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["CommonInput"], {
            labelKey: "login.username",
            modelValue: $setup.loginStore.username ?? '',
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (v) => { $setup.loginStore.username = v })
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["CommonInput"], {
            labelKey: "login.password",
            modelValue: $setup.loginStore.password ?? '',
            onKeydown: _withKeys($setup.login, ["enter"]),
            type: "password",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = (v) => { $setup.loginStore.password = v })
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["gender-selector", $setup.gender == 'female' ? 'selected-border' : ''])
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                onClick: _cache[4] || (_cache[4] = () => { $setup.gender = $setup.UserGender.FEMALE })
              })
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["gender-selector", $setup.gender == 'male' ? 'selected-border' : ''])
            }, [
              _createElementVNode("img", {
                src: _imports_1,
                onClick: _cache[5] || (_cache[5] = () => { $setup.gender = $setup.UserGender.MALE })
              })
            ], 2 /* CLASS */)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["CommonButton"], {
              labelKey: "login.enter",
              onClick: $setup.login
            }),
            ($setup.universeStore.universe?.allowGuests ?? true)
              ? (_openBlock(), _createBlock($setup["CommonButton"], {
                  key: 0,
                  labelKey: "login.guest",
                  onClick: $setup.guestLogin
                }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ], 4 /* STYLE */)
    ]),
    (!$setup.showUniverseInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode($setup["LocalizedText"], {
              style: {"margin-bottom":"0","font-style":"italic"},
              "text-key": "login.powered"
            }),
            _createElementVNode("img", {
              src: $setup.universeStore.universe?.logoUrl,
              alt: ""
            }, null, 8 /* PROPS */, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "background-scenario" }, [
      _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }),
      _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      })
    ], -1 /* HOISTED */))
  ]))
}
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class IframeEvt {

  @JsonProperty()
  url: string;

  @JsonProperty()
  open: boolean;

}
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.context-menu-container[data-v-2cd1f2a4] {
  position: relative;
  font-size: 16px;
}
.open-context-menu[data-v-2cd1f2a4] {
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
}
.open-context-menu[data-v-2cd1f2a4]:hover {
  background: var(--options-color-gray-100, #F2F1F3);
}
.context-menu[data-v-2cd1f2a4] {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--token-semantic-color-bg-subtle, #F2F1F3);
  z-index: 5;
  padding: 12px;
  border-radius: 8px;
  gap: 10px;
  transition: all 0.2s ease-in-out;
  z-index: 5;
}
.context-menu[data-v-2cd1f2a4]:hover {
  cursor: pointer;
}
.context-menu>p[data-v-2cd1f2a4] {
  cursor: pointer;
  padding: 16px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 8px;
  text-wrap: nowrap;
}
.context-menu>p[data-v-2cd1f2a4]:hover {
  background-color: var(--token-semantic-color-bg-surface, #FFFFFF);
}
.text-option-text[data-v-2cd1f2a4] {
  pointer-events: none;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { vMaska } from "maska"
import { useLocalizationStore } from '@/store/localization_store';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonInput',
  props: {
    modelValue: { type: String, required: true },
    labelKey: { type: String, required: true },
    mask: { type: String, required: false },
    type: { type: String, required: false }
  },
  emits: ["update:model-value", "focus", "blur"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;



const label = ref(props.labelKey);

const localizationStore = useLocalizationStore();

onMounted(async () => {
  label.value = await localizationStore.getKey(props.labelKey);
});


const __returned__ = { props, label, localizationStore, get vMaska() { return vMaska } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
<script setup lang="ts">
import { inject, onMounted, Ref, ref } from 'vue';
import CommonInput from '../components/CommonInput.vue';
import CommonButton from '../components/CommonButton.vue';
import { useUserStore } from '../store/login_store';
import LocalizedText from '@/components/LocalizedText.vue';
import { AlertModalInjection, LoadingModalInjection } from '@/core/constants/injections';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import router, { routes } from '@/router';
import { useTownStore } from '../store/town_store';
import { useUniverseStore } from '../store/universe_store';
import { ValueResult } from '@/models/value-result.model';
import { ImutableModel } from '@/models/imutable.model';
import Constants from '@/core/constants/constants';
import { UserGender } from '@/models/user-gender.enum';

const loginStore = useUserStore();
const townStore = useTownStore();
const universeStore = useUniverseStore();

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);

const splittedHostname = window.location.hostname.split('.')[0].split('_');
const [town, universe, gender, showTownInput, showUniverseInput] = [
  ref<string | undefined>(undefined),
  ref<string | undefined>(undefined),
  ref<UserGender>(UserGender.MALE),
  ref<boolean>(true),
  ref<boolean>(true)
  ];

async function login() {
  if (!loginStore.username || !loginStore.password) {
    alertModal?.value.open({
      title: 'login.loginErrorTitle',
      content: 'login.loginErrorMessage',
    });
    return;
  }
  const promise = loginStore.login({
    universeId: universe.value,
    townId: town.value,
    gender: gender.value
  });
  loadingModal?.value.open({
    promise,
  });
  const res = await promise;
  if (res.isError()) {
    alertModal?.value.open({
      title: 'Login Error',
      content: res.getError() ?? 'Login Error',
    });
    return;
  }
  router.push({ name: routes.gameScene.name });
}

onMounted(async () => {
  loginStore.$reset();
  if(!Constants.debugMode) [town.value, universe.value] = splittedHostname;
  if(town.value && !universe.value) universe.value = town.value;
  if (typeof(universe.value) === 'string' && typeof(town.value) === 'string') {
    const promises = ValueResult.fromPromises<ImutableModel | null>([
        townStore.getTown({ filters: {subdomains: [town.value]} }),
        universeStore.getUniverse({ filters: {domains: [universe.value] }}),
      ]);
    loadingModal?.value.open({
      promise: promises,
      zIndex: 2
    });
    await promises;
    if (townStore.town?.universeId
    && universeStore.universe?.id
    && townStore.town?.universeId === universeStore.universe?.id) {
      showTownInput.value = false;
      showUniverseInput.value = false;
    }
  }
});

function guestLogin() {
  router.push({ name: routes.guestLogin.name });
}

</script>

<template>
  <main class="login-main">
    <div class="gradient"></div>
    <div class="town-logo" v-if="!showUniverseInput">
      <img :src=townStore.town?.logoUrl alt=""/>
    </div>
    <div class="login-container">
      <div>
        <LocalizedText
        class="title-first-part"
        text-key="login.welcome"
        :replacer="(t) => t.replaceAll('%TOWN%', townStore.town?.name ?? 'Town')"
        :key="townStore.town?.name"
        ></LocalizedText>
      </div>
      <div class="login-inside" :style="{
        marginBottom: showUniverseInput ? '0px' : '48px',
      }">
        <LocalizedText class="title-second-part" text-key="login.title"></LocalizedText>
        <div class="input-login">
          <CommonInput
            labelKey="login.universeDomain"
            :modelValue="universe ?? ''"
            v-if="showUniverseInput"
            @update:model-value="(v) => { universe = v }"
          ></CommonInput>
          <CommonInput
            labelKey="login.townSubdomain"
            :modelValue="town ?? ''"
            v-if="showTownInput"
            @update:model-value="(v) => { town = v }"
          ></CommonInput>
          <CommonInput
            labelKey="login.username"
            :modelValue="loginStore.username ?? ''"
            @update:model-value="(v) => { loginStore.username = v }"
          ></CommonInput>
          <CommonInput
            labelKey="login.password"
            :modelValue="loginStore.password ?? ''"
            @keydown.enter="login"
            type="password"
            @update:model-value="(v) => { loginStore.password = v }"
          ></CommonInput>
          <div class="gender-container">
            <div class="gender-selector" :class="gender == 'female' ? 'selected-border' : ''">
              <img src="@/assets/genders/female-dark.png" @click="() => { gender = UserGender.FEMALE }"/>
            </div>
            <div class="gender-selector" :class="gender == 'male' ? 'selected-border' : ''">
              <img src="@/assets/genders/male-dark.png" @click="() => { gender = UserGender.MALE }"/>
            </div>
          </div>
          <div style="display: flex; gap: 8px;justify-content: space-between; width: 100%;">
            <CommonButton labelKey="login.enter" @click="login"/>
            <CommonButton v-if="universeStore.universe?.allowGuests ?? true"labelKey="login.guest" @click="guestLogin"/>
          </div>
        </div>
      </div>
    </div>
    <div class="universe-logo" v-if="!showUniverseInput">
      <div class="univ-flex">
        <LocalizedText style="margin-bottom: 0; font-style: italic" text-key="login.powered"></LocalizedText>
        <img :src=universeStore.universe?.logoUrl alt=""/>
      </div>
    </div>
    <div class="background-scenario">
      <img src="@/assets/image/left_scenario_login.svg" alt="" />
      <img src="@/assets/image/right_scenario_login.svg" alt="" />
    </div>
  </main>
</template>

<style scoped>

.gradient {
  position: fixed;
  background: linear-gradient(360deg, #7C2DCA 0%, #EF4F83 100%);
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -2;
}


.title-first-part {
  color: white;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-break: auto;
  padding: 0px 16px 0px 16px;
}

.title-first-part * {
  font-weight: 700;
}

.title-second-part {
  color: white;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.input-login {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
  gap: 24px;
}

.login-container {
  padding-top: 32px;
  gap: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-inside {
  background: linear-gradient(360deg, #853EBC 0%, #FF588E 100%);
  border-radius: 48px;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 16px 48px 16px;
  gap: 32px;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.3);
  width: 28%;
  min-width: 400px;
}

.login-main {
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.background-scenario {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-left: 5vw;
  padding-right: 5vw;
  z-index: -1;
}

.background-scenario img {
  width: 30vw;
  height: auto;
  max-height: 75%;
}

.univ-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px;
  margin: 8px;
  width: fit-content;
  border-radius: 16px;
  background-color: rgba(211, 211, 211, 0.70);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: black;
}

.universe-logo {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
}

.universe-logo img {
  max-height: 100px;
  max-width: 200px;
  width: auto;
  height: auto;
}

.town-logo {
  position: relative;
  padding: 32px;
  max-height: 100px;
}

.town-logo img {
  max-height: 100px;
  max-width: 300px;
  width: auto;
  height: auto;
}

.gender-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-around;
  gap: 8px;
}

.gender-selector {
  border: 4px solid lightgrey;
  border-radius: 16px;
  cursor: pointer;
  background-color: #FFFFFF75;
  border-radius: 16px;
  box-sizing: content-box;
}

.gender-selector img {
  width: 100px;
  height: 100px;
  padding: 8px;
}

.gender-selector:hover {
  border-color: #b1b1b1;
}

.gender-selector:active {
  border: 4px solid #EF4F83;
  border-radius: 16px;
}

.selected-border {
  border-color: #EF4F83 !important;
  border-width: 4px !important;
}

@media screen and (max-height: 896px) {
  .title-first-part {
    font-size: 32px;
  }

  .title-second-part {
    font-size: 32px;
  }

  .login-inside {
    gap: 32px;
    min-width: 320px;
  }

  .input-login {
    gap: 16px;
  }
}

@media screen and (max-height: 720px) {
  .login-container {
    gap: 24px;
  }

  .login-inside {
    padding: 32px 16px 32px 16px;
    gap: 16px;
  }

  .input-login {
    gap: 8px;
  }

  .town-logo img {
    max-height: 75px;
    max-width: 150px;
  }

  .universe-logo img {
    max-height: 75px;
    max-width: 100px;
  }
}

@media screen and (max-width: 800px) {
  .title-first-part {
    text-align: center;
  }

  .gender-selector img {
    width: 75px;
    height: 75px;
    padding: 4px;
  }

  .town-logo img {
    max-height: 75px;
    max-width: 150px;
  }

  .universe-logo img {
    max-height: 75px;
    max-width: 100px;
  }
}

@media screen and (max-width: 570px) {
  .login-inside {
    width: 80%;
  }

  .universe-logo {
    padding: 8px;
  }

  .universe-logo img {
    max-height: 50px;
  }

  .town-logo {
    padding: 16px;
  }

  .town-logo img {
    max-height: 50px;
  }

  .gender-selector img {
    width: 50px;
    height: 50px;
    padding: 4px;
  }
}

</style>

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.chat-message[data-v-437f91dc] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px;
  background: linear-gradient(99.8deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(233, 222, 255, 0.70) 100%);
  border-radius: 24px;
  /* border: 1px solid rgba(255, 255, 255, 0.70); */
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
}
.chat-message-text[data-v-437f91dc] {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.chat-message-text-name[data-v-437f91dc] {
  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: bolder;
}
.chat-message-text-message[data-v-437f91dc] {
  text-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
  
  color: #353535;
  text-align: left;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 14px;
  width: 100%;
  min-width: 80px;
  max-width: 220px;
  line-height: normal;
  letter-spacing: 0.5px;
}
.chat-message-time[data-v-437f91dc] {
  color: #353535;
  font-family: 'komikaKaps', 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  align-self: flex-end;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

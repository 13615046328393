import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';


export default /*@__PURE__*/_defineComponent({
  __name: 'PenToolsComponent',
  emits: [
  'update:penActive',
  'update:eraseActive',
  'update:selectedColor',
  'cleanBoard'
],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const selectedColor = ref('#000000');
const penActive = ref(false);
const eraseActive = ref(false);

// Emitindo eventos para o componente pai

const updatePen = () => {
  penActive.value = !penActive.value
  emit('update:penActive', penActive.value);
  if (penActive.value) {
    eraseActive.value = false;
    emit('update:eraseActive', eraseActive.value);
  }
};

const updateErase = () => {
  eraseActive.value = !eraseActive.value
  emit('update:eraseActive', eraseActive.value);
  if (eraseActive.value) {
    penActive.value = false;
    emit('update:penActive', penActive.value);
  }
};

const updateColor = () => {
  emit('update:selectedColor', selectedColor.value);
};


const __returned__ = { emit, selectedColor, penActive, eraseActive, updatePen, updateErase, updateColor, get FontAwesomeIcon() { return FontAwesomeIcon }, get Icons() { return Icons } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
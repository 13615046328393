import { defineStore } from "pinia";
import { Character } from "@/game/game_objects/Character";
import { GuestType } from "@/domain/gameserver/guest-type.enum";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";

interface IState {
  players: Character[];
}

export const usePlayersStore = defineStore("players_store", {
  state: (): IState => {
    return {
      players: [],
    };
  },
  actions: {
    async changePermission(params: {
      userIds: string[],
      guestType: GuestType,
    }) {
      GameServerClient.changeGuestPermission(params);
    }
  },
});

<script setup lang="ts">
import { ref, defineExpose } from 'vue';
import LoaderAnimation from '../LoaderAnimation.vue';
import { ValueResult } from '@/models/value-result.model';
import CommonModal from './CommonModal.vue';

const loadingV2Modal = ref<InstanceType<typeof CommonModal>>();
const loaderAnimation = ref<InstanceType<typeof LoaderAnimation>>();

defineExpose({
  open: async (params: {
    promise: Promise<ValueResult<any>>;
    zIndex?: number;
    loading?: {
      text?: string;
      subtext?: string;
    };
    loaded?: {
      text?: string;
      subtext?: string;
    };
    delayAfterLoaded?: number;
  }): Promise<void> => {
    const delayAfterLoaded = params.delayAfterLoaded ?? 2000;
    const openPromise = loadingV2Modal.value?.open({
      zIndex: params?.zIndex ?? 10000,
    });
    loaderAnimation.value?.startLoading(params);
    const valueResult = await params.promise;
    if (valueResult?.isError()) {
      await loaderAnimation.value?.endLoading({
        delayAfterLoaded: delayAfterLoaded,
        isError: true,
        error: {
          text: "Erro",
          subtext: valueResult?.getError() ?? "Erro desconhecido ao realizar conexão",
        }
      });
      return;
    }
    await loaderAnimation.value?.endLoading({
      delayAfterLoaded: delayAfterLoaded,
    });
    await openPromise;
  },
  closeImmediately,
});

function closeImmediately() {
  loadingV2Modal.value?.close();
}

</script>

<template>
  <CommonModal ref="loadingV2Modal" :can-close="false" :is-full-screen="true">
    <LoaderAnimation ref="loaderAnimation" @end-animation="closeImmediately" />
  </CommonModal>
</template>
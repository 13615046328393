import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "chat-message ui-shadows" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "chat-message-text" }
const _hoisted_4 = { class: "chat-message-text-name" }
const _hoisted_5 = { class: "chat-message-text-message" }
const _hoisted_6 = { class: "chat-message-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.imgUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $props.imgUrl
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.userName), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.message), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString($props.time.getHours().toString().padStart(2, '0')) + ":" + _toDisplayString($props.time.getMinutes().toString().padStart(2, '0')), 1 /* TEXT */)
  ]))
}
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "media-container" }
const _hoisted_2 = { class: "media-person-container" }
const _hoisted_3 = { class: "bottom-toolbar" }
const _hoisted_4 = { class: "divider" }
const _hoisted_5 = { class: "phaser-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode($setup["LocalParticipantComponent"], null, null, 512 /* NEED_PATCH */), [
          [_vShow, $setup.mediaStore.roomConnected]
        ]),
        _withDirectives(_createVNode($setup["MediaParticipantsComponent"], null, null, 512 /* NEED_PATCH */), [
          [_vShow, $setup.mediaStore.roomConnected]
        ])
      ]),
      _withDirectives(_createVNode($setup["MediaScreenSharesComponent"], null, null, 512 /* NEED_PATCH */), [
        [_vShow, $setup.mediaStore.roomConnected]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["MediaControllersComponent"], {
        onOnOffLocalVideo: _cache[0] || (_cache[0] = () => { $setup.mediaServer.onOffLocalVideo() }),
        onOnOffLocalAudio: _cache[1] || (_cache[1] = () => { $setup.mediaServer.onOffLocalAudio() }),
        onOnOffLocalScreen: _cache[2] || (_cache[2] = () => { $setup.mediaServer.onOffLocalScreen() })
      }),
      _withDirectives(_createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */), [
        [_vShow, $setup.mediaStore.roomConnected]
      ]),
      _createVNode($setup["UIShadowButton"], {
        icon: $setup.Icons.imported.faSmile,
        onClick: _cache[3] || (_cache[3] = () => $setup.showEmojiContainer = !$setup.showEmojiContainer)
      }, null, 8 /* PROPS */, ["icon"]),
      ($setup.UniverseStorage.getUniverse()?.allowGuests && !$setup.LoggedUserStorage.getUser()?.getIsGuest())
        ? (_openBlock(), _createBlock($setup["UIShadowButton"], {
            key: 0,
            icon: $setup.Icons.imported.faPersonCircleQuestion,
            onClick: _cache[4] || (_cache[4] = () => $setup.guestModal?.open())
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ContextMenu"], {
        buttons: [
      {
        textKey: 'global.logout',
        action: async () => {
          await $setup.userStore.logout();
          await $setup.mediaStore.disconnectFromRoom();
          _ctx.$router.push({ name: $setup.routes.login.name })
        }
      }
    ]
      }, null, 8 /* PROPS */, ["buttons"])
    ]),
    _createVNode($setup["GuestModalComponent"], { ref: "guestModal" }, null, 512 /* NEED_PATCH */),
    _withDirectives(_createVNode($setup["EmojiContainerComponent"], {
      onCloseContainer: _cache[5] || (_cache[5] = () => { $setup.showEmojiContainer = false; }),
      onSendEmoji: _cache[6] || (_cache[6] = (emoji, time) => {
      console.log(emoji, time);
      $setup.GameServerClient.sendEmoji({
        emoji: emoji.emoji,
        sentTime: new Date().getTime(),
        temporary: time === $setup.EMOJI_TIMER.TEMP,
        active: true
      });
    }),
      onClearEmoji: _cache[7] || (_cache[7] = () => {
        console.log('clear emoji');
        $setup.GameServerClient.clearEmoji();
      })
    }, null, 512 /* NEED_PATCH */), [
      [_vShow, $setup.showEmojiContainer]
    ]),
    ($setup.mediaStore.showScreenFrame)
      ? (_openBlock(), _createBlock($setup["MediaScreenComponent"], {
          key: 0,
          screen: ($setup.mediaStore.screen as ScreenShareDTO),
          mediaServer: ($setup.mediaServer as MediaServerClient),
          ref: "screenRef"
        }, null, 8 /* PROPS */, ["screen", "mediaServer"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["FlipBoardComponent"]),
    _createVNode($setup["ChatUI"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["PhaserGame"], {
        ref: "phaserRef",
        onCurrentActiveScene: ($event: any) => (0)
      }, null, 512 /* NEED_PATCH */)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
import { defineComponent as _defineComponent } from 'vue'
import ParticipantComponent from "./ParticipantComponent.vue";
import {useMediaStore} from "../../store/media_store";
import {LocalParticipant } from 'livekit-client';

import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LocalParticipantComponent',
  setup(__props, { expose: __expose }) {
  __expose();

const mediaStore = useMediaStore();

const participant=ref<InstanceType<typeof ParticipantComponent>>();

mediaStore.$subscribe((mutation, state) => {
  //update participant UI
  let target = mutation.events["target"];
  if ( target && target instanceof LocalParticipant) {
    participant.value?.updateUI();
  }
})



const __returned__ = { mediaStore, participant, ParticipantComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
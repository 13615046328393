import { defineComponent as _defineComponent } from 'vue'
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';
import LocalizedText from '@/components/LocalizedText.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import Constants from '@/core/constants/constants';
import { AlertModalInjection, LoadingModalInjection } from '@/core/constants/injections';
import { ImutableModel } from '@/models/imutable.model';
import { UserGender } from '@/models/user-gender.enum';
import { ValueResult } from '@/models/value-result.model';
import router, { routes } from '@/router';
import { useGuestLoginStore } from '@/store/guest_login_store';
import { useTownStore } from '@/store/town_store';
import { useUniverseStore } from '@/store/universe_store';
import { inject, onMounted, ref, Ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GuestLogin',
  setup(__props, { expose: __expose }) {
  __expose();

const splittedHostname = window.location.hostname.split('.')[0].split('_');

const guestLoginStore = useGuestLoginStore();
const townStore = useTownStore();
const universeStore = useUniverseStore();

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);

const [town, universe, showTownInput, showUniverseInput] = [
  ref<string | undefined>(undefined),
  ref<string | undefined>(undefined),
  ref<boolean>(true),
  ref<boolean>(true),
];

onMounted(async () => {
  guestLoginStore.$reset();
  if(!Constants.debugMode) [town.value, universe.value] = splittedHostname;
  if(town.value && !universe.value) universe.value = town.value;
  if (typeof(universe.value) === 'string' && typeof(town.value) === 'string') {
    const promises = ValueResult.fromPromises<ImutableModel | null>([
      townStore.getTown({ filters: {subdomains: [town.value]} }),
      universeStore.getUniverse({ filters: {domains: [universe.value] }}),
    ]);
    loadingModal?.value.open({
      promise: promises,
      zIndex: 2
    });
    await promises;
    if (townStore.town?.universeId
    && universeStore.universe?.id
    && townStore.town?.universeId === universeStore.universe?.id) {
      showTownInput.value = false;
      showUniverseInput.value = false;
    }
  }
});

async function guestLogin() {
  if (!guestLoginStore.firstName || !guestLoginStore.lastName) {
    alertModal?.value.open({
      title: 'login.loginErrorTitle',
      content: 'login.loginErrorMessage',
    });
    return;
  }
  const promise = guestLoginStore.guestLogin({
    townId: town.value ?? 'default',
    universeId: universe.value ?? 'default',
  });
  loadingModal?.value.open({
    promise,
  });
  const res = await promise;
  if (res.isError()) {
    alertModal?.value.open({
      title: 'Login Error',
      content: res.getError() ?? 'Login Error',
    });
    return;
  }
  router.push({ name: routes.gameScene.name });
}


const __returned__ = { splittedHostname, guestLoginStore, townStore, universeStore, loadingModal, alertModal, town, universe, showTownInput, showUniverseInput, guestLogin, CommonButton, CommonInput, LocalizedText, get UserGender() { return UserGender } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
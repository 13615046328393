<script setup lang="ts">
import { useChatStore } from '@/store/chat_store';
import ChatMessageUI from './ChatMessageUI.vue';
import CommonButton from '../CommonButton.vue';
import LocalizedText from '../LocalizedText.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import GameServerClient from '@/infra/opagameserver/gameserver/GameServerClient';
import { ChatLocation, ChatMessage } from '@/domain/gameserver/send-message.model';
import LoggedUserStorage from '@/infra/local_storage/logged_user.storage';
import { ToggleChatState } from '@/infra/event_bus/ChatEventBus';
import ChatInput from '../ChatInput.vue';
import ChatButton from '../ChatButton.vue';
import CommonDropdown from '../CommonDropdown.vue';

const chatStore = useChatStore();

onMounted(() => {
  GameServerClient.onChatMessageEvt.subscribe(addToHistory.name, addToHistory);
});

onUnmounted(() => {
  GameServerClient.onChatMessageEvt.unsubscribe(addToHistory.name);
});

function addToHistory(chatMessage: ChatMessage) {
  if (chatMessage.getChatLocation() in chatStore.chatHistory) {
    chatStore.chatHistory[chatMessage.getChatLocation()].push(chatMessage);
  } else {
    chatStore.chatHistory[chatMessage.getChatLocation()] = [chatMessage];
  }
}

const chatInput = ref<InstanceType<typeof ChatInput>>();

</script>

<template>
  <div class="chat-ui">
    <CommonDropdown
      label="chat.channel"
      name="chatChannel"
      :value="chatStore.chatLocation"
      @change="(_, chatLocation) => chatStore.chatLocation = ChatLocation[chatLocation]"
      :options="[
        {
          label: 'chat.mediaRoom',
          value: ChatLocation.MEDIA_ROOM
        },
        {
          label: 'chat.place',
          value: ChatLocation.PLACE,
        }
      ]"
    ></CommonDropdown>
    <div class="chat-ui-content">
      <ChatMessageUI
        :style="{
          'align-self': message.getAuthorUserId() == LoggedUserStorage.getUser()?.getId() ? 'flex-end' : 'flex-start'
        }"
        v-for="(message, i) in chatStore.chatHistory[chatStore.chatLocation]"
        :key="`${message.getAuthorUserId()}-${message.getCreatedAtUtc()}`"
        :is-it-me="message.getAuthorUserId() == LoggedUserStorage.getUser()?.getId()"
        :userName="message.getAuthorName()"
        :message="message.getMessage()"
        :time="message.getCreatedAtUtc()"
      />
      <!-- <LocalizedText class="no-messages" v-if="chatStore.chatHistory.length <= 0" textKey="chat.noMessages"></LocalizedText> -->
    </div>
    <div class="chat-ui-input" @click="chatInput?.setFocus(true)">
      <ChatInput
        ref="chatInput"
        labelKey="chat.input"
        @focus="chatStore.onToggleChat(ToggleChatState.FOCUSED)"
        @blur="chatStore.onToggleChat(ToggleChatState.UNFOCUSED)"
        @keydown.enter="chatStore.sendChatMessage"
        @update:model-value="(v) => chatStore.typingMessage = v"
        :modelValue="chatStore.typingMessage ?? ''"
      />
      <ChatButton
        style="width: fit-content"
        class="chat-button"
        labelKey="chat.send"
        @click="() => chatStore.sendChatMessage()"
      ></ChatButton>
    </div>
  </div>
</template>

<style scoped>

.chat-ui {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 350px;
  z-index: 100;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 16px;
  z-index: 2;
}

.chat-ui-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
  gap: 4px;
  flex-grow: 1;
}

.chat-ui-input {
  display: flex;
  gap: 4px;
}

.no-messages {
  font-weight: 700;
  color: white;
}

.chat-ui-input button.chat-button .rounded-button {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.70);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68px;
}

</style>
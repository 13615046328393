<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, defineExpose } from 'vue';
import { LottieAnimation } from "lottie-web-vue";
import LoadingLottieJSON from "@/assets/lotties/loading.json";
import LocalizedText from './LocalizedText.vue';

const isLoading = ref(true);

const loadingText = ref<string | null>(null);
const loadingSubtext = ref<string | null>(null);

const loadedText = ref<string | null>(null);
const loadedSubtext = ref<string | null>(null);

const errorText = ref<string | null>(null);
const errorSubtext = ref<string | null>(null);
const timeout = ref<NodeJS.Timeout | null>(null);

const isError = ref(false);

defineExpose({
  startLoading: (params?: {
    loading?: {
      text?: string;
      subtext?: string;
    },
    loaded?: {
      text?: string;
      subtext?: string;
    },
  }) => {
    clearTimeout(timeout.value ?? undefined);
    timeout.value = null;
    isError.value = false;
    isLoading.value = true;
    loadingText.value = params?.loading?.text ?? null;
    loadingSubtext.value = params?.loading?.subtext ?? null;
    loadedText.value = params?.loaded?.text ?? null;
    loadedSubtext.value = params?.loaded?.subtext ?? null;
  },
  endLoading: async (params?: {
    delayAfterLoaded?: number;
    isError?: boolean;
    error?: {
      text?: string;
      subtext?: string;
    },
  }): Promise<boolean> => {
    let res: ((v: boolean) => void) | null = null;
    const promise = new Promise<boolean>((resolve) => res = resolve);
    errorText.value = params?.error?.text ?? null;
    errorSubtext.value = params?.error?.subtext ?? null;
    isLoading.value = false;
    isError.value = params?.isError ?? false;
    timeout.value = setTimeout(() => {
      loadingText.value = null;
      loadingSubtext.value = null;
      loadedText.value = null;
      loadedSubtext.value = null;
      emits('endAnimation', !isError.value);
      res?.call(null, !isError.value);
      isError.value = false;
    }, params?.delayAfterLoaded ?? 2000);
    return promise;
  }
});

const emits = defineEmits<{
  (e: 'endAnimation', isSuccess: boolean): void
}>();

</script>

<template>
  <section class="loading-container">
    <LottieAnimation
      v-if="isLoading"
      :animationData="LoadingLottieJSON"
      :loop="true"
      :autoPlay="true"
      style="width: 66px;"
    />
    <div v-if="!isLoading">
      <FontAwesomeIcon v-if="!isError" class="loaded-icon" :icon="Icons.imported.faCircleCheck" />
      <FontAwesomeIcon v-if="isError" class="loaded-icon error" :icon="Icons.imported.faXmark" />
    </div>

    <div style="height: var(--semantic-spacing-stack-200, 16px);"></div>
    
    <LocalizedText :textKey="loadingText ?? 'loaderAnimation.loading'" v-if="isLoading" class="loading-text"></LocalizedText>
    <LocalizedText :textKey="loadedText ?? 'loaderAnimation.loaded'" v-if="!isLoading && !isError" class="loading-text loaded"></LocalizedText>
    <LocalizedText :textKey="errorText ?? 'loaderAnimation.error'" v-if="!isLoading && isError" class="loading-text error"></LocalizedText>

    <div style="height: 4px;"></div>

    <LocalizedText :textKey="loadingSubtext ?? 'loaderAnimation.loadingSubtext'" v-if="isLoading" class="loading-subtext"></LocalizedText>
    <LocalizedText :textKey="loadedSubtext ?? 'loaderAnimation.loadedSubtext'" v-if="!isLoading && !isError" class="loading-subtext"></LocalizedText>
    <LocalizedText :textKey="errorSubtext ?? 'loaderAnimation.errorSubtext'" v-if="!isLoading && isError" class="loading-subtext error"></LocalizedText>
  </section>
</template>

<style scoped>

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background: linear-gradient(99.8deg, rgba(222, 255, 237, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%);
  backdrop-filter: blur(32px);
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.loaded-icon {
  width: 50px;
  height: 50px;
  color: var(--semantic-color-success-default, #58af6e);
}

.loaded-icon.error {
  color: var(--semantic-color-error-default, #b95356);
}

.loading-text {
  color: var(--semantic-color-primary-default, #8300C7);
  text-align: center;

  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.loading-text.loaded {
  color: var(--semantic-color-success-default, #58af6e);
}

.loading-text.error {
  color: var(--semantic-color-error-default, #b95356);
}

.loading-subtext {
  color: var(--semantic-color-fg-muted, #353535);
  text-align: center;

  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.loading-subtext.error {
  color: var(--semantic-color-error-default, #b95356);
}

</style>
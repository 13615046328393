import { ChatLocation, ChatMessage } from "@/domain/gameserver/send-message.model";
import { ChatEventBus, ToggleChatState } from "@/infra/event_bus/ChatEventBus";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";
import { defineStore } from "pinia";

export interface IChatState {
  chatHistory: {
    [key: string]: ChatMessage[],
  };
  typingMessage: string;
  isChatFocused: boolean;
  chatLocation: ChatLocation;
}

export const useChatStore = defineStore("chat_store", {
  state: (): IChatState => ({
    chatHistory: {},
    typingMessage: "",
    isChatFocused: false,
    chatLocation: ChatLocation.MEDIA_ROOM,
  }),
  actions: {
    sendChatMessage() {
      GameServerClient.sendChatMessage({
        message: this.typingMessage,
        location: this.chatLocation,
      });
      this.typingMessage = "";
    },
    onToggleChat(toggleChatState: ToggleChatState) {
      ChatEventBus.toggleChat(toggleChatState);
    },
  }
});
import axios from 'axios';
import { SerializeUtils } from '@/core/utils/serialize.utils';
import Constants from '@/core/constants/constants';
import { ValueResult } from '@/models/value-result.model';
import { LoginResponse } from '@/models/login-response.model';
import { Town } from '@/models/town.model';
import { PaginationDTO } from '@/dto/pagination.dto';
import { PaginatedListDTO } from '@/dto/paginated-list.dto';
import { UserGender } from '@/models/user-gender.enum';

export class TownsRestDao {

  private static controller = "towns";

  static async login(params: {
    universeDomain: string | undefined,
    townSubdomain: string | undefined,
    data: {
      password: string,
      login: string,
      gender: UserGender,
    },
  }): Promise<ValueResult<LoginResponse | null>> {
    try {
      const res = await axios.post(`${Constants.getRestUrl()}/${this.controller}/login/${params.universeDomain}/${params.townSubdomain}`, {
        ...params.data,
      });
      const loginRes = SerializeUtils.serializer.deserializeObject(res.data, LoginResponse);
      return new ValueResult({ value: loginRes });
    } catch (error) {
      console.error('Erro ao realizar login:', error);
      return ValueResult.fromError(error);
    }
  }

  static async guestLogin(params: {
    universeDomain: string | undefined,
    townSubdomain: string | undefined,
    data: {
      firstName: string,
      lastName: string,
      gender: UserGender,
    },
  }): Promise<ValueResult<LoginResponse | null>> {
    try {
      const res = await axios.post(`${Constants.getRestUrl()}/${this.controller}/login/guest/${params.universeDomain}/${params.townSubdomain}`, {
        ...params.data,
      });
      const loginRes = SerializeUtils.serializer.deserializeObject(res.data, LoginResponse);
      return new ValueResult({ value: loginRes });
    } catch (error) {
      console.error('Erro ao realizar login:', error);
      return ValueResult.fromError(error);
    }
  }

  static async getTowns(params: {
    filters?: {
      subdomains?: string[],
    },
    pagination?: PaginationDTO,
  }): Promise<ValueResult<PaginatedListDTO<Town> | null>> {
    try {
      const res = await axios.get(`${Constants.getRestUrl()}/${this.controller}`, {
        params: {
          ...params.filters,
          ...params.pagination
        },
      });
      const towns = SerializeUtils.serializer.deserializeObjectArray(res.data.items, Town) as Town[];

      return new ValueResult({
        value: new PaginatedListDTO<Town>({
          items: towns,
          pageCount: res.data.pageCount,
        })
      });
    } catch (error) {
      console.error('Erro ao buscar cidade:', error);
      return ValueResult.fromError(error);
    }
  }
}
